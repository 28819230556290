import { darken } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { contrastColorRGB, convertHexToRGB } from 'util/color';

interface Props {
  label: React.ReactNode;
  color?: string;
  id?: string;
  onDelete?: () => void;
  onClick?: () => void;
  outline?: boolean;
  className?: string;
}

export default function LabelChip(props: Props) {
  const hover = darken(props.color || bluePlanetTheme.bluePlanetPalette.grey.medium, 0.15);
  const color = props.color || bluePlanetTheme.bluePlanetPalette.grey.medium;
  return (
    <Chip
      onDelete={props.onDelete}
      onClick={props.onClick}
      className={props.className}
      key={props.id}
      label={props.label}
      sx={{
        color: contrastColorRGB(convertHexToRGB(props.color || bluePlanetTheme.bluePlanetPalette.grey.medium)),
        backgroundColor: color,
        border: props.outline ? `2px solid ${bluePlanetTheme.bluePlanetPalette.indigo.dark}` : 'none',

        '&:hover': {
          backgroundColor: props.onClick ? hover : color,
          cursor: props.onClick ? 'pointer' : 'inherit',
        },
      }}
    />
  );
}
