import useSWR from 'hooks/useSWR';
import { CommunitySummary } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityAPIUrls } from './communitiesApi';

export const communitySummaryKey = (communityId: string | number, communityInviteCode?: string) =>
  communityAPIUrls.getSummary(communityId, communityInviteCode);

// Fetch the community summary from `window.preloaded` if it is there, to speed up rendering
function getPreloadedCommunityProfile(slug: string): CommunitySummary {
  const hasBeenPrefetched =
    window.preloaded && window.preloaded.communitySummary && window.preloaded.communitySummary.details.slug === slug;
  return hasBeenPrefetched ? window.preloaded?.communitySummary : undefined;
}

export const useCommunitySummary = (communityId?: string | number, communityInviteCode?: string) => {
  const preloaded = typeof communityId === 'string' ? getPreloadedCommunityProfile(communityId) : undefined;
  const swrResult = useSWR<CommunitySummary>(
    communityId ? communitySummaryKey(communityId, communityInviteCode) : undefined,
    preloaded,
  );
  return useSWRResource(swrResult);
};
