import { Hidden, useMediaQuery } from '@mui/material';
import { Author, AuthorImage } from 'domain/shared/author';
import React, { lazy, useState } from 'react';
import { CommunityUpdateVisibility, UpdateVisibility } from 'types/content/update';
import Button from 'ui/elements/buttons/Button';
import { DocumentList } from 'types/documents';
import Loading from 'ui/elements/Loading';

import Visibility, { CommunityVisibilitySelector } from './Visibility';
import { ContentAPI } from 'apis/ContentAPI';
import Dropzone from 'domain/shared/Files/Upload/Dropzone';
import AttachmentsCarousel from 'domain/Updates/shared/UpdateForm/AttachmentsCarousel';

import config from 'config';
import Dialog, { Title, Content } from 'ui/views/dialogs/Dialog';
import { bluePlanetTheme } from 'ui/theme';
import { communityMentionSearchFunction, removeMentions } from 'ui/elements/form/RichTextEditor/utils';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { useUpdateForm } from 'domain/Updates/shared/UpdateForm/useUpdateForm';
import { CommunityConfiguration, TinyCommunity } from 'types/company/community';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';
import { PostedBy } from './Update';
import CommunityTopicList from './CommunityTopicList';

const RichTextEditor = lazy(() => import('ui/elements/form/RichTextEditor'));

interface Props {
  titleDraft?: string;
  contentDraft?: string;
  bannerImage?: string;
  community: TinyCommunity;
  communityConfiguration: CommunityConfiguration;
  postedBy: PostedBy;
  onSave: (dto: CompanyUpdateFormValues, visibility: UpdateVisibility) => void;
  isSaving: boolean;
  author: Author;
  onClose: () => void;
  selectedVisibility: CommunityUpdateVisibility;
  attachments?: DocumentList;
  linkPreview?: string;
  saveButtonText?: string;
  contentId?: string;
}

export default function UpdateForm(props: Props) {
  const getImageUploadUrl = (filename: string) =>
    ContentAPI(`${config.CONTENT_API_URL}/companies/${props.community.id}`).images.getCompanyUploadUrl(
      props.community.id,
      filename,
    );
  const api = communityUpdateAPI(props.community.id);

  const [isVisiblitiySelectorOpen, setIsVisibilitySelectorOpen] = useState(false);
  const [visibility, setVisibility] = useState(props.selectedVisibility);

  const { formik, newAttachments, onAttachmentRemoved, onUploadFile, removeFile, onCancel, onSubmit } = useUpdateForm({
    ...props,
    onSave: dto => props.onSave(dto, visibility),
  });

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const mentionSearchFunction = async (searchQuery: string) =>
    communityMentionSearchFunction(searchQuery, visibility, props.community.id);
  const onClose = () => onCancel(props.onClose);

  const onChangeVisibility = (visibility: CommunityUpdateVisibility) => {
    setVisibility(visibility);
    // when we change visibility, we don't know if the mentioned companies/users can still see the update, so we remove them
    formik.setFieldValue('structuredContent', removeMentions(formik.values.structuredContent));
  };

  return (
    <Dialog
      maxWidth={isVisiblitiySelectorOpen ? undefined : 'md'}
      fullScreen={isMobile}
      verticalAlign="top"
      open
      onClose={onClose}
    >
      {isVisiblitiySelectorOpen ? (
        <CommunityVisibilitySelector
          setIsVisibilitySelectorOpen={setIsVisibilitySelectorOpen}
          setVisibility={onChangeVisibility}
          isPostingAsCommunity={props.postedBy.type === 'community'}
          visibility={visibility}
          community={props.community}
          communityConfiguration={props.communityConfiguration}
        />
      ) : (
        <>
          <Title onClose={onClose}>
            <div className="u-flex u-flex-space-between u-flex-align-center" style={{ width: '100%' }}>
              <div className="u-flex u-flex-align-center">
                <AuthorImage className="u-half-spacing-right" author={props.author} />
                <Visibility openVisibilityPicker={() => setIsVisibilitySelectorOpen(true)} visibility={visibility} />
              </div>
              <Hidden smDown>
                <Button
                  onClick={() => onSubmit()}
                  kind="primary"
                  type="submit"
                  data-intercom-target="publish"
                  isLoading={props.isSaving}
                  className="u-half-spacing-right"
                >
                  {props.saveButtonText || 'Publish'}
                </Button>
              </Hidden>
            </div>
          </Title>
          <Content className="u-section-spacing-bottom">
            {props.communityConfiguration.updateTopicsIsEnabled && !props.contentId && (
              <CommunityTopicList
                communityId={props.community.id}
                onChange={topicId => formik.setFieldValue('topicId', topicId)}
              />
            )}
            <React.Suspense fallback={<Loading spacing="content-spacing" position="center" />}>
              <Dropzone disableOnClick onDrop={onUploadFile} tabIndex={-1}>
                <RichTextEditor
                  toolbarType="none"
                  name="content"
                  deltaName="structuredContent"
                  content={formik.values.content}
                  delta={formik.values.structuredContent || {}}
                  linkPreview={props.linkPreview}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  validateField={formik.validateField}
                  handleChange={formik.handleChange}
                  error={formik.touched.content ? formik.errors.content : undefined}
                  getImageUploadUrl={getImageUploadUrl}
                  mentionSearchFunction={mentionSearchFunction}
                  onUploadFile={onUploadFile}
                  submitButton={
                    <Button
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => onSubmit()}
                      kind="primary"
                      type="submit"
                      data-intercom-target="publish"
                      isLoading={props.isSaving}
                    >
                      {props.saveButtonText || 'Publish'}
                    </Button>
                  }
                  attachmentSection={
                    <AttachmentsCarousel
                      attachmentIdList={formik.values.attachments}
                      existingAttachments={props.attachments?.documents ?? []}
                      newAttachments={newAttachments}
                      downloadAttachment={(updateId: string) =>
                        api.attachments.download(props.contentId || '', updateId)
                      }
                      removeExistingAttachment={onAttachmentRemoved}
                      removeNewAttachment={removeFile}
                    />
                  }
                  autoFocus
                />
              </Dropzone>
            </React.Suspense>
          </Content>
        </>
      )}
    </Dialog>
  );
}
