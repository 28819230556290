import { CommunityType } from 'types/company/community';
import { MentorshipStatus } from 'apis/MentorAPI/types';

export function mapMentorStatus(communityType?: CommunityType, mentorStatus?: MentorshipStatus) {
  if (!mentorStatus) {
    return undefined;
  }
  if (communityType === 'mentor_master_network') {
    switch (mentorStatus) {
      case 'profile_in_progress':
        return 'Profil i prosess';
      case 'ready_for_transfer':
        return 'Klar for transfer';
      case 'transferred_to_sub_network':
        return 'Klar for matching';
      case 'ready_for_mentor_assignment':
        return 'Under kobling';
      case 'mentor_assigned':
        return 'Koblet';
      case 'mentor_agreement_signed':
        return 'Mentoravtale signert';
      case 'mentoring_in_progress':
        return 'Mentorering i gang';

      case 'final_report_done':
        return 'Sluttrapport OK';
    }
  } else if (communityType === 'mentor_network') {
    switch (mentorStatus) {
      case 'profile_in_progress':
        return '0. Venter på Mentor Ask';
      case 'transferred_to_sub_network':
        return '1. Klar for matching';
      case 'ready_for_mentor_assignment':
        return '2. Under kobling';
      case 'mentor_assigned':
        return '3. Koblet';
      case 'mentor_agreement_signed':
        return '4. Mentoravtale signert';
      case 'mentoring_in_progress':
        return '5. Mentorering i gang';
      case 'final_report_done':
        return '6. Sluttrapport OK';
      default:
        return undefined;
    }
  } else {
    switch (mentorStatus) {
      case 'profile_in_progress':
        return '1. Profile in progress';
      case 'ready_for_mentor_assignment':
      case 'ready_for_transfer':
      case 'transferred_to_sub_network':
        return '2. Ready for mentor assignment';
      case 'mentor_assigned':
        return '3. Mentor assigned';
      case 'mentoring_in_progress':
        return '4. Mentorship in progress';
      case 'final_report_done':
        return '5. Evaluation complete';
      default:
        return undefined;
    }
  }
}
