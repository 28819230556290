import React from 'react';
import Email from './Email';
import LinkedIn from './LinkedIn';
import Phone from './Phone';
import cx from 'classnames';
import SmallAccordion from 'ui/modules/Accordion/SmallAccordion';

import styles from './styles.scss';

interface Props {
  email?: string;
  linkedInUrl?: string;
  phone?: string;
  paddingLeft?: 'normal' | 'none';
}

export default function ContactDetails({ email, linkedInUrl, phone, paddingLeft }: Props) {
  const hasContactInfo = [email, linkedInUrl, phone].filter(Boolean).length > 0;
  if (!hasContactInfo) {
    return null;
  }

  return (
    <SmallAccordion
      viewMoreText="View contact info"
      viewLessText="Close"
      align="bottom"
      className="u-content-spacing-y"
    >
      <div className={styles.contactBlock}>
        {phone && (
          <div
            className={cx(styles.contactInfo, {
              ['u-section-padding-left']: paddingLeft === 'normal',
            })}
          >
            <Phone phone={phone} />
          </div>
        )}
        {email && (
          <div
            className={cx(styles.contactInfo, {
              ['u-section-padding-left']: paddingLeft === 'normal',
            })}
          >
            <Email email={email} />
          </div>
        )}
        {linkedInUrl && (
          <div
            className={cx(styles.contactInfo, {
              ['u-section-padding-left']: paddingLeft === 'normal',
            })}
          >
            <LinkedIn linkedInUrl={linkedInUrl} />
          </div>
        )}
      </div>
    </SmallAccordion>
  );
}
