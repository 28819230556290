import React from 'react';
import { Link } from 'react-router-dom';
import Resource from 'util/resource/Resource';
import SectionHeading from 'ui/elements/SectionHeading';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { communityUrls } from 'urls';
import ClickableCard from 'ui/views/cards/ClickableCard';
import CompanyLogo from 'ui/elements/CompanyLogo';
import useRoute from 'hooks/useRoute';
import { CommunityActivity, CommunityActivityType, CommunityCompanyCardDTO } from 'types/company/community';
import GoalIcon from 'ui/elements/icons/GoalIcon';
import PitchIcon from 'ui/elements/icons/PitchIcon';
import UpdateIcon from 'ui/elements/icons/UpdateIcon';
import ChartIcon from 'ui/elements/icons/ChartIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { timeSince } from 'util/dateUtils';
import { formatShortLocation } from 'util/locationUtils';
import DollarSignIcon from 'ui/elements/icons/DollarSignIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import PiggybankIcon from 'ui/elements/icons/PiggybankIcon';
import styled from '@emotion/styled';
import { quarterSpacing } from 'ui/theme/themeConstants';
import { List, PaginatedResult } from 'types/api';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import Avatar from 'ui/elements/avatars/Avatar';

function getActivityIcon(activityType: CommunityActivityType) {
  switch (activityType) {
    case 'GoalUpdated':
      return <GoalIcon />;
    case 'KpiUpdated':
      return <ChartIcon />;
    case 'UpdateCreated':
      return <UpdateIcon />;
    case 'CompanyProfileUpdated':
      return <PitchIcon />;
    case 'FundingRoundStarted':
      return <DollarSignIcon />;
    case 'FundingRoundClosed':
      return <PiggybankIcon />;
    case 'TeamUpdated':
      return <UsersIcon />;
  }
}

function getActivityText(activityType: CommunityActivityType): string {
  switch (activityType) {
    case 'GoalUpdated':
      return 'updated their goals';
    case 'KpiUpdated':
      return 'updated their KPIs';
    case 'UpdateCreated':
      return 'posted an update';
    case 'CompanyProfileUpdated':
      return 'updated their profile';
    case 'FundingRoundStarted':
      return 'started a funding round';
    case 'FundingRoundClosed':
      return 'closed a funding round';
    case 'TeamUpdated':
      return 'updated their team';
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${quarterSpacing};
`;

function useCompanyActivity(communitySlug: string) {
  const swrResult = useSWR<List<CommunityActivity>>(communityAPIUrls.companies.listActivity(communitySlug));
  return useSWRResource(swrResult);
}

function useLatestCompanies(communitySlug: string) {
  const swrResult = useSWR<PaginatedResult<CommunityCompanyCardDTO>>(
    communityAPIUrls.companies.list(communitySlug, 'latest', { limit: 5 }),
  );
  return useSWRResource(swrResult);
}

export default function CompanyActivity({ communitySlug }: { communitySlug: string }) {
  const { match, push } = useRoute();

  const { resource: companies } = useLatestCompanies(communitySlug);
  const { resource: activity } = useCompanyActivity(communitySlug);

  return (
    <>
      <Resource resource={activity} renderLoading="Nothing">
        {activities =>
          activities.values.length > 0 && (
            <div className="u-section-spacing-bottom">
              <SectionHeading heading="Latest company activity" addMobileMargin />
              <Container>
                {activities.values.slice(0, 5).map(activity => (
                  <ClickableCard
                    key={`${activity.company.id}-${activity.activityType}`}
                    className="text-align-center"
                    avatar={
                      activity.company.logoURL ? (
                        <Avatar imageUrl={activity.company.logoURL} />
                      ) : (
                        <IconAvatar size={{ width: '50px', height: '50px' }}>
                          {getActivityIcon(activity.activityType)}
                        </IconAvatar>
                      )
                    }
                    title={`${activity.company.name} ${getActivityText(activity.activityType)}`}
                    onClick={() => push(`${match.url}/pitch/${activity.company.id}?ac=community-latest-activity`)}
                  >
                    <span className="text-metadata">{timeSince(activity.timestamp)}</span>
                  </ClickableCard>
                ))}
              </Container>
            </div>
          )
        }
      </Resource>
      <Resource resource={companies} renderLoading="Nothing">
        {companies =>
          companies.total > 0 && (
            <div className="u-section-spacing-bottom">
              <SectionHeading heading="Newest companies" actionsAlignment="left" addMobileMargin>
                <Link to={communityUrls.members.overview(communitySlug, 'companies')} className="text-link-small">
                  See all
                </Link>
              </SectionHeading>
              <Container>
                {companies.values.map(company => (
                  <ClickableCard
                    key={company.details.id}
                    className="text-align-center"
                    avatar={<CompanyLogo company={company.details} />}
                    title={company.details.name}
                    onClick={() => push(`${match.url}/pitch/${company.details.id}?ac=community-newest-companies`)}
                  >
                    {company.location && <span className="text-metadata">{formatShortLocation(company.location)}</span>}
                  </ClickableCard>
                ))}
              </Container>
            </div>
          )
        }
      </Resource>
    </>
  );
}
