import { ProgressHandler } from 'hooks/useProgressHandler';

export const requestWrapper = async <T>(
  action: () => Promise<T>,
  onProgress: () => void,
  onError: (error?: string) => void,
  cleanUp?: (result: 'success' | 'failure') => void,
) => {
  onProgress();
  try {
    await action();
    cleanUp && cleanUp('success');
  } catch (e) {
    if (e && e.status === 400 && e.data && e.data.message) {
      onError(e.data.message);
      cleanUp && cleanUp('failure');
    } else {
      onError();
      cleanUp && cleanUp('failure');
    }
  }
};

export const requestWrapper2 = async <T>(
  action: () => Promise<T>,
  onError: (error?: string) => void,
  progressHandler: ProgressHandler,
  cleanUp?: (result: 'success' | 'failure') => void,
) => {
  progressHandler.onProgress();
  try {
    await action();
    progressHandler.onCleanup('success');
    cleanUp && cleanUp('success');
  } catch (e) {
    if (e && e.status === 400 && e.data && e.data.message) {
      onError(e.data.message);
      progressHandler.onCleanup('failure');
      cleanUp && cleanUp('failure');
    } else {
      onError();
      progressHandler.onCleanup('failure');
      cleanUp && cleanUp('failure');
    }
  }
};
