import useSWR from 'hooks/useSWR';
import { Industry } from 'types';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from './companiesApi';

const companyUserIndustries = (companySlug: string) => companiesAPIUrls.industries.listForUsersFilter(companySlug);

export const useCompanyUserIndustries = (companySlug: string) => {
  const swrResult = useSWR<Industry[]>(companyUserIndustries(companySlug));
  return useSWRResource(swrResult);
};
