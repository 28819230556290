import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import { getDescription } from 'util/trustLevelUtils';
import FolderBreadcrumbs from 'domain/companies/Documents/DocumentCenter/FolderBreadcrumbs';
import Tools from 'domain/documents/components/Tools';
import FolderContents from 'domain/documents/FolderContents';
import Chip from '@mui/material/Chip';
import { ICompany, TrustLevel } from 'types/company';
import Resource from 'util/resource/Resource';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import useSelector from 'hooks/useSelector';
import { useDocumentCenterFolders } from 'apis/DocumentAPI/useDocuments';

type Props = {
  viewAs: CompanyViewAs;
  company: ICompany;
  navigation:
    | {
        type: 'href';
        href: string;
      }
    | {
        type: 'state';
      };
};

export default function Documents({ viewAs, company, navigation }: Props) {
  const match = useRouteMatch<{ folderId: string }>();
  const userProfiles = useSelector(state => state.userProfiles.items);

  const [folderId, setFolderId] = useState(match.params.folderId);
  useEffect(() => {
    setFolderId(match.params.folderId);
  }, [match.params.folderId]);

  const { resource: foldersResource } = useDocumentCenterFolders(company.id);
  const folderNavigation = navigation.type === 'href' ? navigation.href : (folderId: string) => setFolderId(folderId);

  return (
    <>
      <Resource resource={foldersResource}>
        {({ folders }) => {
          const trustLevels: TrustLevel[] = ['board', 'trusted', 'semi_trusted'];

          const currentFolder = folders.find(f => f.id === folderId);
          const canUploadDocuments = viewAs === 'Admin';

          const visibleFor =
            currentFolder &&
            currentFolder.accesses &&
            getDescription(currentFolder.accesses.map(access => access.trustLevel) ?? [], 'company');

          return (
            <>
              {visibleFor && canUploadDocuments && (
                <Chip
                  sx={{
                    height: 'auto',
                    label: {
                      whiteSpace: 'normal',
                    },
                  }}
                  variant="outlined"
                  color="default"
                  icon={<EyeIcon />}
                  label={`Visible to ${visibleFor}`}
                  className="u-content-spacing-bottom"
                />
              )}
              <div className="u-flex u-flex-space-between u-flex-align-center">
                <FolderBreadcrumbs
                  folders={folders}
                  folderNavigation={folderNavigation}
                  currentFolder={currentFolder}
                />
                {canUploadDocuments && (
                  <Tools
                    trustLevels={trustLevels}
                    companyId={company.id}
                    currentFolder={currentFolder}
                    folderId={folderId}
                    isCommunity={false}
                  />
                )}
              </div>
              <FolderContents
                trustLevels={trustLevels}
                folders={folders}
                folderId={folderId}
                company={company}
                isCommunity={false}
                userProfiles={userProfiles}
                canUploadDocuments={canUploadDocuments}
                viewAs={viewAs}
                folderNavigation={folderNavigation}
              />
            </>
          );
        }}
      </Resource>
    </>
  );
}
