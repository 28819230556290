import React from 'react';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import { saveUser } from 'actions/userProfiles';
import ContactInfoForm from './ContactInfoForm';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';

interface Props {
  userProfile: SelfUserProfile;
  onComplete: () => void;
}

export default function ContactInfo(props: Props) {
  const notify = useNotify();
  const [onSave, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      props.onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  return <ContactInfoForm saving={isSaving} userProfile={props.userProfile} saveUser={data => onSave(data)} />;
}
