import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { PitchFiles } from 'types/company/pitchdeck';

export default function usePitchDocuments(companyId: number) {
  const swrResult = useSWR<PitchFiles>(companiesAPIUrls.pitchDocuments.list(companyId));

  return useSWRResource(swrResult);
}
