import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function GoalIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17 12a5 5 0 1 1-5-5" />
      <path d="M14 2.2A10 10 0 0 0 2 12a10 10 0 1 0 19.8-2" />
      <path d="m12 12 4.6-4.6m3.1-3-.5-2a.5.5 0 0 0-.8-.3c-1.4 1.2-3 2.8-1.7 5.3 2.6 1.2 4-.5 5.2-1.8.2-.3 0-.7-.3-.8l-1.9-.5Z" />
    </SvgIcon>
  );
}
