import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CircularProgress from '@mui/material/CircularProgress';
import { resize } from 'util/cloudinary';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import PitchDeckActionSlide from 'domain/companies/Documents/Document/PitchDeck/PitchDeckActionSlide';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';

export default function PitchDeckImages({
  pageNumber,
  numPages,
  imageUrls,
  company,
  viewAs,
}: {
  pageNumber: number;
  numPages: number;
  imageUrls: string[];
  company: ICompany;
  viewAs: CompanyViewAs;
}) {
  const listRef = useRef<FixedSizeList>(null);
  const [itemSize, setItemSize] = useState<number>(0);

  const updateItemSize = (index: number, height: number) => {
    setItemSize(height);
  };

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const maxWidth = isMobile ? 1125 : undefined;

  const [hasScrolledToPage, setHasScrolledToPage] = useState(false);

  useEffect(() => {
    if (!hasScrolledToPage && itemSize !== 0) {
      listRef.current?.scrollTo((pageNumber - 1) * itemSize + (pageNumber - 1) * 20);
      setHasScrolledToPage(true);
    }
  }, [itemSize]);

  // Cacheing viewAs so that the user is able to both follow and shortlist company
  // and not show share right after following
  const [viewAsCached] = useState(viewAs);

  const showActionCard = viewAsCached === 'Visitor' || viewAsCached === 'Follower';

  return (
    <>
      <AutoSizer>
        {({ width, height }) => (
          <>
            <FixedSizeList
              ref={listRef}
              itemCount={numPages + (showActionCard ? 1 : 0)}
              width={width}
              height={height}
              overscanCount={0}
              itemSize={itemSize + 20}
              style={{
                touchAction: 'pan-y pinch-zoom',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              {({ index, style }: ListChildComponentProps) =>
                index === imageUrls.length && showActionCard ? (
                  <PitchDeckActionSlide
                    slideHeight={itemSize}
                    slideWidth={width}
                    slideStyle={style}
                    company={company}
                    showInCarousel={false}
                    showFollowAction={viewAsCached !== 'Follower'}
                    isFollowing={viewAs === 'Follower'}
                    hasShortlisted={false}
                  />
                ) : (
                  <div style={style}>
                    <img
                      src={resize(imageUrls[index], { width: maxWidth })}
                      width={width}
                      onLoad={event => {
                        const imgElement = event.currentTarget;
                        if (itemSize === 0) {
                          updateItemSize(index, imgElement.offsetHeight);
                        }
                      }}
                    />
                  </div>
                )
              }
            </FixedSizeList>
            {itemSize === 0 && (
              <div
                className={'u-flex-center u-flex--align-items-center u-fullWidth u-fullHeight'}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'rgba(255, 255, 255)',
                  zIndex: 0.1,
                }}
              >
                <CircularProgress />
              </div>
            )}
          </>
        )}
      </AutoSizer>
    </>
  );
}
