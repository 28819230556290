import featureToggle from 'featureToggle';
import { Role, VisibleRole } from 'types/company/access';
import { CommunityInviteAs, CommunityType } from 'types/company/community';

export const communityRoleArticle = (role: Role) => {
  switch (role) {
    case 'company_master':
    case 'organization_admin_in_community':
    case 'employee':
    case 'advisor':
      return 'an';
    case 'board':
    case 'prospective_investor':
    case 'mentor':
    case 'company_admin_in_community':
      return 'a';
    case 'investor':
      return 'an';
    default:
      return '';
  }
};

export const communityInvitedAsDisplayName = (invitedAs: CommunityInviteAs, communityId: number) => {
  switch (invitedAs) {
    case 'Admin':
    case 'Employee':
    case 'Investor':
    case 'Mentor':
      return invitedAs;
    case 'Company':
      return 'Start-up or Scale-up';
    case 'Organization':
      return 'Corporate or Organization';
    case 'Advisor':
      return featureToggle.communityHasAdvisors(communityId) ? 'Advisor' : 'User';
    case 'NotSpecified':
      return 'Member chooses';
  }
};

export const communityRoleDisplayName = (role: Role, communityId: number) => {
  switch (role) {
    case 'company_master':
    case 'organization_master':
      return 'Admin';
    case 'board':
      return 'Board member';
    case 'investor':
      return 'Investor';
    case 'employee':
      return 'Employee';
    case 'prospective_investor':
      return 'Follower';
    case 'advisor':
      if (featureToggle.communityHasAdvisors(communityId)) return 'Advisor';
      else return 'User';
    case 'mentor':
      return 'Mentor';
    case 'company_admin_in_community':
      return 'Company';
    case 'organization_admin_in_community':
      return 'Organization';
    case 'organization_member':
      return 'Member';
  }
};

export const invisibleRoles: Role[] = ['company_admin_in_community', 'organization_admin_in_community'];

export const communityRoles = (communityType: CommunityType | undefined, communityId: number): VisibleRole[] => {
  if (communityType === 'investor_arena' && featureToggle.communityHasBoardRoom(communityId))
    return ['company_master', 'advisor', 'board', 'employee', 'investor', 'mentor'];
  if (communityType === 'investor_arena' && !featureToggle.communityHasBoardRoom(communityId))
    return ['company_master', 'advisor', 'employee', 'investor', 'mentor'];
  if (communityType === 'mentor_network') return ['company_master', 'mentor'];
  if (communityType === 'mentor_master_network') return ['company_master'];
  else return ['company_master', 'advisor', 'board', 'investor', 'employee'];
};
