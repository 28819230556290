import { ReplyContentDTO, UnbornTopicDTO } from 'types/company/community';

export function toUnbornTopicDTO(title: string, description: string): UnbornTopicDTO {
  return { title, description };
}

export function toReplyContentDTO(content: string): ReplyContentDTO {
  return {
    htmlContent: content,
    structuredContent: content,
  };
}
