import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function Office365Icon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 91 109">
      <path d="M2 23.6L57.4615 3L88.3615 11.7154V97.2846L57.4615 106L3.18846 85.7962L57.4615 92.9269V19.2423L21.0154 27.9577V78.2692L2 85.7962V23.6Z" />
    </SvgIcon>
  );
}
