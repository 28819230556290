import React, { useState } from 'react';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';

export function useFollowAndDownloadWarning(fileName: string, onFollowAndDownload?: () => any) {
  const [warningDialogIsOpen, setWarningDialogIsOpen] = useState(false);

  const onClick = onFollowAndDownload
    ? () => {
        if (warningDialogIsOpen) {
          onFollowAndDownload();
          setWarningDialogIsOpen(false);
        } else {
          setWarningDialogIsOpen(true);
        }
      }
    : undefined;

  const WarningDialog = (
    <Dialog open={warningDialogIsOpen} onClose={() => setWarningDialogIsOpen(false)} mobileLayout={'drawer'}>
      <Title style={{ padding: 10 }} onClose={() => setWarningDialogIsOpen(false)}></Title>
      <Title className={'text-weight-bold'} style={{ paddingTop: 0, paddingBottom: 0 }}>
        Follow the company to download the pitch
      </Title>
      <>
        <Content>
          <p className="text-body u-content-spacing-bottom u-half-spacing-top">
            Only connections of the company can download the pitch document. {'\n'}Start following the company and get
            access to the pitch.
          </p>
          <p className="text-body">
            You are about to download a user-uploaded file from CrowdWorks: <b>{fileName}</b>
          </p>
        </Content>
        <DialogActions>
          <Button kind={'primary'} onClick={onClick}>
            Follow and download
          </Button>
          <Button kind={'tertiary'} onClick={() => setWarningDialogIsOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );

  return { onClick, WarningDialog };
}
