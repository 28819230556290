import React from 'react';
import { PaginatedResult } from 'types/api';
import { VisibleRole } from 'types/company/access';
import { UserProfile } from 'types/user';
import { UsersSearchFilter } from 'types/company/companyAPI';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import UserList from 'domain/users/UsersDialog/UserList';
import { useCompanyUserIndustries } from 'apis/CompanyAPI/companies/useCompanyUserIndustries';

interface Props {
  fetchUsers?: (companySlug: string, filter: UsersSearchFilter) => Promise<PaginatedResult<UserProfile>>;
  defaultFilter?: VisibleRole;
  companySlug: string;
  renderUser?: (user: UserProfile) => JSX.Element;
  relevantRoles: { value: string; label: string }[];
}

export default function CompanyUserList(props: Props) {
  const { resource: industriesResource } = useCompanyUserIndustries(props.companySlug);
  const fetch = props.fetchUsers || companiesApi.users.search;

  const relevantRoles = props.relevantRoles.map(role => ({ ...role, isVisible: true }));

  return (
    <UserList
      fetchUsers={filter => fetch(props.companySlug, filter)}
      filterOptions={{
        industries: industriesResource,
        role: {
          defaultValue: props.defaultFilter,
          values: relevantRoles,
        },
      }}
      renderUser={props.renderUser}
    />
  );
}
