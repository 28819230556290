import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function YahooIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 114 85">
      <path d="M4 2H26.6415L39.7233 35.7107L53.0566 2H75.195L41.9874 82H19.5975L28.6541 60.6164L4 2Z" />
      <path d="M86.133 2H110.667L88.9153 50.805H63.875L86.133 2Z" />
      <circle cx="69.913" cy="69.415" r="13.5849" />
    </SvgIcon>
  );
}
