import React, { useEffect } from 'react';
import cx from 'classnames';
import Hidden from '@mui/material/Hidden';
import PageTitle from 'ui/views/layouts/BaseLayout/PageTitle';
import MaxWidth, { maxWidths } from 'ui/views/containers/MaxWidth';
import useRoute from 'hooks/useRoute';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import Logo from 'ui/domain/Logo';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import { useMediaQuery, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import { useMobileHeader } from 'domain/shared/Navigation';
import ScrollableBody from '../BaseLayout/ScrollableBody';
import BackButton from 'ui/elements/buttons/BackButton';
import CommunitySearch from './CommunitySearch';
import { scrollToTop } from 'util/scroll';
import { CommunityConfiguration, TinyCommunity } from 'types/company/community';
import Banner from 'domain/shared/Banner';

export const communityBleedAmounts = {
  xs: halfSpacing, // Same as <MobileMargin>
  sm: contentSpacing,
  md: sectionSpacing,
  lg: sectionSpacing,
};

const horizontalSpacing = css`
  margin: 0 auto;
  max-width: ${maxWidths.xl};
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  ${bluePlanetTheme.breakpoints.up('sm')} {
    padding-left: ${contentSpacing};
    padding-right: ${contentSpacing};
  }
  ${bluePlanetTheme.breakpoints.up('md')} {
    padding-left: ${sectionSpacing};
    padding-right: ${sectionSpacing};
  }
`;

export const HorizontalSpacing = styled.div`
  ${horizontalSpacing}
`;

export const Main = styled.main`
  ${horizontalSpacing}
  padding-top: ${contentSpacing};
  padding-bottom: ${contentSpacing};
`;

const Header = styled.header<{ hasCompactHeader: boolean }>`
  background-color: ${bluePlanetTheme.bluePlanetPalette.white};
  padding: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.up('md')} {
    padding: ${props =>
      props.hasCompactHeader
        ? `${contentSpacing} ${sectionSpacing} ${contentSpacing}`
        : `${sectionSpacing} ${sectionSpacing} ${contentSpacing}`};
    margin: 0;
  }
`;

const LogoHeader = styled.div(
  ({ hasBanner }: { hasBanner: boolean }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    ${
      hasBanner
        ? `
      ${bluePlanetTheme.breakpoints.down('md')} {
      margin-top: -35px; // 1/4 of logo height + Header padding (content spacing)
      align-items: flex-end;
      margin-bottom: ${halfSpacing};
    }
  `
        : ''
    }
  `,
);

const LogoBorder = styled.div`
  background: ${bluePlanetTheme.bluePlanetPalette.white};
  border-radius: 50%;
  border: 2px solid ${bluePlanetTheme.bluePlanetPalette.grey.medium};
`;

function CommunityHeader({
  backHref,
  subheading,
  community,
  configuration,
  disableSearch,
  imageURL,
}: {
  community: TinyCommunity;
  configuration: CommunityConfiguration;
  subheading?: React.ReactNode;
  backHref?: string;
  disableSearch?: boolean;
  imageURL?: string;
}) {
  const { push } = useRoute();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const hasBanner = !!imageURL && configuration.headerType === 'DefaultWithBanner';
  const logoSize = isSmDown && !hasBanner ? 40 : 60;

  return (
    <>
      {isMdDown && hasBanner && <Banner imageURL={imageURL} />}
      <Header hasCompactHeader={configuration.headerType === 'Compact'}>
        <div className="u-flex u-flex-space-between">
          <Hidden smDown>
            {backHref ? (
              <BackButton
                className="u-content-spacing-bottom"
                color="greyLight"
                onClick={() => push(backHref)}
                text="Back"
              />
            ) : (
              <span />
            )}
          </Hidden>
        </div>
        {hasBanner && !isMdDown && (
          <Banner
            imageURL={imageURL}
            style={{
              borderRadius: `${bluePlanetTheme.shape.borderRadius}px`,
            }}
          />
        )}
        <LogoHeader hasBanner={hasBanner}>
          <h1
            className={cx('u-flex u-flex-align-center', {
              ['text-h3 text-weight-bold']: isSmDown,
              ['text-h2']: !isSmDown,
              'u-no-spacing-y': configuration.headerType === 'Compact' || (hasBanner && isMdDown),
            })}
          >
            <LogoBorder>
              <Logo url={community.logoURL} type={'community'} size={logoSize} />
            </LogoBorder>
            {(!isMdDown || !hasBanner) && <span className="u-flex-grow u-content-spacing-left">{community.name}</span>}
          </h1>
          {!disableSearch && <CommunitySearch community={community} />}
        </LogoHeader>
        {isMdDown && hasBanner && (
          <span className="text-h3 text-weight-bold u-content-spacing-top">{community.name}</span>
        )}
        {subheading}
      </Header>
    </>
  );
}

export default function CommunityPage({
  title,
  backHref,
  subheading,
  community,
  configuration,
  children,
  mobileHeaderTitle,
  disableSearch,
  imageURL,
}: {
  community: TinyCommunity;
  configuration: CommunityConfiguration;
  title: string;
  mobileHeaderTitle?: string;
  subheading?: React.ReactNode;
  children?: React.ReactNode;
  backHref?: string;
  disableSearch?: boolean;
  imageURL?: string;
}) {
  useEffect(() => {
    scrollToTop();
  }, []);

  useMobileHeader(mobileHeaderTitle ?? title);
  return (
    <>
      <PageTitle title={title} />
      <ScrollableBody accountForMobileHeader>
        <div className="u-flex-center" style={{ backgroundColor: bluePlanetTheme.bluePlanetPalette.white }}>
          <MaxWidth width="xl">
            <CommunityHeader
              backHref={backHref}
              subheading={subheading}
              community={community}
              configuration={configuration}
              disableSearch={disableSearch}
              imageURL={imageURL}
            />
          </MaxWidth>
        </div>
        {children}
      </ScrollableBody>
    </>
  );
}
