import React, { useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import { TinyCommunity } from 'types/company/community';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import Resource from 'util/resource/Resource';
import { Link, Route, Switch } from 'react-router-dom';
import { Room } from 'domain/rooms/types';
import EditRoomDialog from './EditRoomDialog';

import useRoute from 'hooks/useRoute';
import CardStack from 'ui/views/cards/CardStack';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { ICompany } from 'types/company';
import UserListDialog from 'domain/users/UserListDialog';
import { paginationResultFrom } from 'util/paginationUtils';
import useLazyResource from 'util/resource/useLazyResource';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import useNotify from 'hooks/useNotify';

export default function CommunityRooms({ company, community }: { company: ICompany; community: TinyCommunity }) {
  const [roomsResource, , , reload] = useResourceLegacy(
    async () => await companiesApi.rooms.list(community.slug, company.id),
    [community.slug, company.id],
  );

  const [editRoom, setEditRoom] = useState<Room | undefined>(undefined);

  const { match, push } = useRoute();

  const notify = useNotify();
  const handleClose = () => push(match.url);

  const [onCreate, isCreating] = useLazyResource(
    (payload: { name: string; admins: UUID[]; users: UUID[] }) => {
      return communitiesApi.rooms.create(community.slug, company.id, payload.name, payload.users, payload.admins);
    },
    {
      onSuccess: () => {
        notify('success', 'Room created');
        reload();
        handleClose();
      },
      onFailure: () => notify('error', 'Failed to create room'),
    },
  );

  const [onEdit, isSaving] = useLazyResource(
    (payload: { roomId: UUID; admins: UUID[]; users: UUID[] }) => {
      return communitiesApi.rooms.patch(community.slug, company.id, payload.roomId, payload.users, payload.admins);
    },
    {
      onSuccess: () => {
        notify('success', 'Room users updated');
        reload();
        handleClose();
      },
      onFailure: () => notify('error', 'Failed to update room'),
    },
  );

  return (
    <CardStack>
      <Resource
        resource={roomsResource}
        renderError={() => (
          <>
            <SectionHeading heading="Rooms" addMobileMargin />
            <Card>
              <Link to={`${match.url}/create-room`}>
                <Button className="u-half-spacing-right" kind="secondary">
                  Create room
                </Button>
              </Link>
            </Card>
          </>
        )}
      >
        {rooms => (
          <>
            <SectionHeading addMobileMargin heading="Rooms" />
            <CardStack>
              {rooms.values.map(room => (
                <div key={room.id}>
                  <Card className="u-flex u-flex-space-between">
                    <Link className="text-link-small" to={`${match.url}/rooms/${room.id}`}>
                      {room.name}
                    </Link>
                  </Card>
                  <UserListDialog
                    isOpen={editRoom !== undefined}
                    closeDialog={() => setEditRoom(undefined)}
                    title="Room users"
                    fetchResource={() =>
                      companiesApi.rooms
                        .get(community.slug, company.id, room.id)
                        .then(values => paginationResultFrom(values.users))
                    }
                  />
                </div>
              ))}
            </CardStack>
            <Card>
              <Button kind="secondary" onClick={() => push(`${match.url}/create-room`)}>
                Create room
              </Button>
            </Card>
          </>
        )}
      </Resource>

      <Switch>
        <Route
          path={`${match.path}/rooms/:roomId`}
          render={({ match }) => (
            <EditRoomDialog
              community={community}
              company={company}
              onAssign={(name, users, admins) => onEdit({ roomId: match.params.roomId, users, admins })}
              onClose={handleClose}
              isSaving={isSaving}
            />
          )}
        />

        <Route
          path={`${match.path}/create-room`}
          render={() => (
            <EditRoomDialog
              community={community}
              company={company}
              onAssign={(name, users, admins) => onCreate({ name, users, admins })}
              onClose={handleClose}
              isSaving={isCreating}
            />
          )}
        />
      </Switch>
    </CardStack>
  );
}
