import LoadableResource from 'util/resource/Resource';
import React, { useEffect, useState } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { VisibleRole } from 'types/company/access';
import { mentorExpertise, MentorExpertise, mentorExpertises } from 'apis/MentorAPI/types';
import { getOrElse, IResource } from 'util/resource';
import { UserProfile } from 'types/user';
import SkillChip from 'ui/domain/Chips/SkillChip';
import Button from 'ui/elements/buttons/Button';
import MultiSelect from 'ui/elements/form/Select/MultiSelect';
import Select from 'ui/elements/form/Select/Select';
import { PAGINATION_DEFAULT_PAGESIZE } from 'util/constants';
import styles from './styles.scss';
import UserCard from './UserCard';
import TextField from 'ui/elements/form/TextField';
import Card from 'ui/views/cards/Card';
import SectionHeading from 'ui/elements/SectionHeading';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { UsersSearchFilter } from 'types/company/companyAPI';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { userDialogUrl } from '../UserProfile/UserProfileDialogRoute';
import { useRouteMatch } from 'react-router';
import { Industry } from 'types';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import { useBusinessDomains } from 'apis/CompanyAPI/labels/useBusinessDomains';
import classNames from 'classnames';
import { roleDisplayNamePlural } from 'domain/companies/roleUtils';

interface Props {
  fetchUsers: (filter: UsersSearchFilter) => Promise<PaginatedResult<UserProfile>>;
  filterOptions: {
    industries: IResource<Industry[]>;
    role: {
      defaultValue?: VisibleRole;
      values: {
        value: string;
        label: string;
        isVisible: boolean;
      }[];
    };
    mentorExpertise?: {
      enabled: boolean;
      defaultValue: MentorExpertise[] | undefined;
    };
  };
  renderUser?: (user: UserProfile) => JSX.Element;
}

interface Option {
  value: string;
  label: string;
}

export type RoleFilter =
  | 'investors'
  | 'board'
  | 'employees'
  | 'advisor'
  | 'prospective-investors'
  | 'mentor'
  | 'founder'
  | 'people';

export const getRoleFilterValue = (role: VisibleRole | 'all' | undefined): RoleFilter | undefined => {
  switch (role) {
    case 'all':
      return undefined;
    case 'investor':
      return 'investors';
    case 'employee':
    case 'company_master':
      return 'employees';
    case 'prospective_investor':
      return 'prospective-investors';
    default:
      return role;
  }
};

const limit = PAGINATION_DEFAULT_PAGESIZE;

const fetchUsers = (
  fetchUsers: (filter: UsersSearchFilter) => Promise<PaginatedResult<UserProfile>>,
  role: VisibleRole | 'all' | undefined,
  q: string,
  mentorExpertise: MentorExpertise[],
  businessDomain: string,
  industry: string[],
  paginationOptions?: PaginationOptions,
) => {
  const query = {
    role: getRoleFilterValue(role),
    q,
    businessdomain: !!businessDomain && businessDomain !== '0' ? businessDomain : undefined,
    industry,
    mentorExpertise: mentorExpertise,
    ...paginationOptions,
  };

  return fetchUsers(query);
};

export default function UserList(props: Props) {
  const [q, setQ] = useState('');
  const [role, setRole] = useState<VisibleRole | 'all' | undefined>(props.filterOptions.role?.defaultValue);
  const defaultBusinessDomainOption = {
    value: '0',
    label: 'All business domains',
  };
  const defaultIndustryOption = {
    value: '0',
    label: 'All industries',
  };
  const [businessDomain, setBusinessDomain] = useState<Option>(defaultBusinessDomainOption);
  const [industry, setIndustry] = useState<Option>(defaultIndustryOption);
  const [expertise, setMentorExpertise] = useState<{ value: MentorExpertise; label: string }[]>(
    props.filterOptions.mentorExpertise?.defaultValue?.map(value => ({
      value,
      label: mentorExpertise[value],
    })) ?? [],
  );

  const debouncedQuery = useDebouncedValue(q, 350);

  const industries: Option[] = getOrElse(props.filterOptions.industries, []).map(industry => ({
    value: industry.id.toString(),
    label: industry.name,
  }));

  const { resource: businessDomainsResource } = useBusinessDomains();
  const businessDomains: Option[] = getOrElse(businessDomainsResource, emptyPaginatedResult()).values.map(
    businessDomain => ({
      value: businessDomain.id.toString(),
      label: businessDomain.name,
    }),
  );

  const [users, , , onSeeMore, reload] = usePaginatedResourceLegacy(
    pagOpts => {
      return fetchUsers(
        props.fetchUsers,
        role,
        q,
        expertise.map(value => value.value),
        businessDomain.value,
        industry.value && industry.value !== '0' ? [industry.value] : [],
        pagOpts || {
          limit,
        },
      );
    },
    [role, businessDomain, industry, JSON.stringify(expertise)], // stringify array to avoid re-renders if the list doesn't change
  );

  useEffect(() => {
    reload();
  }, [debouncedQuery]);

  const queryOnChange = (value: string) => {
    setMentorExpertise([]);
    setIndustry(defaultIndustryOption);
    setBusinessDomain(defaultBusinessDomainOption);
    setQ(value);
  };

  const roleSelectValues = props.filterOptions.role?.values ?? [];

  const roleSelectValueOptions = roleSelectValues.reduce<{ [val: string]: string }>((memo, curr) => {
    memo[curr.value] = curr.label;
    return memo;
  }, {});

  const roleDisplayName = !role || role === 'all' ? 'All profiles' : roleDisplayNamePlural(role);

  const resetFilters = () => {
    setMentorExpertise([]);
    setRole(props.filterOptions.role?.defaultValue ?? undefined);
    setIndustry(defaultIndustryOption);
    setBusinessDomain(defaultBusinessDomainOption);
    setQ('');
  };

  const hasFilters =
    q != '' ||
    role != props.filterOptions.role.defaultValue ||
    industry.value != '0' ||
    businessDomain.value != '0' ||
    expertise.length > 0;

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const match = useRouteMatch();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={4}>
          <SectionHeading heading="Filtering" addMobileMargin />
          {/* Explicitly specify margins where MUI grid spacing causes overflow issues */}
          <Card className={isMdUp ? 'u-content-spacing-right' : 'u-content-spacing-bottom'}>
            <div className={styles.filterRow}>
              <div className={styles.filter}>
                <Select
                  name="role"
                  value={role ? { value: role, label: roleSelectValueOptions[role] } : roleSelectValues[0]}
                  onChange={e => setRole(e?.value as VisibleRole | 'all')}
                  options={roleSelectValues.filter(v => v.isVisible).map(v => ({ value: v.value, label: v.label }))}
                  getOptionLabel={option => option.label}
                />
              </div>
              <div className={styles.filter}>
                <Select
                  name="business-domains"
                  value={businessDomain}
                  onChange={value => value && setBusinessDomain(value)}
                  options={businessDomains}
                  getOptionLabel={option => option.label}
                />
              </div>
            </div>
            <div className={styles.filterRow}>
              <div className={styles.filter}>
                <Select
                  name="industries"
                  value={industry}
                  onChange={value => value && setIndustry(value)}
                  options={industries}
                  getOptionLabel={option => option.label}
                />
              </div>
              <div className={styles.filter}>
                <TextField
                  autoFocus
                  fullWidth
                  placeholder="Search by name or location"
                  value={q}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => queryOnChange(e.target.value)}
                />
              </div>
            </div>
            {props.filterOptions.mentorExpertise?.enabled && (
              <div className={styles.filterRow}>
                <div className={styles.filter}>
                  <MultiSelect
                    name="mentorExpertise"
                    placeholder="Mentor expertise"
                    values={expertise}
                    options={mentorExpertises}
                    onChange={setMentorExpertise}
                    renderValue={SkillChip}
                    getOptionLabel={option => option.label}
                  />
                </div>
                <div className={styles.filter} style={{ marginBottom: 0 }} />
              </div>
            )}
            <div>
              <Button kind="tertiary" onClick={() => resetFilters()} disabled={!hasFilters}>
                Reset filters
              </Button>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <LoadableResource resource={users}>
            {users => {
              return (
                <>
                  <h2 className={classNames('text-h4 u-half-spacing-bottom', { ['u-content-spacing-left']: isMobile })}>
                    {roleDisplayName} ({users.total})
                  </h2>
                  {users.values.length > 0 ? (
                    users.values.map(user => (
                      <div key={user.id} className={'u-half-spacing-bottom'}>
                        {props.renderUser ? (
                          props.renderUser(user)
                        ) : (
                          <UserCard href={userDialogUrl(match.url, user.cwUserId)} user={user} />
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="u-content-spacing-bottom">
                      No {roleDisplayName.toLocaleLowerCase()} match the chosen criteria
                    </p>
                  )}
                  {users.total > users.limit && (
                    <SeeMore
                      render={(onClick, remaining) => (
                        <Button onClick={onClick} kind="tertiary" className="u-half-spacing-top">
                          See more ({remaining})
                        </Button>
                      )}
                      resource={users}
                      loadResource={onSeeMore}
                      limit={users.limit}
                    />
                  )}
                </>
              );
            }}
          </LoadableResource>
        </Grid>
      </Grid>
    </div>
  );
}
