import React, { useState } from 'react';
import Dialog, { Content as DialogContent } from 'ui/views/dialogs/Dialog';
import UserCard from 'ui/domain/Users/UserCard';
import UserPreview from 'domain/users/UserProfile/UserPreview';
import { UserProfile } from 'types/user';
import { Grid, IconButton } from '@mui/material';
import SectionHeading from 'ui/elements/SectionHeading';
import UserListDialog from './UserListDialog';
import { RoomRoles } from './types';
import { roleDisplayNamePlural } from './utils';
import AddAction from 'ui/elements/actions/AddAction';
import { capitalize } from 'util/stringUtils';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import { Link, Route, Switch } from 'react-router-dom';
import useRoute from 'hooks/useRoute';
import InfoIcon from 'ui/elements/icons/InfoIcon';
import InfoMessageInline from 'ui/views/messages/InfoMessageInline';
import { PaginatedResult } from 'types';
import { UsersSearchFilter } from 'types/company/companyAPI';

interface Props {
  mode: 'create' | 'edit';
  fetchUsers: (slug: string, filter: UsersSearchFilter) => Promise<PaginatedResult<UserProfile>>;
  companyName: string;
  users: UserProfile[];
  setUsers: (users: UserProfile[]) => void;
  role: RoomRoles;
  loggedInUser?: UserProfile;
  className?: string;
  companySlug: string;
  relevantRoles: { value: string; label: string }[];
}

export default function UserList(props: Props) {
  const [userPreviewProfile, setUserPreviewProfile] = useState<undefined | UserProfile>(undefined);

  const { push, match } = useRoute();
  const handleClose = () => push(match.url);

  return (
    <div className={props.className}>
      <SectionHeading heading={capitalize(roleDisplayNamePlural(props.role))} />
      <Grid container spacing={2}>
        {props.role === 'admin' && (
          <Grid item xs={12}>
            <div className="u-flex">
              <InfoMessageInline icon={<InfoIcon />}>
                Admins in {props.companyName} will be added as admins in the room
              </InfoMessageInline>
            </div>
          </Grid>
        )}
        {props.role === 'admin' && props.loggedInUser && props.mode === 'create' && (
          <Grid item xs={12} md={6} lg={4}>
            <UserCard user={props.loggedInUser} onClick={() => setUserPreviewProfile(props.loggedInUser)} />
          </Grid>
        )}
        {props.users.map(user => (
          <Grid item key={user.id} xs={12} md={6} lg={4}>
            <UserCard
              user={user}
              onClick={() => setUserPreviewProfile(user)}
              actions={
                <>
                  <IconButton
                    color="secondary"
                    onClick={() => props.setUsers(props.users.filter(u => u.id !== user.id))}
                  >
                    <TrashIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <div className="u-flex">
            <Link to={`${match.url}/${props.role}`}>
              <AddAction>Add {roleDisplayNamePlural(props.role)}</AddAction>
            </Link>
          </div>
        </Grid>
      </Grid>

      <Switch>
        <Route
          exact
          path={`${match.path}/${props.role}`}
          render={() => (
            <UserListDialog
              companySlug={props.companySlug}
              fetchUsers={props.fetchUsers}
              onClose={handleClose}
              role={props.role}
              assignUser={u => props.setUsers([...props.users, u])}
              unAssignUser={u => props.setUsers(props.users.filter(u2 => u2.id !== u.id))}
              assignedUsers={props.users}
              relevantRoles={props.relevantRoles}
            />
          )}
        />
      </Switch>

      <Dialog open={!!userPreviewProfile} onClose={() => setUserPreviewProfile(undefined)}>
        <DialogContent className="u-content-spacing-y">
          {userPreviewProfile && <UserPreview cwUserId={userPreviewProfile.cwUserId}></UserPreview>}
        </DialogContent>
      </Dialog>
    </div>
  );
}
