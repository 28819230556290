import { notify } from 'actions/snackbar';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { requestWrapper2 } from 'apis/requestWrapper';
import { ProgressHandler } from 'hooks/useProgressHandler';

export function unassignMentor(
  communitySlug: string,
  companyId: number,
  mentorshipId: UUID,
  onSuccess: () => void,
  progressHandler: ProgressHandler,
  notifyA: typeof notify,
) {
  requestWrapper2(
    async () => {
      await communitiesApi.mentorNetwork.mentorships.mentorAssignments.remove(communitySlug, companyId, mentorshipId);
      notifyA('success', 'Mentor unassigned');
      onSuccess();
    },
    () => notifyA('error', 'Failed to unassign mentor'),
    progressHandler,
  );
}
