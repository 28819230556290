import { bluePlanetTheme } from 'ui/theme';

interface RGBColor {
  red: number;
  green: number;
  blue: number;
}

export function convertHexToRGB(hex: string): RGBColor {
  const split = hex.replace('#', '').match(/.{1,2}/g);
  const rgb = split?.map(s => parseInt(s, 16)) ?? [0, 0, 0];
  return { red: rgb[0], green: rgb[1], blue: rgb[2] };
}

export function contrastColorRGB(color: RGBColor) {
  return color.red * 0.299 + color.green * 0.587 + color.blue * 0.114 > 186
    ? bluePlanetTheme.bluePlanetPalette.indigo.main
    : bluePlanetTheme.palette.secondary.contrastText;
}
