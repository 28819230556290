import { getCompanyProfiles } from 'actions/companyProfiles';
import { UpdatesFilter } from 'actions/content/updates';
import { getUserProfiles } from 'actions/userProfiles';
import { withPagination } from 'apis/utils';
import config from 'config';
import useSWR, { invalidateStartsWith } from 'hooks/useSWR';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { CommunityUpdate } from 'types/content';
import { notEmpty } from 'util/arrayUtils';
import { useSWRResource } from 'util/resource/useSWRResource';

export const communityUpdatesKey = (
  communityId: number,
  filterBy: UpdatesFilter,
  paginationOptions: PaginationOptions,
  topicId?: string,
) => {
  const byCompanyId = filterBy.type === 'my updates' ? filterBy.companyId : undefined;
  const filterByParams = filterBy.type === 'archive' ? { archived: true } : byCompanyId ? { byCompanyId } : {};
  const topicParams = topicId ? { topicId } : {};
  const params = Object.assign(filterByParams, topicParams);
  const url =
    filterBy.type === 'archive'
      ? `${config.CONTENT_API_URL}/companies/${communityId}/updates`
      : `${config.CONTENT_API_URL}/communities/${communityId}/updates`;

  return withPagination(url, paginationOptions, params);
};

export const reloadAllUpdates = (communityId: number) => {
  invalidateStartsWith([
    `${config.CONTENT_API_URL}/companies/${communityId}/updates`,
    `${config.CONTENT_API_URL}/communities/${communityId}/updates`,
  ]);
};

export function useCommunityUpdates(
  communityId: number,
  paginationOptions: PaginationOptions,
  filterBy: UpdatesFilter,
  topicId?: string,
) {
  const dispatch = useDispatch();

  const actions = bindActionCreators(
    {
      getCompanyProfiles,
      getUserProfiles,
    },
    dispatch,
  );

  const swrResult = useSWR<PaginatedResult<CommunityUpdate>>(
    communityUpdatesKey(communityId, filterBy, paginationOptions, topicId),
    undefined,
    {
      revalidateIfStale: true,
    },
  );

  const swrResource = useSWRResource(swrResult);

  useEffect(() => {
    if (swrResource.resource.state === 'fetched') {
      const companyIds = swrResource.resource.resource.values
        .map(u => (u.postedBy.type === 'company' ? u.postedBy.companyId : u.communityId))
        .filter(notEmpty);
      actions.getCompanyProfiles(companyIds);

      const userIds = [
        ...swrResource.resource.resource.values.map(u => u.update.creatorCwUserId),
        ...swrResource.resource.resource.values.map(u =>
          u.postedBy.type === 'user' ? u.postedBy.cwUserId : undefined,
        ),
      ].filter(notEmpty);
      actions.getUserProfiles(userIds);
    }
  }, [swrResource.resource.state, swrResource.resource.state === 'fetched' && swrResource.resource.resource.values]);

  return swrResource;
}
