import React from 'react';

import CompanyMastersOnlyTooltip from 'ui/domain/tooltips/CompanyMastersOnlyTooltip';
import { OnboardingPart, onboardingApiUrls } from 'apis/OnboardingAPI';
import Resource from 'util/resource/Resource';
import SectionHeading from 'ui/elements/SectionHeading';
import useResource from 'util/resource/useResource';
import Checklist from './Checklist';

export default function CommunityChecklist({ communitySlug }: { communitySlug: string }) {
  const { resource: onboardingResource, mutate } = useResource<OnboardingPart>(
    onboardingApiUrls.getCommunityChecklist(communitySlug),
  );

  return (
    <div data-intercom-target="setup-widget">
      <Resource resource={onboardingResource}>
        {onboarding => {
          return (
            onboarding.status === 'InProgress' && (
              <>
                <SectionHeading heading="Get started" className="u-content-spacing-bottom" addMobileMargin>
                  <CompanyMastersOnlyTooltip
                    style={{ display: 'flex', height: '20px' }}
                    title="Community profile progress. Only community administrators will see this"
                  />
                </SectionHeading>
                <Checklist onboardingSteps={onboarding.steps} communitySlug={communitySlug} reloadOnboarding={mutate} />
              </>
            )
          );
        }}
      </Resource>
    </div>
  );
}
