import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { TopicDTO } from 'types/company/community';
import Button from 'ui/elements/buttons/Button';
import { DialogActions } from 'ui/views/dialogs/Dialog';
import { PAGINATION_SMALL_PAGESIZE } from 'util/constants';
import TopicList from './TopicList';

interface Props {
  discussions: PaginatedResult<TopicDTO>;
  addPageToDiscussions: (paginationOptions: PaginationOptions) => Promise<void>;
  dialogHandler: DialogHandlerProps;
  communityId: number;
  companyId: number;
  companyName: string;
  setActiveTopic: (id: string) => void;
  editTopic: (topic: TopicDTO) => void;
  canEdit: boolean;
  openEditDialog: (discussion: TopicDTO) => void;
}

export default function TopicListDialog(props: Props) {
  const { dialogHandler, discussions, setActiveTopic } = props;

  return (
    <Dialog
      aria-labelledby="discussions-dialog-title"
      aria-describedby="discussions-dialog-description"
      fullWidth
      maxWidth="sm"
      onClose={dialogHandler.close}
      open={dialogHandler.isOpen}
    >
      <DialogTitle id="discussions-dialog-title">
        <div className="u-flex-align-center u-flex-space-between">
          <span className="u-flex-align-center">Discussions for {props.companyName}</span>
          <IconButton color="inherit" aria-label="Close" onClick={dialogHandler.close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="discussions-dialog-description">
          Click the topic link from the list below to join the discussion
        </DialogContentText>
        <TopicList
          discussions={discussions}
          onClick={setActiveTopic}
          canEdit={props.canEdit}
          openEditDialog={props.openEditDialog}
        />
        <SeeMore
          text="Show more discussions"
          resource={discussions}
          limit={PAGINATION_SMALL_PAGESIZE}
          loadResource={props.addPageToDiscussions}
        />
      </DialogContent>
      <DialogActions>
        <Button
          kind="secondary"
          onClick={e => {
            e.stopPropagation();
            dialogHandler.close();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
