import React from 'react';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { TinyCommunity } from 'types/company/community';
import { ICompany } from 'types/company/company';
import { getOrElse } from 'util/resource';
import featureToggle from 'featureToggle';
import CommunityCompanyLabels from 'domain/communities/CompanyDialog/Labels/CommunityCompanyLabels';
import DiscussionTopics from 'domain/communities/CompanyDialog/Discussions/components/DiscussionTopics';
import CommunityRooms from 'domain/communities/rooms';
import TransferToCommunity from '../../InnovasjonNorge/TransferToCommunity';
import DealLeads from './DealLeads/DealLeads';
import Mentorships from './Mentorships';
import { isInnovasjonNorge } from 'domain/communities/InnovasjonNorge/utils';
import { HasScope } from 'hooks/useCommunityRole';
import { useCommunityCompanyLabels } from 'apis/CompanyAPI/communities/useCommunityLabels';

interface Props {
  companyId: number;
  community: TinyCommunity;
  companyProfile: ICompany;
  discussionUrl: (discussionId: string) => string;
  hasScopeInCommunity: HasScope;
}

export default function Sidebar(props: Props) {
  const { community, companyId, companyProfile } = props;
  const hasScope = props.hasScopeInCommunity;

  const [dealLeadsResource, , , reloadDealLeads] = useResourceLegacy(
    () => communitiesApi.dealLeads.list(props.community.slug, props.companyId),
    [props.community.slug, props.companyId],
  );

  const canSeeLabels = hasScope('Read', 'Community.Labels');
  const { mutate: refreshLabels } = useCommunityCompanyLabels(canSeeLabels, props.community.id, props.companyId);

  return (
    <div className="u-content-spacing-top">
      {isInnovasjonNorge(props.community.communityType) && (
        <TransferToCommunity
          companyName={props.companyProfile.name}
          community={props.community}
          companyId={props.companyId}
          refreshLabels={refreshLabels}
        />
      )}
      {hasScope('Write', 'Community') && (
        <Mentorships community={community} companyId={companyProfile.id} companyName={companyProfile.name} />
      )}
      {hasScope('Write', 'Community') && featureToggle.communityCanAssignRooms(props.community.id) && (
        <div className="u-content-spacing-bottom">
          <CommunityRooms community={community} company={props.companyProfile} />
        </div>
      )}
      {featureToggle.communityHasDealLeads(props.community.id) && (
        <div className="u-content-spacing-bottom">
          <DealLeads
            community={community}
            companyId={companyId}
            refresh={reloadDealLeads}
            dealLeads={getOrElse(dealLeadsResource, { values: [] }).values}
            hasScopeInCommunity={props.hasScopeInCommunity}
          />
        </div>
      )}
      {canSeeLabels && (
        <div className="u-content-spacing-bottom">
          <CommunityCompanyLabels
            communitySlug={props.community.slug}
            communityId={props.community.id}
            companyId={props.companyProfile.id}
            companyName={props.companyProfile.name}
          />
        </div>
      )}
      {hasScope('Read', 'Discussion.Topic') && featureToggle.communityHasDiscussions(props.community.id) && (
        <div className="u-content-spacing-bottom">
          <DiscussionTopics
            community={community}
            companyId={companyId}
            companyName={companyProfile.name}
            dealLeads={getOrElse(dealLeadsResource, { values: [] })}
          />
        </div>
      )}
    </div>
  );
}
