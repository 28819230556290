import React, { useEffect, useState } from 'react';
import { OnboardingStep, Step } from 'apis/OnboardingAPI';
import ChecklistPercent from 'ui/elements/ChecklistPercent';
import { getChecklistStepText } from 'domain/onboarding/utils';
import useRoute from 'hooks/useRoute';
import CommunityBrandingDialog from 'domain/onboarding/community/BrandingDialog';
import { communityUrls } from 'urls';
import InviteDialog from 'pages/Community/CommunityManagement/invite/InviteDialog';

interface Props {
  onboardingSteps: Step[];
  communitySlug: string;
  reloadOnboarding: () => void;
}

function goToOnboardingStep(
  step: OnboardingStep,
  openStep: (step: OnboardingStep) => void,
  push: (href: string) => void,
  communitySlug: string,
) {
  switch (step) {
    case 'CommunityDescription':
      return () => push(communityUrls.settings.information(communitySlug, 'general-information'));
    case 'InviteYourTeam':
      return () => push(communityUrls.manageMembers.invite(communitySlug, 'Employee'));
    case 'InviteMembers':
      return () => openStep(step);
    case 'CommunityBranding':
      return () => openStep(step);
  }
  return undefined;
}

export default function Checklist({ onboardingSteps, communitySlug, reloadOnboarding }: Props) {
  const [filteredSteps, setFilteredSteps] = useState(onboardingSteps.filter(it => !it.isCompleted));

  useEffect(() => {
    setFilteredSteps(
      onboardingSteps.filter(
        //Keep the completed steps upon finishing them so they will be animated out in ChecklistPercent
        it => !it.isCompleted || filteredSteps.some(filteredStep => filteredStep.step === it.step),
      ),
    );
  }, [onboardingSteps]);

  const { push } = useRoute();
  const [currentDialog, setCurrentDialog] = useState<OnboardingStep | undefined>(undefined);

  return (
    <>
      <CommunityBrandingDialog
        communitySlug={communitySlug}
        isOpen={currentDialog === 'CommunityBranding'}
        onClose={() => {
          reloadOnboarding();
          setCurrentDialog(undefined);
        }}
      />
      {currentDialog === 'InviteMembers' && (
        <InviteDialog
          communitySlug={communitySlug}
          onClose={() => {
            reloadOnboarding();
            setCurrentDialog(undefined);
          }}
        />
      )}
      {filteredSteps.map(step => (
        <ChecklistPercent
          className="u-quarter-spacing-bottom"
          key={step.step}
          percent={step.completenessPercentage}
          text={getChecklistStepText(step.step)}
          onClick={goToOnboardingStep(step.step, setCurrentDialog, push, communitySlug)}
        />
      ))}
    </>
  );
}
