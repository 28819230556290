import LoadableResource from 'util/resource/Resource';
import React from 'react';
import presentationIllustration from 'ui/illustrations/presentation.svg';
import Card from 'ui/views/cards/Card';
import ManagePitchDocuments from './ManagePitchDocumentsDialog';
import SmallAccordion from 'ui/modules/Accordion/SmallAccordion';
import PitchDocumentTile from 'domain/companies/Documents/Document/PitchDocumentTile';
import { KeyedMutator } from 'swr';
import { IResource } from 'util/resource';
import { useCompanyLastUpdated } from '../LastUpdated';
import { ICompany, VideoListDTO } from 'types/company';
import LinkButton from 'ui/elements/buttons/LinkButton';
import PitchDeckPreviewContainer from 'domain/companies/Documents/Document/PitchDeck/PitchDeckPreviewContainer';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import { PitchFiles } from 'types/company/pitchdeck';
import Loading from 'ui/elements/Loading';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';

const PitchPreviewProcessingContainer = styled.div`
  margin-top: ${contentSpacing};
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${sectionSpacing};
  border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.main};
  border-radius: 4px;
`;
const PitchPreviewProcessing = () => (
  <PitchPreviewProcessingContainer>
    <Loading className="u-section-spacing-top"></Loading>
    <p className="text-metadata">Generating preview of your pitch. This usually takes less than a minute.</p>
  </PitchPreviewProcessingContainer>
);

interface Props {
  className?: string;
  company: ICompany;
  userCanUploadDocuments: boolean;
  documents: IResource<PitchFiles>;
  setDocuments: KeyedMutator<PitchFiles>;
  videos: VideoListDTO;
  onSaveVideos: (videos: VideoListDTO) => void;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (isOpen: boolean) => void;
  shouldAutofocusVideos?: boolean;
  viewAs: CompanyViewAs;
}

const maxVisibleFileCount = 2;
export default function PitchDocuments({
  documents: documentsResource,
  setDocuments,
  company,
  userCanUploadDocuments,
  className,
  videos,
  onSaveVideos,
  isEditDialogOpen,
  setIsEditDialogOpen,
  shouldAutofocusVideos,
  viewAs,
}: Props) {
  const { setLastUpdated } = useCompanyLastUpdated(company.id);

  return (
    <LoadableResource resource={documentsResource} renderError={() => <></>}>
      {documents => {
        const { attachments, pitchDeck, preview } = documents;

        const showEmptyPlaceholder = attachments.length === 0 && userCanUploadDocuments;
        const hasPitchDocuments = !!pitchDeck || documents.attachments.length > 0;

        return hasPitchDocuments || userCanUploadDocuments ? (
          <>
            <Card
              className={className}
              onClick={showEmptyPlaceholder ? () => setIsEditDialogOpen(true) : undefined}
              type={showEmptyPlaceholder ? 'dashed' : 'default'}
              style={{ position: 'relative' }}
            >
              {userCanUploadDocuments && !hasPitchDocuments && (
                <>
                  <div className="u-flex u-flex--center u-flex-align-center">
                    <img src={presentationIllustration} width="72" />
                    <div className="u-flex u-flex--column u-section-spacing-left">
                      <p className="text-body">
                        Add a pitch deck, company presentation or any other documentation you want to share with
                        visitors of your company profile. The documents will be visible in all communities you are part
                        of.
                      </p>
                      <LinkButton onClick={() => setIsEditDialogOpen(true)}>Select files</LinkButton>
                    </div>
                  </div>
                </>
              )}
              {pitchDeck && (
                <>
                  {preview?.status === 'Processing' && viewAs === 'Admin' && <PitchPreviewProcessing />}
                  {preview?.status === 'Ready' ? (
                    <PitchDeckPreviewContainer
                      pitchDeckId={pitchDeck.id}
                      document={pitchDeck}
                      preview={preview}
                      company={company}
                      pitchDeckTitle={videos.values.length > 0 ? 'Pitch Deck' : undefined}
                      viewAs={viewAs}
                    />
                  ) : (
                    <PitchDocumentTile key={pitchDeck.id} companyId={company.id} document={pitchDeck} isPitchDeck />
                  )}
                </>
              )}
              {attachments.length > 0 && (
                <>
                  {preview?.status === 'Ready' && (
                    <div className="text-large text-weight-medium u-section-spacing-top u-content-spacing-bottom">
                      Additional documents
                    </div>
                  )}
                  <div className="u-flex u-flex--column">
                    {attachments.map(doc => (
                      <PitchDocumentTile
                        key={doc.id}
                        companyId={company.id}
                        document={doc}
                        isPitchDeck={doc.documentType === 'pitch_deck'}
                      />
                    ))}
                    {attachments.length > maxVisibleFileCount && (
                      <SmallAccordion viewMoreText="Show more" viewLessText="Show less" align="bottom">
                        <div className="u-flex u-flex--column">
                          {attachments.slice(maxVisibleFileCount).map(doc => (
                            <PitchDocumentTile
                              key={doc.id}
                              companyId={company.id}
                              document={doc}
                              isPitchDeck={doc.documentType === 'pitch_deck'}
                            />
                          ))}
                        </div>
                      </SmallAccordion>
                    )}
                  </div>
                </>
              )}
            </Card>
            {userCanUploadDocuments && isEditDialogOpen && (
              <ManagePitchDocuments
                onClose={() => setIsEditDialogOpen(false)}
                company={company}
                documents={documents}
                onDocumentUploaded={() => {
                  setDocuments();
                  setLastUpdated(new Date());
                }}
                onDocumentDeleted={documentId =>
                  setDocuments({ attachments: documents.attachments.filter(doc => doc.id !== documentId) })
                }
                videos={videos}
                onSaveVideos={onSaveVideos}
                shouldAutofocusVideos={shouldAutofocusVideos}
              />
            )}
          </>
        ) : null;
      }}
    </LoadableResource>
  );
}
