import React, { useState } from 'react';
import Resources from 'util/resource/Resources';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import DashboardAPI, { PulseDTO, PulseItemDTO } from 'apis/DashboardAPI';
import { CompanyOverviewSection, communityUrls } from 'urls';
import { Link } from 'react-router-dom';
import Button from 'ui/elements/buttons/Button';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { addDays, startOfDay } from 'date-fns';
import { CommunityListItem } from 'types/company/community';
import useResource from 'util/resource/useResource';
import { useUserFeed } from 'pages/Dashboard/useUserFeed';
import PulseItems from './PulseItems';
import PopMenu from 'ui/modules/PopMenu';
import FilterIcon from 'ui/elements/icons/FilterIcon';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { Hidden } from '@mui/material';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

export function getTitleAndHref(
  myCommunities: CommunityListItem[],
  item: PulseItemDTO,
): {
  title: React.ReactNode;
  href?: string;
  showCompanyIdInDialog?: { companyId: number; initialTab: CompanyOverviewSection };
  showEventIdInDialog?: UUID;
  logo: string | React.ReactNode;
} | null {
  switch (item.type) {
    case 'StartedFundingRound':
      return {
        title: `${item.company.name} started a new funding round`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'ClosedFundingRound':
      return {
        title: `${item.company.name} closed their funding round`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'UpdatedKpis':
      return {
        title: `${item.company.name} updated their metrics`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'kpis' },
        logo: item.company.logoURL,
      };
    case 'UpdatedProfile':
      return {
        title: `${item.company.name} updated their profile`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'UpdatedTeam':
      return {
        title: `${item.company.name} updated their team information`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'team' },
        logo: item.company.logoURL,
      };
    case 'UpdatedPitchDeck':
      return {
        title: `${item.company.name} uploaded a new pitch deck`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'PostedUpdate':
      const postedInCommunity = myCommunities
        .filter(c => c.roles.length > 0)
        .find(c => item.audienceCommunityIds.includes(c.id));

      return {
        title: postedInCommunity ? (
          <>
            {item.company.name} posted an update in{' '}
            <Link
              style={{ textDecoration: 'underline' }}
              to={communityUrls.overview(postedInCommunity.slug, 'updates')}
            >
              {postedInCommunity.details.name}
            </Link>
          </>
        ) : (
          `${item.company.name} posted an update`
        ),
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'updates' },
        logo: item.company.logoURL,
      };
    case 'JoinedCommunity':
      return {
        title: (
          <>
            {item.company.name} joined{' '}
            <Link style={{ textDecoration: 'underline' }} to={communityUrls.overview(item.communitySlug)}>
              {item.communityName}
            </Link>
          </>
        ),
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'CreatedEvent':
      return {
        title: `New event: ${item.title}`,
        showEventIdInDialog: item.eventId,
        logo: (
          <IconAvatar color="blue-light" size={{ width: '40px', height: '40px' }}>
            <CalendarIcon />
          </IconAvatar>
        ),
      };
  }
}

export const filter = ['All activity', 'Only posts', 'Only updates'] as const;
export type TFilter = (typeof filter)[number];
export const activityTypes = ['All', 'From my network', 'From my collections', 'Suggestions'] as const;
export type ActivityType = (typeof activityTypes)[number];

export const FEED_PAGE_SIZE = 20;

export default function Pulse({
  activityTypes,
  initialActivityType,
}: {
  activityTypes: ActivityType[];
  initialActivityType?: ActivityType;
}) {
  const [activeFilter, setActiveFilter] = React.useState<TFilter>(filter[0]);
  const companies = useCompanies();
  const communities = useCommunities();

  const startDate = startOfDay(addDays(new Date(), -90));

  const [selectedFeed, selectFeed] = useState<ActivityType>(initialActivityType || activityTypes[0]);
  const audience =
    selectedFeed === 'All'
      ? 'all'
      : selectedFeed === 'From my collections'
        ? 'shortlisted'
        : selectedFeed === 'From my network'
          ? 'following'
          : undefined;

  const [feed] = useUserFeed(startDate, { audience }, { limit: 50 }); // fiks noe paginering her

  const { resource: pulse } = useResource<PulseDTO>(
    selectedFeed === 'All'
      ? DashboardAPI.pulse.platformWide(FEED_PAGE_SIZE)
      : selectedFeed === 'Suggestions'
        ? DashboardAPI.pulse.suggestions()
        : selectedFeed === 'From my network'
          ? DashboardAPI.pulse.myNetwork()
          : DashboardAPI.pulse.myCollections(),
  );

  return (
    <>
      <Hidden smDown>
        <SectionHeadingLarge
          heading={
            <>
              {activityTypes.length > 1 ? (
                <ButtonList>
                  {activityTypes.map(feedType => {
                    const isActive = selectedFeed === feedType;
                    return (
                      <Button
                        key={feedType}
                        kind={'secondary'}
                        color={'grey'}
                        onClick={() => selectFeed(feedType)}
                        isActive={isActive}
                      >
                        {feedType}
                      </Button>
                    );
                  })}
                </ButtonList>
              ) : (
                'Activity ' + selectedFeed.toLocaleLowerCase()
              )}
            </>
          }
        >
          <PopMenu
            items={filter.map(f => {
              return {
                text: <>{f}</>,
                onClick: () => setActiveFilter(f),
                selected: f === activeFilter,
              };
            })}
            renderAnchor={onClick => (
              <Button kind="tertiary" color="grey" onClick={onClick} size="small">
                <FilterIcon className="u-half-spacing-right" fontSize="small" />
                {activeFilter}
                <ChevronDownIcon className="u-half-spacing-left" fontSize="small" />
              </Button>
            )}
            icon={<FilterIcon />}
          />
        </SectionHeadingLarge>
      </Hidden>

      <Hidden smUp>
        <div className="u-content-spacing-y u-flex-space-between u-flex-align-center">
          {activityTypes.length > 1 ? (
            <PopMenu
              items={activityTypes.map(f => {
                return {
                  text: <>{f}</>,
                  onClick: () => selectFeed(f),
                  selected: f === selectedFeed,
                };
              })}
              renderAnchor={onClick => (
                <div className="u-flex u-flex-end">
                  <Button kind="tertiary" color="grey" onClick={onClick}>
                    <FilterIcon className="u-half-spacing-right" fontSize="small" />
                    {selectedFeed}
                    <ChevronDownIcon className="u-half-spacing-left" fontSize="small" />
                  </Button>
                </div>
              )}
              icon={<FilterIcon />}
            />
          ) : (
            <MobileMargin>{selectedFeed}</MobileMargin>
          )}
          <PopMenu
            items={filter.map(f => {
              return {
                text: <>{f}</>,
                onClick: () => setActiveFilter(f),
                selected: f === activeFilter,
              };
            })}
            renderAnchor={onClick => (
              <div className="u-flex u-flex-end">
                <Button kind="tertiary" color="grey" onClick={onClick}>
                  <FilterIcon className="u-half-spacing-right" fontSize="small" />
                  {activeFilter}
                  <ChevronDownIcon className="u-half-spacing-left" fontSize="small" />
                </Button>
              </div>
            )}
            icon={<FilterIcon />}
          />
        </div>
      </Hidden>
      <Resources resources={[companies.resource, communities.resource, feed, pulse]}>
        {([companies, communities, feed, pulse]) => (
          <PulseItems
            communities={communities.values}
            companies={companies.values}
            // Updates from suggestions isn't supported atm, so we have to filter them out as a hotfix
            feed={selectedFeed === 'Suggestions' ? [] : feed.values}
            pulse={pulse}
            selectedFeed={selectedFeed}
            activeFilter={activeFilter}
          />
        )}
      </Resources>
    </>
  );
}
