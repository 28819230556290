import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { downloadBlob } from 'domain/shared/Files/actions';
import useNotify from 'hooks/useNotify';
import React from 'react';
import { Mentorship } from 'apis/MentorAPI/types';
import useLazyResource from 'util/resource/useLazyResource';
import DocumentListItem from '../DocumentListItem';

interface Props {
  communitySlug: string;
  companyId: number;
  mentorship: Mentorship;
}

export default function FinalReportDocument(props: Props) {
  const notify = useNotify();
  const [download, isDownloading] = useLazyResource(
    () =>
      communitiesApi.mentorNetwork.mentorships
        .downloadFinalReport(props.communitySlug, props.companyId, props.mentorship.id)
        .then(response => downloadBlob(response)),
    {
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );
  return props.mentorship.finalReportFileId ? (
    <DocumentListItem
      key={props.mentorship.finalReportFileId}
      name="Final report"
      onDownload={() => download(props.mentorship.finalReportFileId)}
      isDownloading={isDownloading}
    />
  ) : (
    <></>
  );
}
