import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import SectionHeading from 'ui/elements/SectionHeading';
import CompanyCard from 'domain/companies/CompanyCard/CompanyCard';
import { communityUrls } from 'urls';
import { pluralize } from 'util/stringUtils';
import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import { CompaniesListFilters, CommunityCompanyCardDTO } from 'types/company/community';
import Carousel, { SeeMoreCard } from 'ui/elements/Carousel';
import { PaginatedResult } from 'types';
import { getOrElse } from 'util/resource';
import { CommunityCompanySortOptions } from 'apis/CompanyAPI/communities/types/types';
import useSWR from 'hooks/useSWR';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityBleedAmounts } from 'ui/views/layouts/CommunityPage';
import { hasMorePages } from 'util/paginationUtils';

interface Props {
  communitySlug: string;
  heading: { singular: string; plural: string };
  companies: PaginatedResult<CommunityCompanyCardDTO>;
  filters?: CompaniesListFilters;
}

export function useFeaturedCommunityCompanies(
  communitySlug: string,
  opts: {
    orderBy: CommunityCompanySortOptions;
    limit: number;
    filters?: CompaniesListFilters;
    shouldFetch?: boolean;
  },
) {
  const { orderBy, limit, filters = {}, shouldFetch = true } = opts || {};
  const resource = useSWR<PaginatedResult<CommunityCompanyCardDTO>>(
    shouldFetch ? communityAPIUrls.companies.list(communitySlug, orderBy, { limit }, filters) : undefined,
  );
  return useSWRResource(resource);
}

export default function FeaturedCommunityCompanies({ communitySlug, filters, heading, companies }: Props) {
  const match = useRouteMatch();

  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();
  return (
    <>
      {companies.total > 0 && (
        <div className="u-section-spacing-bottom">
          <SectionHeading
            heading={`Explore ${companies.total} ${pluralize(companies.total, heading.singular, heading.plural)}`}
            actionsAlignment="left"
          >
            <Link to={communityUrls.members.overview(communitySlug, 'companies', filters)} className="text-link-small">
              See all
            </Link>
          </SectionHeading>
          <Carousel
            slidesWidth={{ md: '40%', lg: '28%' }}
            options={{ dragFree: 'mobile' }}
            bleed={communityBleedAmounts}
          >
            {companies.values.map(company => (
              <CompanyCard
                key={company.details.id}
                company={company}
                action={{
                  type: 'href',
                  handler: company => `${match.url}/pitch/${company.id}?ac=community-featured`,
                }}
                shortlists={getOrElse(shortlistResource, { values: [] }).values}
                setShortlists={setShortlistResource}
              />
            ))}
            {hasMorePages(companies) && (
              <SeeMoreCard href={communityUrls.members.overview(communitySlug, 'companies', filters)} />
            )}
          </Carousel>
        </div>
      )}
    </>
  );
}
