import { CompanySettingsSections } from 'pages/CompanySettings/utils';
import { CommunitySettingsSections } from 'pages/Community/CommunityManagement/utils';

import { DocumentDTO } from 'types/documents';
import { ProfileType } from 'types/company';
import { iso8601DateString } from 'util/dateUtils';
import config from 'config';
import { ExploreCommunityCategory } from 'pages/Community/CommunityMembers/utils';
import { CommunityInviteAs, CommunityVisibility, CompaniesListFilters } from 'types/company/community';
import { companiesListFiltersToQueryString } from 'apis/CompanyAPI/communities/communitiesApi';
import { CompanySharingSection } from 'pages/CompanySettings/CompanySharing';
import { CompanyToolsSection } from 'pages/CompanySettings/CompanyTools';
import { roleDisplayName } from 'domain/companies/roleUtils';

export type CompanyId = number | string;

export const dateRangeQueryParam = (fromDate: Date, toDate: Date): string =>
  `from=${iso8601DateString(fromDate)}&to=${iso8601DateString(toDate)}`;

export const backofficeUrls = {
  view: '/backoffice',
  companies: {
    list: '/backoffice/companies',
    approveCompanies: '/backoffice/approve-companies',
    view: (companyId: number) => `/backoffice/companies/${companyId}`,
    captable: (companyId: number) => `/backoffice/companies/${companyId}/captable`,
  },
  communities: {
    list: '/backoffice/communities',
    view: (communitySlug: string) => `/backoffice/communities/${communitySlug}`,
  },
  users: {
    list: '/backoffice/users',
    view: (cwUserId: UUID) => `/backoffice/users/${cwUserId}`,
  },
  emails: {
    list: '/backoffice/email-events',
    view: (eventId: number) => `/backoffice/email-events/${eventId}`,
    viewUpdate: (updateId: UUID) => `/backoffice/email-events/update/${updateId}`,
  },
  industries: {
    list: '/backoffice/industries',
  },
  stats: '/backoffice/stats',
  antiFraud: '/backoffice/anti-fraud',
  deleteUserRequests: '/backoffice/delete-user-requests',
  events: {
    list: '/backoffice/events',
  },
  organizations: {
    list: '/backoffice/organizations',
    approveOrganizations: '/backoffice/approve-organizations',
    create: '/backoffice/organizations/create',
    edit: (organizationId: string) => `/backoffice/organizations/${organizationId}/edit`,
  },
  matchmaking: {
    overview: '/backoffice/matchmaking',
    companies: '/backoffice/matchmaking/companies',
    users: '/backoffice/matchmaking/users',
    notify: '/backoffice/matchmaking/notify',
    history: '/backoffice/matchmaking/history',
    user: (cwUserId: string) => `/backoffice/matchmaking/users/${cwUserId}`,
    company: (companyId: number) => `/backoffice/matchmaking/companies/${companyId}`,
  },
  registrationTracking: {
    list: '/backoffice/registrations',
    view: (queryParams: {
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      utmTerm?: string;
      utmContent?: string;
    }) => {
      return `/backoffice/registration?${new URLSearchParams(queryParams).toString()}`;
    },
  },
};

export const externalUrls = {
  mapboxPlacesPermanent: (searchText: string, maxResults: number, searchArea: 'places' | 'addresses' | 'countries') => {
    const searchTypes = searchArea === 'places' ? 'place' : searchArea === 'addresses' ? 'address,place' : 'country';
    return `https://api.mapbox.com/geocoding/v5/mapbox.places-permanent/${searchText}.json?language=en&limit=${maxResults}&types=${searchTypes}&access_token=${config.MAPBOX_API_TOKEN}`;
  },
};

export const staticFileLocations = {
  defaultCompanyImage:
    'https://res.cloudinary.com/crowdworks/image/upload/v1599053507/production/static/default-company-banner-dark-faded.png',
  defaultCompanyProfileImage:
    'https://res.cloudinary.com/crowdworks/image/upload/v1652344658/production/static/CW-profile-companies.png',
  defaultCommunityProfileImage:
    'https://res.cloudinary.com/crowdworks/image/upload/v1652866562/production/static/CW-Profile-community.png',
  defaultProfileImage:
    'https://res.cloudinary.com/crowdworks/image/upload/v1652344653/production/static/CW-Profile-person.png',
  termsOfAgreements: 'https://assets.crowdworks.it/terms/Agreement_terms_CW_general.pdf',
  dataProcessingAgreement: 'https://assets.crowdworks.it/terms/Data_Processing_Agreement_Crowdworks_Company.pdf',
};

export const userUrls = {
  dashboard: {
    home: () => `/`,
    manage: () => `/manage`,
    network: {
      overview: () => `/network`,
      communities: () => `/network/communities`,
      companies: () => `/network/companies`,
    },
    collections: () => `/collections`,
  },
  discover: '/discover',
  profile: {
    view: '/profile',
    edit: '/profile/edit',
    security: '/profile/edit/security',
    communications: '/profile/edit/communications',
  },
  show: (cwUserId: string) => `/people/${cwUserId}`,
  conversations: {
    viewUserUserConversation: (conversationId: string) => `/conversation/${conversationId}`,
  },
  legal: {
    privacyPolicy: (section?: string) => `/legal/privacy-policy${section ? `#${section}` : ''}`,
    termsOfUse: () => `/legal/terms-of-use`,
  },
  registration: {
    company: (params?: { communityCode?: string; utm_source?: string; utm_medium?: string }) => {
      const searchParams = new URLSearchParams(params);
      if (searchParams.size > 0) {
        return `/registerCompany?${searchParams.toString()}`;
      } else {
        return '/registerCompany';
      }
    },
    organization: (params?: { communityCode?: string; utm_source?: string; utm_medium?: string }) => {
      const searchParams = new URLSearchParams(params);
      if (searchParams.size > 0) {
        return `/registerOrganization?${searchParams.toString()}`;
      } else {
        return '/registerOrganization';
      }
    },
    community: () => `/registerCommunity`,
    user: {
      register: (params?: { utm_source?: string; utm_medium?: string }) => {
        const searchParams = new URLSearchParams(params);
        if (searchParams.size > 0) {
          return `/register?${searchParams.toString()}`;
        } else {
          return '/register';
        }
      },
    },
  },
  deleteConfirmation: '/delete-confirmation',
  collections: {
    list: '/collections',
    view: (
      id: string,
      opts?: {
        mode?: 'duplicate';
      },
    ) => {
      const params = new URLSearchParams();
      if (opts?.mode === 'duplicate') {
        params.append('mode', 'duplicate');
      }
      return `/collections/${id}${params.toString() ? `?${params.toString()}` : ''}`;
    },
  },
};
export const exploreUrls = {
  matches: `/explore/matches`,
};

export const eventUrls = {
  view: (id: string) => `/events/${id}`,
};

export const baseEventUrl = (organizationId: UUID, eventId?: UUID) =>
  `${config.COMPANIES_API_URL}/organizations/${organizationId}/events` + (eventId ? `/${eventId}` : '');

export const organizationUrls = {
  view: (slug: string) => `/organizations/${slug}`,
  settings: {
    settings: (slug: string) => `/organizations/${slug}/settings`,
    visibility: (slug: string) => `/organizations/${slug}/settings/visibility`,
    connections: (slug: string) => `/organizations/${slug}/settings/connections`,
  },
  events: {
    create: (slug: string) => `/organizations/${slug}/events/create`,
    getEventUser: (organizationId: UUID, eventId: UUID) => `${baseEventUrl(organizationId, eventId)}/users/me`,
    listAttachments: (organizationId: UUID, eventId: UUID) => `${baseEventUrl(organizationId, eventId)}/documents`,
  },
};

const communityViewCompany = (communityId: CompanyId, companyId: CompanyId) =>
  `/communities/${communityId}/explore/companies/pitch/${companyId}`;

export const communityUrls = {
  overview: (
    communitySlug: string,
    section: 'overview' | 'updates' | 'members' | 'companies' | 'events' = 'overview',
    options?: { isPostingUpdate?: boolean; topicId?: string },
  ) => {
    const params = new URLSearchParams();
    if (options?.isPostingUpdate) {
      params.append('writeUpdate', '1');
    }
    if (options?.topicId) {
      params.append('topicId', options.topicId);
    }

    return `/communities/${communitySlug}/${section}?${params.toString()}`;
  },
  join: (communitySlug: string, communityCode: string) =>
    `/communities/${communitySlug}?join&communityCode=${communityCode}`,
  application: (communityId: CompanyId) => `/communities/${communityId}/overview/application`,
  events: {
    create: (communitySlug: string) => `/communities/${communitySlug}/events/create`,
  },
  companies: {
    view: (
      path: string,
      companyId: CompanyId,
      analyticsContext: 'community-feed' | 'companies-widget' | 'community-explore',
    ) => `${path}/pitch/${companyId}?ac=${analyticsContext}`,
    viewFromOverview: (
      communityId: CompanyId,
      companyId: CompanyId,
      analyticsContext: 'community-feed' | 'companies-widget' | 'community-explore' | 'community-overview',
    ) => `/communities/${communityId}/overview/pitch/${companyId}?ac=${analyticsContext}`,
    viewCompanyConversation: (communitySlug: string, companyId: CompanyId) =>
      `${communityViewCompany(communitySlug, companyId)}/contact`,
  },

  documents: {
    overview: (communitySlug: string) => `/communities/${communitySlug}/documents`,
    download: (communityId: number, documentId: DocumentDTO['id']) =>
      `/communities/${communityId}/documents/download/${documentId}`,
  },
  boardroom: {
    overview: (communityId: CompanyId) => `/communities/${communityId}/boardroom`,
    view: (communityId: CompanyId, updateId: string) => `/communities/${communityId}/boardroom/update/${updateId}`,
    documents: {
      baseUrl: (communityId: string) => `/communities/${communityId}/boardroom/folders`,
      folder: (communityId: string, folderId?: string) =>
        `/communities/${communityId}/boardroom/folders${folderId ? `/${folderId}` : ''}`,
      download: (communityId: string, documentId: DocumentDTO['id']) =>
        `/communities/${communityId}/boardroom/download/${documentId}`,
    },
  },
  update: {
    view: (communitySlug: string, updateId: string) => `/communities/${communitySlug}/updates/${updateId}`,
  },
  updateActivity: (communitySlug: string, updateId: string, commentId?: string) =>
    `/communities/${communitySlug}/overview/${updateId}${commentId ? `#${commentId}` : ''}`,
  settings: {
    information: (communityId: string, section: CommunitySettingsSections = undefined) =>
      section
        ? `/communities/${communityId}/settings/information?section=${section}`
        : `/communities/${communityId}/settings/information`,
    integrations: (communityId: string) => `/communities/${communityId}/settings/integrations`,
    topics: (communityId: string) => `/communities/${communityId}/settings/topics`,
  },
  manageMembers: {
    users: (communityId: string, tab?: 'users' | 'requests') =>
      `/communities/${communityId}/manage/users${tab ? '?tab=' + tab : ''}`,
    invite: (communityId: string, invitedAs?: CommunityInviteAs) =>
      `/communities/${communityId}/manage/invitations${invitedAs ? `?inviteAs=${invitedAs}` : ''}`,
    companies: (communityId: string, visibilityFilter?: CommunityVisibility) =>
      `/communities/${communityId}/manage${visibilityFilter ? '?visibility=' + visibilityFilter : ''}`,
    organizations: (communityId: string, visibilityFilter?: CommunityVisibility) =>
      `/communities/${communityId}/manage/organizations${visibilityFilter ? '?visibility=' + visibilityFilter : ''}`,
    viewCompany: (communityId: string, communityCompanyId: CompanyId) =>
      `/communities/${communityId}/manage/${communityCompanyId}`,
    viewOrganization: (communityId: string, organizationSlug: string) =>
      `/communities/${communityId}/manage/organizations/${organizationSlug}`,
  },
  discussion: {
    view: (communityId: string, communityCompanyId: CompanyId) => {
      return `${communityViewCompany(communityId, communityCompanyId)}`;
    },
  },
  innovasjonNorge: {
    listCompanies: (communityId: string) => `/communities/${communityId}/in/companies`,
    viewCompany: (communityId: string, companyId: CompanyId) => `/communities/${communityId}/in/companies/${companyId}`,
  },
  members: {
    overview: (communitySlug: string, category?: ExploreCommunityCategory, filters?: CompaniesListFilters) =>
      category
        ? `/communities/${communitySlug}/members/explore/${category}${companiesListFiltersToQueryString(filters)}`
        : `/communities/${communitySlug}/members`,

    companyPitch: (communitySlug: string, companyId: number) =>
      `/communities/${communitySlug}/members/explore/companies/pitch/${companyId}/profile`,
    user: (communitySlug: string, cwUserId: string) => `/communities/${communitySlug}/members/users/${cwUserId}`,
    organization: (communitySlug: string, organizationSlug: string) =>
      `/communities/${communitySlug}/members/organizations/${organizationSlug}`,
  },
  inbox: {
    showAsUser: (communitySlug: string) => `/communities/${communitySlug}/contact`,
    list: (communitySlug: string) => `/communities/${communitySlug}/inbox`,
    view: (communitySlug: string, cwUserId: string) => `/communities/${communitySlug}/inbox/${cwUserId}`,
  },
  groups: {
    board: (communitySlug: string, groupId?: UUID) =>
      `/communities/${communitySlug}/overview/board${groupId ? `/${groupId}` : ''}`,
  },
};

export const companyOverviewSections = [
  'updates',
  'profile',
  'documents',
  'kpis',
  'goals',
  'team',
  'captable',
  'users',
] as const;
export type CompanyOverviewSection = (typeof companyOverviewSections)[number];

export const companyUrls = {
  overview: (
    companySlug: string | CompanyId,
    section?: CompanyOverviewSection,
    options?: {
      isPostingUpdate?: boolean;
      isContactingCompany?: boolean;
      audience?: 'investors';
      expandOnboardingChecklist?: boolean;
      connectionsRoleFilter?: 'prospective_investor';
    },
  ) => {
    const params = new URLSearchParams();
    if (options?.isPostingUpdate) {
      params.append('writeUpdate', '1');
    }
    if (options?.isContactingCompany) {
      params.append('contact', '1');
    }
    if (options?.expandOnboardingChecklist) {
      params.append('showChecklist', '1');
    }
    if (options?.audience) {
      params.append('audience', options.audience);
    }
    if (options?.connectionsRoleFilter) {
      params.append('role', roleDisplayName(options.connectionsRoleFilter).toLowerCase());
    }
    const querySearch = params.toString();
    const sectionPath = section ? `/${section}` : '';
    return `/companies/${companySlug}${sectionPath}${querySearch ? `?${querySearch}` : ''}`;
  },
  documents: {
    overview: (companySlug: string) => `/companies/${companySlug}/documents`,
    download: (companySlug: string, documentId: DocumentDTO['id']) =>
      `/companies/${companySlug}/documents/download/${documentId}`,
  },
  kpi: {
    manage: (companySlug: string) => `/companies/${companySlug}/kpis/update`,
  },
  boardroom: {
    overview: (companyId: CompanyId) => `/companies/${companyId}/boardroom`,
    view: (companyId: CompanyId, updateId: string) => `/companies/${companyId}/boardroom/update/${updateId}`,
    documents: {
      baseUrl: (companyId: string) => `/companies/${companyId}/boardroom/folders`,
      folder: (companyId: string, folderId?: string) =>
        `/companies/${companyId}/boardroom/folders${folderId ? `/${folderId}` : ''}`,
      download: (companyId: string, documentId: DocumentDTO['id']) =>
        `/companies/${companyId}/boardroom/download/${documentId}`,
    },
  },
  update: {
    view: (companySlug: string, updateId: string) => `/companies/${companySlug}/updates/${updateId}`,
  },
  innovasjonNorge: {
    mentee: {
      overview: (companySlug: string, cohortId: number) => `/companies/${companySlug}/in/${cohortId}`,
    },
    mentor: {
      overview: (companySlug: string, cohortId: number) => `/companies/${companySlug}/in/${cohortId}/mentor`,
    },
  },
  settings: {
    settings: (companySlug: string, section: CompanySettingsSections = undefined) =>
      section ? `/companies/${companySlug}/settings?section=${section}` : `/companies/${companySlug}/settings`,
    users: (companySlug: string, tab?: 'users' | 'requests') =>
      `/companies/${companySlug}/settings/users${tab ? '?tab=' + tab : ''}`,
    fundingRound: (companySlug: string) => `/companies/${companySlug}/settings/funding-round`,
    communityMembership: (companySlug: string) => `/companies/${companySlug}/settings/community-membership`,
    tools: (companySlug: string, section?: CompanyToolsSection) =>
      `/companies/${companySlug}/settings/tools${section ? `?section=${section}` : ''}`,
    share: (companySlug: string, section?: CompanySharingSection) => {
      const path = `/companies/${companySlug}/settings/share`;
      if (typeof window !== 'undefined') {
        const url = new URL(path, location.origin);
        section && url.searchParams.set('section', section);
        return `${url.pathname}${url.search}`;
      } else {
        return path;
      }
    },
    inviteUser: (companySlug: string, role?: 'investor' | 'prospective_investor' | 'board' | 'company_master') =>
      role
        ? `/companies/${companySlug}/settings/users/invite?role=${role}`
        : `/companies/${companySlug}/settings/users/invite`,
  },
  insights: (companySlug: string) => `/companies/${companySlug}/insights`,
  rooms: {
    view: (companySlug: string, roomId: UUID) => `/companies/${companySlug}/rooms/${roomId}`,
    viewUpdate: (companySlug: string, roomId: UUID, updateId: UUID) =>
      `/companies/${companySlug}/rooms/${roomId}/update/${updateId}`,
  },
  goals: {
    new: (companySlug: string) => `/companies/${companySlug}/goals/create`,
    edit: (companySlug: string, goalId: string) => `/companies/${companySlug}/goals/edit/${goalId}`,
  },
  inbox: {
    showAsUser: (companySlug: string) => `/companies/${companySlug}/contact`,
    list: (companySlug: string) => `/companies/${companySlug}/inbox`,
    view: (companySlug: string, cwUserId: string) => `/companies/${companySlug}/inbox/${cwUserId}`,
  },

  embed: {
    companyProfile: (companySlug: string) => `/embed/companies/${companySlug}`,
    detailedCompanyProfile: (companySlug: string) => `/embed/companies/${companySlug}/detailed`,
  },
};

export const sharedUrls = (type: ProfileType) => {
  const base = type === 'community' ? 'communities' : 'companies';
  return {
    inbox: {
      show: (companyId: CompanyId, conversationId: string) => `/${base}/${companyId}/inbox/${conversationId}`,
    },
    boardroom: {
      overview: (companyId: CompanyId) => `/${base}/${companyId}/boardroom`,
      view: (companyId: CompanyId, updateId: string) => `/${base}/${companyId}/boardroom/update/${updateId}`,
      documents: {
        baseUrl: (companyId: string) => `/${base}/${companyId}/boardroom/folders`,
        folder: (companyId: string, folderId?: string) =>
          `/${base}/${companyId}/boardroom/folders${folderId ? `/${folderId}` : ''}`,
        download: (companyId: string, documentId: DocumentDTO['id']) =>
          `/${base}/${companyId}/boardroom/download/${documentId}`,
      },
    },
  };
};
