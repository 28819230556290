import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ReplyNotificationIcon(props: IconProps) {
  return (
    <SvgIcon viewBox="0 -1 9 12" {...props}>
      <path d="M7.94039 8.97293C7.82429 6.44843 7.92719 5.52993 6.28409 4.35193C5.47649 3.77293 3.85179 3.39143 1.65799 3.59743M3.39219 1.06543L1.09549 3.21903C0.901392 3.41323 0.899993 3.72873 1.09219 3.92473L3.39219 6.11273"></path>
    </SvgIcon>
  );
}
