import React from 'react';
import Dialog, { Title } from 'ui/views/dialogs/Dialog';
import Branding from './Branding';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  communitySlug: string;
}

export default function CommunityBrandingDialog({ isOpen, onClose, communitySlug }: Props) {
  const title = 'Upload logo';
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="md">
      <Title onClose={onClose}>{title}</Title>
      <Branding communitySlug={communitySlug} onComplete={onClose} />
    </Dialog>
  );
}
