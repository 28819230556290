import React from 'react';
import Label from 'ui/elements/form/Label';
import { communityKey } from 'apis/CompanyAPI/communities/useCommunity';
import useNotify from 'hooks/useNotify';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { invalidate } from 'hooks/useSWR';
import { communitySummaryKey } from 'apis/CompanyAPI/communities/useCommunitySummary';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { UploadLogo } from 'domain/shared/Branding/UploadLogo';
import styles from 'domain/shared/Branding/styles.scss';
import { TinyCommunity } from 'types/company/community';

interface Props {
  community: TinyCommunity;
  logoURL?: string;
  imageURL?: string;
  onLogoUploaded?: (logoURL: string) => void;
  onImageUploaded?: (imageURL: string) => void;
}

export default function BrandingForm({ community, logoURL, onLogoUploaded }: Props) {
  const communities = useCommunities();
  const notify = useNotify();

  return (
    <section>
      <Label>Logo</Label>
      <div className={styles.logo}>
        <UploadLogo
          logoURL={logoURL}
          companyId={community.id}
          notify={notify}
          onUploadSuccess={async (_, image) => {
            await communitiesApi.patch(community.slug, { logoURL: image.secure_url });
            onLogoUploaded && onLogoUploaded(image.secure_url);

            invalidate(communityKey(community.slug));
            invalidate(communitySummaryKey(community.slug));

            communities.mutate();
          }}
        />
      </div>
    </section>
  );
}
