import { CompanyConversationLinkAsUser } from 'domain/conversations/CompanyConversationAsUser';
import React from 'react';
import { ExecutiveSummaryDTO, CompanyProfile, VideoListDTO } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import Card from 'ui/views/cards/Card';
import { companyUrls } from 'urls';
import ImpactGoalsSection from './ImpactGoalsSection/ImpactGoalsSection';
import emptyIllustration from 'ui/illustrations/reporting_help.svg';
import { usePitch } from 'apis/CompanyAPI/companies/usePitch';
import PitchAndPresentation from 'domain/companies/profile/PitchAndPresentation';
import MarketPotentialSection, { hasMarketPotential } from './MarketPotentialSection/MarketPotentialSection';
import WhyInvestSection from './WhyInvestSection/WhyInvestSection';
import { useMarketPotential } from 'apis/CompanyAPI/companies/useMarketPotential';
import { getOrElse, getOrUndefined } from 'util/resource';
import ProblemSolutionSection from './ProblemSolutionSection/ProblemSolutionSection';
import usePitchDocuments from 'apis/DocumentAPI/usePitchDocuments';
import RevenueAndValuationSection from './RevenueAndValuationSection/RevenueAndValuationSection';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import { useImpactGoals } from 'apis/CompanyAPI/companies/useImpactGoals';
import { useExecutiveSummary } from 'apis/CompanyAPI/companies/useExecutiveSummary';
import { useVideos } from 'apis/CompanyAPI/companies/useVideos';
import Resources from 'util/resource/Resources';
import CompanyDetails from './Details';
import RestrictedCard from 'ui/views/cards/RestrictedCard';
import FollowButton from 'domain/companies/Follow/FollowButton';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { sectionSpacing } from 'ui/theme/themeConstants';

interface Props {
  companyProfile: CompanyProfile;
  viewAs: CompanyViewAs;
  reloadOnboarding?: () => void;
  hideRevenues?: boolean; // Temporary to hide it from community profile until we know where to place it
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${sectionSpacing};
`;

export default function CompanyOverviewProfileSection({
  companyProfile,
  viewAs,
  hideRevenues,
  reloadOnboarding,
}: Props) {
  const { resource: pitch, mutate: reloadPitch } = usePitch(companyProfile.id);
  const { resource: executiveSummary } = useExecutiveSummary(companyProfile.id);
  const { resource: impactGoals } = useImpactGoals(companyProfile.id);
  const { resource: marketPotential, mutate: reloadMarketPotential } = useMarketPotential(companyProfile.id);
  const { resource: pitchDocuments, mutate: setPitchDocuments } = usePitchDocuments(companyProfile.id);
  const { resource: videos, mutate: reloadVideos } = useVideos(companyProfile.id);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const hasAccessToCompany = viewAs !== 'Visitor';
  const canEditCompany = viewAs === 'Admin';

  const hasContent = (executiveSummary: ExecutiveSummaryDTO, videos: VideoListDTO) =>
    canEditCompany ||
    hasMarketPotential(getOrUndefined(marketPotential)) ||
    getOrElse(impactGoals, []).length > 0 ||
    executiveSummary.problem ||
    executiveSummary.solution ||
    executiveSummary.unfairAdvantage ||
    videos.values.length > 0 ||
    getOrUndefined(pitchDocuments)?.pitchDeck ||
    (getOrUndefined(pitchDocuments)?.attachments.length ?? 0) > 0;

  return (
    <Container>
      <CompanyDetails
        canEditCompany={viewAs === 'Admin'}
        companyProfile={companyProfile}
        onEdit={reloadOnboarding ? reloadOnboarding : () => {}}
      />
      <Resources resources={[pitch, videos, executiveSummary]}>
        {([pitch, videos, executiveSummary]) => (
          <>
            {viewAs === 'Visitor' || hasContent(executiveSummary, videos) ? (
              <>
                <PitchAndPresentation
                  company={companyProfile}
                  canEditCompany={canEditCompany}
                  videos={videos}
                  documents={pitchDocuments}
                  setDocuments={setPitchDocuments}
                  reloadVideos={() => reloadVideos}
                  viewAs={viewAs}
                />
                <ProblemSolutionSection
                  company={companyProfile}
                  executiveSummary={executiveSummary}
                  canEditCompany={canEditCompany}
                  onSave={reloadOnboarding}
                />
                <MarketPotentialSection
                  company={companyProfile}
                  marketPotentialResource={marketPotential}
                  reloadMarketPotential={() => reloadMarketPotential()}
                  canEditCompany={canEditCompany}
                />
                <WhyInvestSection
                  company={companyProfile}
                  executiveSummary={executiveSummary}
                  canEditCompany={canEditCompany}
                  onSave={reloadOnboarding}
                />
                <ImpactGoalsSection companyId={companyProfile.id} canEditCompany={viewAs === 'Admin'} />
                {!hideRevenues && (
                  <RevenueAndValuationSection company={pitch} reloadCompany={reloadPitch} viewAs={viewAs} />
                )}
                {!hasAccessToCompany && (
                  <RestrictedCard
                    title="Some parts of this profile are hidden"
                    action={
                      <FollowButton
                        style={{ whiteSpace: 'nowrap' }}
                        company={companyProfile}
                        size={isMobile ? 'small' : 'medium'}
                        trackingKey="community-pitch-investment-round-restricted"
                      />
                    }
                  >
                    Follow {companyProfile.name} to receive real-time updates on their progress and gain access to extra
                    information shared with you.
                  </RestrictedCard>
                )}
              </>
            ) : (
              <Card
                padding="double"
                className="text-body"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <div>
                  <img
                    className="u-content-spacing-bottom"
                    style={{ width: '100%', minWidth: 100, maxWidth: 175 }}
                    src={emptyIllustration}
                  />
                </div>
                <p className="u-content-spacing-bottom u-content-spacing-right text-align-center">
                  <span className="text-weight-medium">{companyProfile.name}</span> hasn&apos;t added any in-depth
                  details of their company yet. <br />
                  Help them get started by sending them a message with what you want to see about their company.
                </p>
                <CompanyConversationLinkAsUser
                  company={companyProfile}
                  href={companyUrls.inbox.showAsUser(companyProfile.slug)}
                  trackingKey="company-empty-profile-encouragement"
                >
                  <Button kind="primary">Send them a message!</Button>
                </CompanyConversationLinkAsUser>
              </Card>
            )}
          </>
        )}
      </Resources>
    </Container>
  );
}
