import React, { useState } from 'react';
import FullscreenDialog, { Content, Title } from 'ui/views/dialogs/FullscreenDialog';
import { UserProfile } from 'types/user';
import { DialogActions } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import UserList from 'domain/rooms/UserList';
import { ICompany } from 'types/company';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { useRouteMatch } from 'react-router';
import * as Yup from 'yup';

import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { Link } from 'react-router-dom';
import Resources from 'util/resource/Resources';
import { getOrUndefined } from 'util/resource';
import { companyUrls } from 'urls';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { TinyCommunity } from 'types/company/community';
import { getRelevantRoles } from 'domain/rooms/utils';

interface Props {
  onClose: () => void;
  community: TinyCommunity;
  company: ICompany;
  onAssign: (name: string, users: UUID[], admins: UUID[]) => void;
  isSaving: boolean;
}

export default function EditRoomDialog(props: Props) {
  const [admins, setAdmins] = useState<UserProfile[]>([]);
  const [users, setUsers] = useState<UserProfile[]>([]);

  const match = useRouteMatch<{ roomId?: string }>();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Please provide a name for the room'),
    }),
    onSubmit(values) {
      props.onAssign(
        values.name,
        users.map(u => u.cwUserId),
        admins.map(u => u.cwUserId),
      );
    },
  });

  const [roomResource] = useResourceLegacy(async () => {
    if (match.params.roomId) {
      const room = await communitiesApi.rooms.get(props.community.id, props.company.id, match.params.roomId);

      setAdmins(room.admins);
      setUsers(room.users);
      formik.setFieldValue('name', room.room.name);
      return room;
    } else {
      return Promise.resolve(undefined);
    }
  }, [match.params.roomId]);

  const { resource: userProfileResource } = useSelfUserProfile();

  const mode = match.params.roomId ? 'edit' : 'create';
  const title =
    mode === 'create'
      ? `Create room for ${props.company.name}`
      : `Edit ${getOrUndefined(roomResource)?.room.name ?? ''}`;

  return (
    <FullscreenDialog open={true} onClose={props.onClose}>
      <Title onClose={props.onClose}>{title}</Title>
      <Content>
        <Resources resources={[roomResource, userProfileResource]} renderError="Nothing">
          {([room, userProfile]) => {
            const allRoomUsers = room !== undefined ? [...room.admins, ...room.users] : [];
            const hasAccessToRoom = allRoomUsers.map(room => room.cwUserId).includes(userProfile.cwUserId);
            return (
              <>
                {mode === 'create' && (
                  <Grid container>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormikTextField name="name" label="Room name" formikProps={formik} />
                    </Grid>
                  </Grid>
                )}
                {mode === 'edit' && room && hasAccessToRoom && (
                  <div>
                    <Link to={companyUrls.rooms.view(props.company.slug, room.room.id)} className="text-link">
                      Go to room
                    </Link>
                  </div>
                )}
                <UserList
                  fetchUsers={(companySlug, filter) => communitiesApi.rooms.getUserList(companySlug, filter)}
                  mode={mode}
                  className="u-section-spacing-y"
                  companyName={props.company.name}
                  companySlug={props.community.slug}
                  users={admins}
                  setUsers={setAdmins}
                  role="admin"
                  relevantRoles={getRelevantRoles('community', props.community.id)}
                />
                <UserList
                  fetchUsers={(companySlug, filter) => communitiesApi.rooms.getUserList(companySlug, filter)}
                  mode={mode}
                  relevantRoles={getRelevantRoles('community', props.community.id)}
                  companyName={props.company.name}
                  companySlug={props.community.slug}
                  users={users}
                  setUsers={setUsers}
                  role="user"
                />
              </>
            );
          }}
        </Resources>
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={() => formik.handleSubmit()} isLoading={props.isSaving}>
          {mode === 'edit' ? 'Save' : 'Create room'}
        </Button>
        <Button kind="tertiary" onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </FullscreenDialog>
  );
}
