import React from 'react';
import { VisibleRole } from 'types/company/access';
import { MentorExpertise } from 'apis/MentorAPI/types';
import { UserProfile } from 'types/user';
import { communityRoles } from 'domain/communities/roleUtils';
import UserList from '../../users/UsersDialog/UserList';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { CommunityType, TinyCommunity } from 'types/company/community';
import { useCompanyUserIndustries } from 'apis/CompanyAPI/companies/useCompanyUserIndustries';
import { roleDisplayNamePlural } from './utils';

interface Props {
  defaultFilter?: VisibleRole;
  community: TinyCommunity;
  defaultMentorExpertise?: MentorExpertise[];
  renderUser?: (user: UserProfile) => JSX.Element;
}

const getRelevantRoles = (
  communityType: CommunityType,
  communityId: number,
): { value: string; label: string; isVisible: boolean }[] => [
  { value: 'all', label: 'All profiles', isVisible: true },
  ...communityRoles(communityType, communityId).map(role => ({
    value: role,
    label: roleDisplayNamePlural(role),
    isVisible: true,
  })),
];

export default function CompanyUserList(props: Props) {
  const { resource: industriesResource } = useCompanyUserIndustries(props.community.slug);
  const relevantRoles = getRelevantRoles(props.community.communityType, props.community.id);
  const enableMentorExpertiseFilter = relevantRoles.find(item => item.value === 'mentor');

  return (
    <UserList
      fetchUsers={filter => companiesApi.users.search(props.community.slug, filter)}
      filterOptions={{
        industries: industriesResource,
        role: {
          defaultValue: props.defaultFilter,
          values: relevantRoles,
        },
        mentorExpertise: enableMentorExpertiseFilter
          ? {
              enabled: true,
              defaultValue: props.defaultMentorExpertise,
            }
          : undefined,
      }}
      renderUser={props.renderUser}
    />
  );
}
