import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { ProgressHandler } from 'hooks/useProgressHandler';
import { fetchDiscussion } from '../actions';
import React, { useEffect, useState } from 'react';
import { TopicDTO, UnbornTopicDTO } from 'types/company/community';
import { CompanyId } from 'urls';
import i18n from 'util/i18n';
import { toUnbornTopicDTO } from 'util/typeTransformers';
import TextField from 'ui/elements/form/TextField';

interface Props {
  dialogHandler: DialogHandlerProps;
  onCreate: (unbornTopic: UnbornTopicDTO, progressHandler: ProgressHandler) => Promise<void>;
  onEdit: (topicId: TopicDTO['id'], newTopic: UnbornTopicDTO, progressHandler: ProgressHandler) => Promise<void>;
  companyId: number;
  companyName: string;
  topicBeingEdited?: TopicDTO;
  communityId: CompanyId;
}

export default function CreateDiscussionTopicDialog(props: Props) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [discussion, setDiscussion] = useState<TopicDTO>();

  useEffect(() => {
    (async () => {
      if (props.topicBeingEdited) {
        const data = await fetchDiscussion(props.communityId, props.companyId, props.topicBeingEdited.id);
        setDiscussion(data);
        setTitle(props.topicBeingEdited.title);
        setDescription(data.content.htmlContent || '');
      }
    })();
  }, [props.topicBeingEdited, props.companyId, props.communityId]);

  function resetForm() {
    setTitle('');
    setDescription('');
  }

  return (
    <ProgressConfirmDialog
      dialogHandler={props.dialogHandler}
      title={
        <div className="u-flex-align-center">
          {props.topicBeingEdited
            ? i18n('en').discussions.topic.edit(props.topicBeingEdited.title)
            : i18n('en').discussions.topic.create}{' '}
          {props.companyName}
        </div>
      }
      dismissLabel="Discard"
      confirmLabel={i18n('en').common.saveOrCreate.confirm(
        !!props.topicBeingEdited ? 'Save' : 'Create',
        'discussion topic',
      )}
      onConfirm={async handleProgress => {
        if (props.topicBeingEdited) {
          await props.onEdit(props.topicBeingEdited.id, toUnbornTopicDTO(title, description), handleProgress);
        } else {
          await props.onCreate(toUnbornTopicDTO(title, description), {
            ...handleProgress,
            onCleanup: result => {
              handleProgress.onCleanup(result);
              if (result === 'success') {
                resetForm();
              }
            },
          });
        }
      }}
      maxWidth="sm"
      disableConfirmButton={[title, description].filter(Boolean).length < 2}
      onDismiss={() => {
        setTitle((props.topicBeingEdited && props.topicBeingEdited.title) || '');
        setDescription((discussion && discussion.content.htmlContent) || '');
      }}
    >
      <div className="u-content-spacing-bottom">
        <TextField
          name="title"
          title="Title"
          placeholder="Title"
          label="Title"
          fullWidth
          onChange={e => setTitle(e.target.value)}
          value={title}
        />
      </div>
      <div>
        <TextField
          fullWidth
          minRows={3}
          maxRows={12}
          name="description"
          title="Description"
          placeholder="Description"
          label="Description"
          multiline
          onChange={e => setDescription(e.target.value)}
          value={description}
        />
      </div>
    </ProgressConfirmDialog>
  );
}
