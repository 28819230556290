import {
  CommonOnboardingStep,
  InvestorOnboardingStep,
  Onboarding,
  OnboardingPart,
  OnboardingStep,
  onboardingApiUrls,
} from 'apis/OnboardingAPI';
import React, { useEffect } from 'react';
import { SelfUserProfile } from 'types/user';
import Wizard, { OnboardingStage } from 'ui/modules/wizards/Wizard';
import { track } from 'util/analytics';
import UserInfoForm from '../user/UserInfo/UserInfoForm';
import InvestorPreferenceForm from './InvestorPreferences/InvestorPreferenceForm';
import InvestorTypeForm from './InvestorType/InvestorTypeForm';
import InvestmentRangeForm from './InvestorPreferences/InvestmentRangeForm';
import { useIndustries } from 'apis/CompanyAPI/labels/useIndustries';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { invalidate } from 'hooks/useSWR';
import { featuredMatchesKey } from 'apis/CompanyAPI/users/useFeaturedMatches';
import ChooseYourRole from './ChooseYourRoleForm';
import { CommunityInviteDetails } from 'types/company/community';
import { WizardType } from 'pages/Dashboard/Dashboard';
import InterestsForm from './InterestForm';
import BoardPosition from './BoardPosition';
import SebLegalForm from './SebLegalForm';
import CompanyMatchingForm from './CompanyMatching/CompanyMatchingForm';
import { setStepCompleted } from '../utils';

export const USER_WIZARD_CONTENT_MIN_HEIGHT = '225px';

export default function UserOnboardingWizard({
  invite,
  user,
  onboarding: o,
  onClose,
  setOnboarding,
  onWizardCompleted,
  onShowWizard,
}: {
  user: SelfUserProfile;
  onboarding: Onboarding;
  onClose: () => void;
  setOnboarding: (onboarding: Onboarding) => void;
  onWizardCompleted: () => void;
  onShowWizard: (wizard: WizardType) => void;
  invite?: CommunityInviteDetails;
}) {
  useEffect(() => {
    track('investor-onboarding-started');
  }, []);

  const onboarding = o.wizard;

  const handleClose = () => {
    onClose();
    invalidate(userDashboardKey);
    invalidate(onboardingApiUrls.getUserOnboarding);
    track('investor-onboarding-completed');
  };

  // Preload industries
  useIndustries();

  const canChooseRole = !invite || invite.invitedAs === 'NotSpecified';

  if (onboarding.currentStep === 'ChooseYourRole') {
    return (
      <Wizard
        completedStages={[]}
        stages={[
          {
            name: 'ChooseYourRole',
            title: canChooseRole ? 'Choose your role to get started' : 'Welcome',
            hideStageInformation: true,
            content: () => (
              <ChooseYourRole
                invite={invite}
                userProfile={user}
                onShowWizard={onShowWizard}
                onStart={() => track('investor-onboarding-role-step-started')}
                onComplete={() => {
                  invalidate(onboardingApiUrls.getUserOnboarding);
                  invalidate(featuredMatchesKey);
                  track('investor-onboarding-role-step-completed');
                }}
              />
            ),
          },
        ]}
      />
    );
  }

  const setOnboardingState = (part: OnboardingPart) => setOnboarding({ ...o, wizard: part });
  const setWizardStepCompleted = (step: OnboardingStep) => setOnboardingState(setStepCompleted(onboarding, step));

  const stages: OnboardingStage<InvestorOnboardingStep | CommonOnboardingStep>[] = [
    {
      name: 'InvestorType',
      title: 'What kind of investor do you identify as?',
      content: ({ onComplete }) => (
        <InvestorTypeForm
          userProfile={user}
          onStart={() => track('investor-onboarding-type-step-started')}
          closeWizard={handleClose}
          onComplete={isInvestor => {
            // We want to skip the InvestmentPreferences and InvestmentRange steps if the user is not an investor
            // Ideally we would just sync onboarding with backend, but with current wizard setup it causes awkward skips between stages
            if (isInvestor === false) {
              setOnboarding({
                ...o,
                wizard: {
                  ...o.wizard,
                  currentStep: 'UserProfile',
                  steps: o.wizard.steps.filter(s => s.step !== 'InvestmentPreferences' && s.step !== 'InvestmentRange'),
                },
              });
            } else {
              setWizardStepCompleted('InvestorType');
            }
            invalidate(featuredMatchesKey);
            onComplete();
            track('investor-onboarding-type-step-completed');
          }}
        />
      ),
    },
    {
      name: 'InvestmentPreferences',
      title: 'What is your industry and business focus?',
      subtitle: 'Add as many areas as you want, choose at least one category',
      content: ({ onComplete, onBack }) => (
        <InvestorPreferenceForm
          userProfile={user}
          onStart={() => track('investor-onboarding-investmentpreferences-step-started')}
          closeWizard={handleClose}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-investmentpreferences-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'InvestmentRange',
      title: 'Add your preferred range & capacity',
      content: ({ onComplete, onBack }) => (
        <InvestmentRangeForm
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-investmentpreferences-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-investmentrange-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'CompanyMatching',
      title: 'Follow a few companies to get started (optional)',
      content: ({ onComplete, onBack }) => (
        <CompanyMatchingForm
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-companymatching-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-companymatching-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'SebLegal',
      title: 'Legal',
      content: ({ onComplete, onBack }) => (
        <SebLegalForm
          userProfile={user}
          onStart={() => track('investor-onboarding-seb-legal-step-started')}
          onComplete={() => {
            setWizardStepCompleted('SebLegal');
            onComplete();
            track('investor-onboarding-seb-legal-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'Interests',
      title: 'What are your interests?',
      subtitle: 'Add as many areas as you want, choose at least one category',
      content: ({ onComplete, onBack }) => (
        <InterestsForm
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-interests-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-interests-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'BoardPosition',
      title: 'Board Position',
      subtitle: 'Are you open for requests regarding board positions?',
      content: ({ onComplete, onBack }) => (
        <BoardPosition
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-board-position-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-board-position-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },

    {
      name: 'UserProfile',
      title: 'Preview your profile card and add the missing information',
      content: ({ onComplete, onBack }) => (
        <UserInfoForm
          userProfile={user}
          context="onboarding"
          onStart={() => track('investor-onboarding-userprofile-step-started')}
          onComplete={() => {
            onComplete();
            onWizardCompleted();
            track('investor-onboarding-userprofile-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
  ];

  const relevantStages = stages.filter(stage => onboarding.steps.find(step => step.step === stage.name));
  const completedStages = onboarding.steps.filter(step => step.isCompleted).map(step => step.step);

  return <Wizard completedStages={completedStages} stages={relevantStages} />;
}
