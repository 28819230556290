import { UpdatesFilter } from 'actions/content/updates';
import { HasScope } from 'hooks/useCommunityRole';
import { ICompany } from 'types/company';

export const resolveCompanyName = (companyId: number, companies: ICompany[]) => {
  const companyName = companies.find(company => company.id === companyId)?.name || `company ${companyId}}`;
  return `By ${companyName}`;
};

export const getFilterName = (filter: UpdatesFilter, companies: ICompany[]) => {
  switch (filter.type) {
    case 'latest':
      return 'Latest';
    case 'archive':
      return 'Archived';
    case 'my updates':
      return resolveCompanyName(filter.companyId, companies);
  }
};

export const canPostCommunityUpdate = (hasScope: HasScope, roles: string[]) =>
  hasScope('Write', 'Update', 'Self') ||
  hasScope('Write', 'Update', 'Admin') ||
  roles.includes('company_admin_in_community');
