import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';
import Sidebar from './Sidebar';
import useCommunityRole from 'hooks/useCommunityRole';
import ProfileDialogContainer from 'domain/companies/ProfileDialog/ProfileDialogContainer';
import { TinyCommunity } from 'types/company/community';
import { AnalyticsContext } from 'hooks/usePitchViewLogger';

interface Props {
  community: TinyCommunity;
  analyticsContext: AnalyticsContext;
  basePath: string;
}

export default function CommunityCompanyProfileRoute(props: Props) {
  const match = useRouteMatch<{ companyId: string }>();
  const { hasScope } = useCommunityRole(props.community.slug);
  const { basePath } = props;

  return (
    <Route
      path={`${basePath}/pitch/:companyId`}
      render={routeProps => {
        const companyId = parseInt(routeProps.match.params.companyId, 10);
        return (
          <ProfileDialogContainer
            analyticsCommunityId={props.community.id}
            companyId={companyId}
            onClose={match.url}
            analyticsContext={props.analyticsContext}
            subRoutingIsEnabled
            renderSidebar={company => (
              <Sidebar
                community={props.community}
                companyId={companyId}
                companyProfile={company}
                discussionUrl={(discussionId: string) => `${routeProps.match.url}/discussions/${discussionId}`}
                hasScopeInCommunity={hasScope}
              />
            )}
          />
        );
      }}
    />
  );
}
