import React from 'react';
import { TinyCommunity } from 'types/company/community';
import { TinyOrganizationDTO } from 'types/organization';
import { EventDTO } from 'ui/domain/Event/types';
import Avatar from 'ui/elements/avatars/Avatar';
import SectionHeading from 'ui/elements/SectionHeading';
import ClickableCard from 'ui/views/cards/ClickableCard';
import { communityUrls, organizationUrls } from 'urls';

export default function PartnersAndHosts({
  event,
  community,
  organization,
}: {
  event: EventDTO;
  community?: TinyCommunity;
  organization?: TinyOrganizationDTO;
}) {
  return (
    <>
      <SectionHeading heading="Partners and hosts" />
      <div className="u-content-spacing-bottom">
        {community && (
          <ClickableCard
            key={community.id}
            title={<span>{community.name}</span>}
            padding="half"
            href={communityUrls.overview(community.slug)}
            avatar={<Avatar imageUrl={community.logoURL} userName={community.name} />}
          >
            <span className="text-metadata">Community</span>
          </ClickableCard>
        )}

        {organization && (
          <ClickableCard
            key={organization.id}
            title={<span>{organization.name}</span>}
            padding="half"
            href={organizationUrls.view(organization.slug)}
            avatar={<Avatar imageUrl={organization.logoURL} userName={organization.name} />}
          >
            <span className="text-metadata">Organization</span>
          </ClickableCard>
        )}

        {event.organizations &&
          event.organizations.map(org => (
            <ClickableCard
              key={org.id}
              title={<span>{org.name}</span>}
              padding="half"
              href={organizationUrls.view(org.slug)}
              avatar={<Avatar imageUrl={org.logoURL} userName={org.name} />}
            >
              <span className="text-metadata">Organization</span>
            </ClickableCard>
          ))}
      </div>
    </>
  );
}
