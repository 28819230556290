import React, { useState } from 'react';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import PDFDialog from 'domain/companies/Documents/Document/PitchDeck/PDFDialog';
import PitchDeckPDFPreview from './PitchDeckPDFPreview';
import PitchDeckImages from './PitchDeckImages';
import { PitchFile, PitchPreview } from 'types/company/pitchdeck';

interface Props {
  pitchDeckId: UUID;
  document: PitchFile;
  preview: PitchPreview;
  company: ICompany;
  viewAs: CompanyViewAs;
  pitchDeckTitle?: string;
}

function PitchDeckPreviewContainer(props: Props) {
  const { preview, pitchDeckId } = props;
  const [dialogPageNumber, setDialogPageNumber] = useState<number | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={containerRef}>
        <PitchDeckPDFPreview
          pitchDeckId={pitchDeckId}
          document={props.document}
          company={props.company}
          onThumbnailClick={clickedPage => {
            setDialogPageNumber(clickedPage);
          }}
          pitchDeckTitle={props.pitchDeckTitle}
          numPages={preview.imageUrls.length}
          imageUrls={preview.imageUrls}
          viewAs={props.viewAs}
        />
      </div>
      {dialogPageNumber && (
        <PDFDialog
          onClose={() => {
            setDialogPageNumber(null);
          }}
        >
          <PitchDeckImages
            pageNumber={dialogPageNumber}
            numPages={preview.imageUrls?.length ?? 0}
            imageUrls={preview.imageUrls}
            company={props.company}
            viewAs={props.viewAs}
          />
        </PDFDialog>
      )}
    </>
  );
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.company.id === nextProps.company.id && prevProps.viewAs === nextProps.viewAs;
};

export default React.memo(PitchDeckPreviewContainer, areEqual);
