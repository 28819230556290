import ApiBase from 'apis/ApiBase';
import config from 'config';
import { TinyEventUserInfo } from 'ui/domain/Event/types';

const baseUrl = `${config.COMPANIES_API_URL}/events`;
const api = ApiBase();

const EventAPI = {
  get: (id: UUID) => `${baseUrl}/${id}`,
  getPreview: (id: UUID) => `${baseUrl}/${id}/preview`,
  getApproved: () => `${baseUrl}/approved`,

  updateEventUserInfo: (id: UUID, payload: TinyEventUserInfo) =>
    api.put<TinyEventUserInfo>(`${baseUrl}/${id}/users/me`, payload),
};

export default EventAPI;
