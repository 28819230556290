import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'ui/elements/icons/CloseIcon';

import Resource from 'util/resource/Resource';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import React from 'react';
import { TopicDTO, TopicReplyDTO } from 'types/company/community';
import Byline from 'ui/domain/Byline';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import HtmlContent from 'ui/elements/HtmlContent';

interface Props {
  title: TopicDTO['title'];
  description: TopicReplyDTO;
  onClose: () => void;
}

export default function TopicDialogTitle(props: Props) {
  const { title, description, onClose } = props;

  const [userProfileResource] = useResourceLegacy(
    () => usersApi.userProfiles.get(description.creatorCwUserId),
    [description],
  );

  return (
    <DialogTitle>
      <div className="u-flex-align-center">
        <div className="u-flex-grow">
          <Resource resource={userProfileResource}>
            {creator => (
              <Byline
                className="u-content-spacing-bottom"
                author={{ type: 'user', imageUrl: creator.imageUrl ?? '', ...creator }}
                timestamp={description.createdAt}
              />
            )}
          </Resource>
          <h2 className="text-h3">{title}</h2>
        </div>
        <span className="u-flex--jSelf-end u-flex--aSelf-start">
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </span>
      </div>
      <HtmlContent>{description.content.htmlContent}</HtmlContent>
    </DialogTitle>
  );
}
