import useDialogHandler from 'hooks/useDialogHandler';
import React from 'react';
import { CommunityType, TinyCommunity } from 'types/company/community';
import { getOrUndefined } from 'util/resource';
import { UserProfile } from 'types/user';
import EditAction from 'ui/elements/actions/EditAction';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import DealLeadsDialog from './DealLeadsDialog';
import User from 'domain/users/Common/User';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { HasScope } from 'hooks/useCommunityRole';

interface Props {
  community: TinyCommunity;
  companyId: number;
  dealLeads: UserProfile[];
  refresh: () => void;
  communityType?: CommunityType;
  hasScopeInCommunity: HasScope;
}

export default function DealLeads(props: Props) {
  const dialogHandler = useDialogHandler(false);
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const isDealLead = props.dealLeads.some(dl => dl.cwUserId === userProfile?.cwUserId);

  const canManageDealLeads = isDealLead || props.hasScopeInCommunity('Write', 'Community');

  return (
    <>
      <SectionHeading heading="Deal lead" addMobileMargin>
        {canManageDealLeads && (
          <button onClick={dialogHandler.open}>
            <EditAction tooltip="Manage deal leads" />
          </button>
        )}
      </SectionHeading>
      <Card>
        {dialogHandler.isOpen && (
          <DealLeadsDialog
            dealLeads={props.dealLeads}
            refresh={props.refresh}
            dialogHandler={dialogHandler}
            community={props.community}
            companyId={props.companyId}
          />
        )}

        {props.dealLeads.map(lead => (
          <User key={lead.cwUserId} className="u-half-spacing-bottom" user={lead} />
        ))}
        {props.dealLeads.length === 0 && 'No deal lead assigned'}
      </Card>
    </>
  );
}
