import differenceInDays from 'date-fns/differenceInDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import endOfDay from 'date-fns/endOfDay';
import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import getYear from 'date-fns/getYear';
import intervalToDuration from 'date-fns/intervalToDuration';
import startOfDay from 'date-fns/startOfDay';

import { pluralize } from './stringUtils';
import { addDays, parseISO } from 'date-fns';

type DateInput = Date | string | number | null;

export const countriesUsing12HourFormat = [
  'Egypt',
  'Bangladesh',
  'India',
  'Jordan',
  'Pakistan',
  'Philippines',
  'Malaysia',
  'Saudi Arabia',
  'El Salvador',
  'Honduras',
  'Nicaragua',
  'Ireland',
  'Canada',
  'Mexico',
  'United States',
  'Australia',
  'New Zealand',
  'Colombia',
];

const formatDate = (date: DateInput, dateFormat: string) => {
  if (date === null) return 'Invalid date';
  return format(new Date(date), dateFormat);
};
export const dateMonthYear = (date: DateInput) => formatDate(date, 'dd.MM.yy');
export const dateWithTimestamp = (date: DateInput) => formatDate(date, 'HH:mm - dd MM yyyy');
export const dateWithDayName = (date: DateInput) => formatDate(date, 'EE dd MMM yyyy');
export const dateWithMonthName12HourFormat = (date: DateInput) => formatDate(date, 'MMMM do yyyy h.mm a');
export const dateWithMonthName24HourFormat = (date: DateInput) => formatDate(date, 'MMMM do yyyy HH.mm');
export const norwegianDateWithTimestamp = (date: DateInput) => formatDate(date, 'dd.MM.yy HH:mm');
export const asShortDate = (date: DateInput) => formatDate(date, 'dd MMM yy');
export const asShortDateWithoutYear = (date: DateInput) => formatDate(date, 'dd MMM');

export const yearMonthDate = (date: DateInput) => formatDate(date, 'yyyy.MM.dd');
export const asDayMonthNameYear = (date: DateInput) => formatDate(date, 'd MMMM yyyy');
export const asDayMonthName = (date: DateInput) => formatDate(date, 'd MMMM');
export const asMonthNameYear = (date: DateInput) => formatDate(date, 'MMMM yyyy');
export const asTime = (date: DateInput) => formatDate(date, 'HH:mm');
export const currentYear = () => format(new Date(), 'yyyy');
export const iso8601DateString = (date: Date) => format(date, 'yyyy-MM-dd');

// This is handy because the datepicker uses only the date part but includes the time zone, which has a tendency to skew dates 🫠
export const asIsoDateOnly = (date?: Date | null) => (date ? new Date(iso8601DateString(date)).toISOString() : date);
export const asUnixMillis = (t: Date) => getUnixTime(t) * 1000;

export const formatEventDate = (from: Date, to?: Date, location?: string): string => {
  const uses12HourFormat = location && countriesUsing12HourFormat.includes(location);

  const fromDateFormatted = uses12HourFormat
    ? dateWithMonthName12HourFormat(from)
    : dateWithMonthName24HourFormat(from);
  if (!to) return fromDateFormatted;

  const toDateFormatted = uses12HourFormat ? dateWithMonthName12HourFormat(to) : dateWithMonthName24HourFormat(to);

  const sameDay = from.toDateString() === to.toDateString();
  return sameDay
    ? `${fromDateFormatted} - ${format(to, uses12HourFormat ? 'h.mm a' : 'HH.mm')}`
    : `${fromDateFormatted} - ${toDateFormatted}`;
};

export function getDateRange(endDate: Date, days: number) {
  return [startOfDay(addDays(endDate, -(days - 1))), endOfDay(endDate)];
}

export const getDifferenceInDays = (start: Date, end: Date) => {
  const endOfStart = startOfDay(start);
  const endOfEnd = endOfDay(end);
  return intervalToDuration({ start: endOfStart, end: endOfEnd }).days || 0;
};

export const yearsMonthsDaysSince = (date: DateString, now?: DateString) => {
  const today = startOfDay(now ? new Date(now) : new Date());
  const start = startOfDay(new Date(date));

  const duration = intervalToDuration({ start: start, end: today });

  const dateTexts = [];
  if (duration.years) {
    dateTexts.push(`${duration.years} ${pluralize(duration.years, 'year', 'years')}`);
  }
  if (duration.months) {
    dateTexts.push(`${duration.months} ${pluralize(duration.months, 'month', 'months')}`);
  }
  if (duration.days) {
    dateTexts.push(`${duration.days} ${pluralize(duration.days, 'day', 'days')}`);
  }

  // We only pick the largest two blocks of time for display, so that it is easy to read
  // Meaning that if the time since is 2 years, 3 months and 4 days, we only show "2 years and 3 months"
  return dateTexts.slice(0, 2).join(' and ');
};

export const minimumDaysForDateDisplay = 32;

export function daysAgo(isoDate: string | null): string {
  if (!isoDate) {
    return 'Never';
  }
  return `${differenceInDays(new Date(), parseISO(isoDate))} days ago`;
}

export function timeSince(date: Date | string) {
  if (!date) return '';

  const startDate = new Date(date);

  if (isNaN(startDate.getTime())) return '';

  const now = new Date();
  const days = differenceInDays(startOfDay(now), startOfDay(startDate));
  const hours = differenceInHours(now, startDate);
  const minutes = differenceInMinutes(now, startDate);
  const seconds = differenceInSeconds(now, startDate);

  const postfix = ' ago';

  if (seconds < 60) return 'Just now';
  if (minutes < 2) return minutes + ' minute' + postfix;
  if (minutes < 60) return minutes + ' minutes' + postfix;
  if (hours < 2) return hours + ' hour' + postfix;
  if (hours < 24) return hours + ' hours' + postfix;
  if (days < 2) return 'Yesterday';
  if (days < minimumDaysForDateDisplay) return days + ' days' + postfix;
  if (getYear(now) === getYear(startDate)) return asDayMonthName(date);
  return asDayMonthNameYear(date);
}

export function sortDates(a: DateString, b: DateString): number {
  return new Date(a).getTime() - new Date(b).getTime();
}
