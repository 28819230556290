import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Resource from 'util/resource/Resource';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from '../UserOnboardingWizard';
import useResource from 'util/resource/useResource';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { CompanyInvestorOnboardingDTO } from 'types/company';
import CompanyMatchingCard from 'domain/onboarding/investor/CompanyMatching/CompanyMatchingCard';
import { contentSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { SelfUserProfile } from 'types/user';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

interface OwnProps {
  userProfile: SelfUserProfile;
  onComplete: () => void;
  closeWizard?: () => void;
  onStart?: () => void;
  onBack?: () => void;
}

export default function CompanyMatchingForm({ userProfile, onStart, onBack, closeWizard, onComplete }: OwnProps) {
  const [followedCompanies, setFollowedCompanies] = useState<number[]>([]);

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const { resource: companiesResource } = useResource<CompanyInvestorOnboardingDTO[]>(
    companiesAPIUrls.getPublicProfilesSuggestedForOnboarding(),
  );

  const handleFollowedCompanies = (companyId: number) => {
    setFollowedCompanies(curr =>
      curr.includes(companyId) ? curr.filter(id => id !== companyId) : [...curr, companyId],
    );
  };

  return (
    <Resource resource={companiesResource}>
      {companies => (
        <>
          <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
            <Grid>
              {companies.map(company => (
                <CompanyMatchingCard
                  key={company.details.id}
                  company={company}
                  industryInvestmentPreferences={userProfile.industryInvestmentPreferences}
                  isFollowed={followedCompanies.includes(company.details.id)}
                  setIsFollowed={() => handleFollowedCompanies(company.details.id)}
                />
              ))}
            </Grid>
          </Content>
          <DialogActions align="space-between">
            <ButtonList>
              <Button onClick={onComplete} kind="primary">
                {closeWizard ? 'Next' : 'Update'}
              </Button>
              {onBack && (
                <Button kind="primary" onClick={onBack} color="greyLight">
                  Back
                </Button>
              )}
            </ButtonList>
            {closeWizard && (
              <Button onClick={closeWizard} kind="tertiary" color="grey">
                Pick it up later
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Resource>
  );
}
