import useSWR from 'hooks/useSWR';
import { LabelDTO } from 'types/company';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communitiesApi, communityAPIUrls } from './communitiesApi';
import { List } from 'types/api';
import useResource from 'util/resource/useResource';

export const communityLabelsKey = (communitySlug: string, onlySelfServiceable = false) =>
  communityAPIUrls.labels.list(communitySlug, onlySelfServiceable);

export const useCommunityLabels = (communitySlug: string, onlySelfServiceable = false) => {
  const response = useSWR<List<LabelDTO>>(communityLabelsKey(communitySlug, onlySelfServiceable));
  return useSWRResource(response);
};

export const useCommunityCompanyLabels = (canSeeLabels: boolean, communityId: number, companyId: number) =>
  useResource<List<LabelDTO>>(
    canSeeLabels ? communitiesApi.communityMemberLabels.get(communityId, companyId) : undefined,
  );
