import React, { useState } from 'react';
import { DocumentDownloadDTO } from 'types/documents';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { SUPPORTED_DOCUMENTS_PREVIEW, SUPPORTED_IMAGES_PREVIEW } from 'util/constants';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import ImageDialog from 'domain/documents/ImageDialog';
import PDFDialog from 'domain/companies/Documents/Document/PitchDeck/PDFDialog';
import { Document, pdfjs } from 'react-pdf';
import Loading from 'ui/elements/Loading';
import PitchDeckPDF from 'domain/companies/Documents/Document/PitchDeck/PitchDeckPDF';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

export default function useDocumentPreviewOrDownload({
  filename,
  downloadDocumentPreview,
  downloadBlob,
}: {
  filename: string;
  downloadDocumentPreview: () => Promise<DocumentDownloadDTO>;
  downloadBlob?: () => any;
}) {
  const [documentDownloadDTO, setDocumentDownloadDTO] = useState<DocumentDownloadDTO | null>(null);

  const isPdf = SUPPORTED_DOCUMENTS_PREVIEW.some(type => filename.endsWith(type));
  const isImage = SUPPORTED_IMAGES_PREVIEW.some(type => filename.endsWith(type));

  const notify = useNotify();
  const [loadResource] = useLazyResource(downloadDocumentPreview, {
    onSuccess: result => {
      setDocumentDownloadDTO(result);
    },
    onFailure: () => notify('error', 'Something went wrong when trying to fetch document'),
  });

  const { onClick: onDownloadClick, WarningDialog } = useDownloadWarning(filename, downloadBlob);

  const showPreviewOnClick = isPdf || isImage;
  const showDownloadOnClick = !showPreviewOnClick && onDownloadClick && downloadBlob;

  const onClick = showPreviewOnClick ? () => loadResource('') : showDownloadOnClick ? onDownloadClick : undefined;

  const [numPages, setNumPages] = useState(1);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const dialog = (
    <>
      {documentDownloadDTO !== null && isPdf && (
        <Document
          className="u-flex u-flex--column u-flex--gap-2 u-section-spacing-bottom"
          file={documentDownloadDTO.path}
          onLoadSuccess={onDocumentLoadSuccess}
          externalLinkTarget="_blank"
          loading={<Loading className="u-section-spacing-y" position="center" />}
          noData=""
        >
          <PDFDialog onClose={() => setDocumentDownloadDTO(null)}>
            <PitchDeckPDF pageNumber={0} numPages={numPages} />
          </PDFDialog>
        </Document>
      )}
      {documentDownloadDTO !== null && isImage && (
        <ImageDialog imagePath={documentDownloadDTO.path} onClose={() => setDocumentDownloadDTO(null)} />
      )}
      {WarningDialog}
    </>
  );

  return {
    onClick,
    dialog,
  };
}
