import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Card from 'ui/views/cards/Card';
import Button from 'ui/elements/buttons/Button';
import styles from './styles.scss';
import { InvestorProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import { formatCurrencyRange } from 'util/currency';
import useDialogHandler from 'hooks/useDialogHandler';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';

export default function InvestorTile(props: {
  className?: string;
  viewInvestorHref: string;
  investor: InvestorProfile;
}) {
  const { investor } = props;

  const dialogHandler = useDialogHandler();

  return (
    <>
      <Card elevation={1} className={cx(styles.card, props.className)} hover="drop-shadow">
        <Link to={props.viewInvestorHref}>
          <div className={styles.greyBox} />
          <div className={styles.avatarContainer}>
            <Avatar className={styles.avatar} imageUrl={investor.imageUrl} size={105} />
          </div>
          <div className={styles.body}>
            <div className="u-flex u-flex--column u-flex-align-center" style={{ textAlign: 'center' }}>
              <h3 className="text-h4 u-half-spacing-top">{investor.name}</h3>
              {investor.position && <span className="text-metadata u-quarter-spacing-top">{investor.position}</span>}
              {investor.employer && <span className="text-metadata u-quarter-spacing-top">{investor.employer}</span>}
              {investor.preferredInvestmentSize?.rangeFrom || investor.preferredInvestmentSize?.rangeTo ? (
                <HorizontalDictionary align="center" className="u-content-spacing-top">
                  {[
                    {
                      key: 'Typical investments',
                      value: (
                        <>
                          {formatCurrencyRange(
                            investor.preferredInvestmentSize.currency,
                            investor.preferredInvestmentSize.rangeFrom,
                            investor.preferredInvestmentSize.rangeTo,
                          )}
                        </>
                      ),
                    },
                  ]}
                </HorizontalDictionary>
              ) : null}
            </div>
            <span className="text-link-small u-content-spacing-bottom">View profile</span>
          </div>
        </Link>
        <div className="u-flex-center">
          <Button kind="primary" onClick={dialogHandler.open}>
            Connect
          </Button>
        </div>
      </Card>
      {dialogHandler.isOpen && (
        <UserConversationDialog
          conversation={{ type: 'withUsers', withCwUserIds: [investor.cwUserId] }}
          closeConversation={dialogHandler.close}
        />
      )}
    </>
  );
}
