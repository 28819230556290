import TextField from '@mui/material/TextField';
import EditIcon from 'ui/elements/icons/EditIcon';
import { notify } from 'actions/snackbar';
import { getUserProfiles } from 'actions/userProfiles';
import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import { ProgressHandler } from 'hooks/useProgressHandler';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from 'globalstate/rootStore';
import { ReplyContentDTO, TopicReplyDTO } from 'types/company/community';
import { UserProfile } from 'types/user';
import i18n from 'util/i18n';
import { toReplyContentDTO } from 'util/typeTransformers';
import TopicReply from './TopicReply';

interface OwnProps {
  replies: TopicReplyDTO[];
  onSave: (replyId: TopicReplyDTO['id'], replyEdits: ReplyContentDTO, progressHandler: ProgressHandler) => void;
  notify: typeof notify;
}

interface StatefulProps {
  userProfiles: UserProfile[];
}

interface DispatchProps {
  getUserProfiles: typeof getUserProfiles;
}

function TopicReplies(props: StatefulProps & OwnProps & DispatchProps) {
  const { replies, onSave, notify, getUserProfiles } = props;

  const dialogHandler = useDialogHandler(false);
  const [editing, setEditing] = useState<TopicReplyDTO>();
  const [replyEdits, setReplyEdits] = useState<ReplyContentDTO>();

  useEffect(() => {
    getUserProfiles(replies.map(r => r.creatorCwUserId));
  }, [replies]);

  useEffect(() => {
    if (editing) {
      setReplyEdits(toReplyContentDTO(editing && editing.content.htmlContent));
    }
  }, [editing]);

  return (
    <>
      {props.userProfiles && (
        <>
          {replies.map(reply => (
            <TopicReply
              reply={reply}
              key={reply.id}
              creator={props.userProfiles.find(r => r.cwUserId === reply.creatorCwUserId)}
              openEditDialog={reply => {
                setEditing(reply);
                dialogHandler.open();
              }}
            />
          ))}
        </>
      )}
      {editing && (
        <ProgressConfirmDialog
          dialogHandler={dialogHandler}
          title={
            <div className="u-flex-align-center">
              <EditIcon className="u-quarter-spacing-right" /> Edit reply
            </div>
          }
          dismissLabel="Discard"
          confirmLabel="Save reply"
          onConfirm={async progressHandler => {
            if (
              replyEdits &&
              [replyEdits.htmlContent.trim(), replyEdits.structuredContent.trim()].filter(Boolean).length > 0
            ) {
              onSave(editing.id, replyEdits, progressHandler);
            } else {
              notify('error', i18n('en').validation.notEmpty('Reply content', 'when editing'));
            }
          }}
          maxWidth="sm"
          onDismiss={() => setReplyEdits(toReplyContentDTO(editing.content.htmlContent))}
          disableConfirmButton={replyEdits && replyEdits.htmlContent.trim().length === 0}
        >
          <div className="u-content-spacing-y">
            <TextField
              name="reply"
              title="Reply"
              placeholder="Reply"
              label="Reply"
              fullWidth
              onChange={e => setReplyEdits(toReplyContentDTO(e.target.value))}
              value={replyEdits && replyEdits.htmlContent}
              minRows={3}
              maxRows={9}
            />
          </div>
        </ProgressConfirmDialog>
      )}
    </>
  );
}

function mapStateToProps(state: RootStore) {
  return {
    userProfiles: state.userProfiles.items,
  };
}

export default connect<StatefulProps, DispatchProps, OwnProps>(mapStateToProps, {
  getUserProfiles: getUserProfiles,
})(TopicReplies);
