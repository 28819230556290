import FormHelperText from '@mui/material/FormHelperText';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import { FormikProps, withFormik } from 'formik';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import { TrustLevel } from 'types/company/company';
import TrustLevelPicker from './TrustLevelPicker';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { notEmpty, toggleItemInList } from 'util/arrayUtils';
import { getDerivedTrustLevels } from 'util/trustLevelUtils';
import * as yup from 'yup';
import { Info } from './Info';

interface Props {
  dialogHandler: DialogHandlerProps;
  onSubmit: (folderName: string, trustLevels: TrustLevel[]) => void;
  parentAccesses?: { trustLevel: TrustLevel }[];
  existingAccesses?: { trustLevel: TrustLevel }[];
  trustLevels: TrustLevel[];
  folderName?: string;
  loading: boolean;
  type: 'create' | 'edit';
  companyId: number;
  isCommunity: boolean;
}

interface FormValues {
  folderName: string;
  trustlevels: TrustLevel[];
}

function CreateFolderDialog(props: Props & FormikProps<FormValues>) {
  const selectableTrustLevels = props.trustLevels.filter(a => a !== 'board');
  return (
    <ConfirmDialog
      title={props.type === 'create' ? 'Create folder' : 'Edit folder'}
      isOpen={props.dialogHandler.isOpen}
      onClose={props.dialogHandler.close}
      maxWidth="sm"
      dismissLabel="Cancel"
      confirmButton={
        <Button kind="primary" onClick={() => props.handleSubmit()} isLoading={props.loading}>
          {props.type === 'create' ? 'Create folder' : 'Save'}
        </Button>
      }
    >
      <div className="u-half-spacing-top">
        <FormikTextField
          formikProps={props}
          autoFocus
          name="folderName"
          placeholder="Folder name"
          label="Name"
          fullWidth
        />
      </div>
      {selectableTrustLevels.length > 1 && (
        <TrustLevelPicker
          isCommunity={props.isCommunity}
          companyId={props.companyId}
          trustLevels={selectableTrustLevels}
          selectedTrustLevels={getDerivedTrustLevels(props.values.trustlevels)
            .concat(props.parentAccesses?.map(a => a.trustLevel) ?? [])
            .concat(props.values.trustlevels)}
          disabledTrustLevels={getDerivedTrustLevels(props.values.trustlevels).concat(
            props.parentAccesses?.map(a => a.trustLevel) ?? [],
          )}
          onChange={trustLevel =>
            props.setFieldValue('trustlevels', toggleItemInList(props.values.trustlevels, trustLevel))
          }
        ></TrustLevelPicker>
      )}

      {props.errors.trustlevels && props.touched.trustlevels && (
        <FormHelperText error>{props.errors.trustlevels}</FormHelperText>
      )}
      {selectableTrustLevels.length > 1 && <Info />}
    </ConfirmDialog>
  );
}

export default withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    folderName: props.folderName || '',
    trustlevels:
      props.existingAccesses !== undefined
        ? props.existingAccesses.map(ac => ac.trustLevel)
        : props.trustLevels
            .map(trustLevel => trustLevel)
            .filter(t => t === 'trusted')
            .filter(notEmpty),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values.folderName, values.trustlevels);
  },
  validateOnBlur: false,
  validationSchema: yup.object().shape({
    folderName: yup
      .string()
      .trim()
      .required('Folder name cannot be empty')
      .matches(
        /^[a-z0-9 \-_åøæ]{1,255}$/i,
        'Folder names can only contain alphanumerical characters, - (dashes) and _ (underscores)',
      ),
    trustlevels: yup.array().min(1, 'The folder must have at least one audience'),
  }),
})(CreateFolderDialog);
