import { notify as notifyA } from 'actions/snackbar';
import { ContentAPI } from 'apis/ContentAPI';
import { requestWrapper, requestWrapper2 } from 'apis/requestWrapper';
import config from 'config';
import { ProgressHandler } from 'hooks/useProgressHandler';
import { CompanyId } from 'urls';
import i18n from 'util/i18n';

import { PaginationOptions } from 'types/api';
import { ReplyContentDTO, TopicDTO, TopicReplyDTO, UnbornTopicDTO } from 'types/company/community';

const apiWrapper = ContentAPI(config.CONTENT_API_URL);

export const fetchDiscussion = (communityId: CompanyId, communityCompanyId: CompanyId, discussionId: string) =>
  apiWrapper.community.discussions.single(communityId, communityCompanyId, discussionId);

export const fetchReplies = (
  communityId: CompanyId,
  communityCompanyId: CompanyId,
  discussionId: string,
  paginationOptions?: PaginationOptions,
) => apiWrapper.community.discussions.replies(communityId, communityCompanyId, discussionId, paginationOptions);

export const createReply = (
  notify: typeof notifyA,
  communityId: CompanyId,
  communityCompanyId: CompanyId,
  discussionId: TopicDTO['id'],
  onCompleted: (bornReply: TopicReplyDTO) => void,
  afterCompleted: () => void,
  reply: ReplyContentDTO,
  progressHandler: ProgressHandler,
) =>
  requestWrapper(
    async () => {
      const bornReply = await apiWrapper.community.discussions.reply(
        communityId,
        communityCompanyId,
        discussionId,
        reply,
      );
      onCompleted(bornReply);
    },
    progressHandler.onProgress,
    error => notify('error', error || i18n('en').errors.create('reply')),
    result => {
      progressHandler.onCleanup(result);
      afterCompleted();
    },
  );

export const saveReply = (
  notify: typeof notifyA,
  communityId: CompanyId,
  communityCompanyId: CompanyId,
  discussionId: TopicDTO['id'],
  onCompleted: (reply: TopicReplyDTO) => void,
  replyId: TopicReplyDTO['id'],
  replyEdits: ReplyContentDTO,
  progressHandler: ProgressHandler,
) =>
  requestWrapper(
    async () => {
      const editedReply = await apiWrapper.community.discussions.editReply(
        communityId,
        communityCompanyId,
        discussionId,
        replyId,
        replyEdits,
      );
      notify('success', 'Reply has been updated'), onCompleted(editedReply);
    },
    progressHandler.onProgress,
    error => notify('error', error || i18n('en').errors.save('reply')),
    progressHandler.onCleanup,
  );

export const editDiscussionTopic = (
  notify: typeof notifyA,
  communityId: CompanyId,
  communityCompanyId: CompanyId,
  onCompleted: (topic: TopicDTO) => void,
  topicId: TopicDTO['id'],
  newTopic: UnbornTopicDTO,
  progressHandler: ProgressHandler,
) => {
  return requestWrapper2(
    async () => {
      const topic = await apiWrapper.community.discussions.editTopic(
        communityId,
        communityCompanyId,
        topicId,
        newTopic.title,
        newTopic.description,
      );
      onCompleted(topic);
      return topic;
    },
    error => notify('error', error || i18n('en').errors.save('topic')),
    progressHandler,
  );
};

export const fetchCommunityDiscussions = (
  communityId: CompanyId,
  communityCompanyId: CompanyId,
  paginationOptions?: PaginationOptions,
) => apiWrapper.community.discussions.get(communityId, communityCompanyId, paginationOptions);

export const createDiscussionTopic = (
  notify: typeof notifyA,
  communityId: CompanyId,
  communityCompanyId: CompanyId,
  onCompleted: (topic: TopicDTO) => void,
  unbornTopic: UnbornTopicDTO,
  progressHandler: ProgressHandler,
) => {
  return requestWrapper2(
    async () => {
      const topic = await apiWrapper.community.discussions.createTopic(
        communityId,
        communityCompanyId,
        unbornTopic.title,
        unbornTopic.description,
      );
      onCompleted(topic);
      return topic;
    },
    error => notify('error', error || i18n('en').errors.create('topic')),
    progressHandler,
  );
};
