import { useEffect } from 'react';
import i18n from 'util/i18n';

export default function usePrompt(dirty: boolean, isSubmitting: boolean) {
  function promptLeave(event: BeforeUnloadEvent) {
    if (dirty && !isSubmitting) {
      event.returnValue = i18n('en').common.unsavedChangesPrompt;
    } else {
      delete event['returnValue'];
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', promptLeave);
    return () => {
      window.removeEventListener('beforeunload', promptLeave);
    };
  }, [dirty, isSubmitting]);
  const onConfirmClose = (callback: () => void) => () => {
    if (dirty && !isSubmitting) {
      const confirmed = confirm(i18n('en').common.unsavedChangesPrompt);
      if (confirmed) {
        callback();
      }
    } else {
      callback();
    }
  };

  return { onConfirmClose };
}
