import React, { lazy, startTransition, Suspense, useEffect, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AuthenticatedRoutes, { LoadingScreen } from './auth/AuthenticatedRoutes';
import ErrorBoundary from './util/components/ErrorBoundary';
import { createRedirectUri } from 'auth/util';
import { updateRoute } from 'util/intercom/intercom';
import { useDispatch } from 'react-redux';
import { LocationChangeAction, LOCATION_CHANGED_ACTION } from 'middlewares/companyChangedMiddleware';
import useJourneyLogger from 'hooks/useJourneyLogger';
import RightHandSidebar from 'domain/shared/Navigation/Desktop/RightHandSidebar';
import InitialLoadWrapper from 'pages/Onboarding/InitialLoadWrapper';
import { trackPageView } from 'util/analytics';
import useLocalStorage from 'hooks/useLocalStorage';
import { v4 } from 'uuid';
import useAccessToken from 'auth/useAccessToken';
import { useDebouncedCallback } from 'use-debounce';
import { ANONYMOUS_USER_ID_KEY } from 'hooks/usePitchViewLogger';
import { useContextualNavigation } from 'domain/shared/Navigation';
import { useEnterprises } from 'apis/CompanyAPI/enterprisesApi';
import { getOrElse } from 'util/resource';
import { useLogout } from 'auth/useLogout';
import GroupIcon from 'ui/elements/icons/GroupIcon';

const BackofficeRoute = lazy(() => import('pages/Backoffice/shared/BackofficeRoute'));
const Auth0Callback = lazy(() => import('./auth/Auth0Callback'));
const ErrorPage = lazy(() => import('./pages/common/error/ErrorPage'));
const NotFound = lazy(() => import('./pages/common/error/NotFoundPage'));
const RedirectFromContent = lazy(() => import('./pages/RedirectFromContent'));
const RedirectFromDynamicLink = lazy(() => import('./pages/RedirectFromDynamicLink'));
const UserRegistration = lazy(() => import('./pages/Registration/User/UserRegistration'));
const LoadingBar = lazy(() => import('ui/elements/Loading/LoadingBar'));
const CompleteRegistration = lazy(() => import('pages/Registration/common/CompleteRegistration'));
const UserProfile = lazy(() => import('pages/UserProfile'));
const Profile = lazy(() => import('pages/profile/Show'));
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));

const CompanyRoutes = lazy(() => import('./pages/CompanyRoutes'));
const ShortlistRoutes = lazy(() => import('./pages/Shortlists/ShortlistRoutes'));
const CommunityRoutes = lazy(() => import('./pages/CommunityRoutes'));
const OrganizationRoutes = lazy(() => import('./pages/OrganizationRoutes'));
const EventPage = lazy(() => import('./pages/Event'));
const Discover = lazy(() => import('./pages/Discover'));
const ExperimentsExploreMatchmaking = lazy(() => import('./pages/Experiments/Matchmaking/Matches'));
const EventRoutes = lazy(() => import('./pages/EventRoutes'));

const Backoffice = lazy(() => import('./pages/Backoffice'));
const EnterpriseOverview = lazy(() => import('pages/Enterprise/Index'));
const CompanyRegistration = lazy(() => import('./pages/Registration/Company'));
const OrganizationRegistration = lazy(() => import('./pages/Registration/Organization'));
const CommunityRegistration = lazy(() => import('./pages/Registration/Community'));
const Unsubscribe = lazy(() => import('./pages/Unsubscribe'));
const Feedback = lazy(() => import('./pages/Feedback'));
const VerifyUserEmail = lazy(() => import('./pages/VerifyUserEmail'));
const DeleteConfirmation = lazy(() => import('./pages/DeleteConfirmation'));
const SlackAuth = lazy(() => import('./auth/Slack'));

const EditProfile = React.lazy(() => import('pages/profile/Edit'));

const HelpCenter = lazy(() => import('pages/HelpCenter/HelpCenter'));
const PrivacyPolicy = lazy(() => import('pages/Legal/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('pages/Legal/TermsOfUse'));

function Logout() {
  const { logout } = useLogout();
  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: createRedirectUri('/'),
      },
    });
  }, []);

  return <LoadingScreen />;
}

function UserRoutesContainer({ children }: { children: React.ReactNode }) {
  const { resource: enterprisesResource } = useEnterprises();
  const enterprises = getOrElse(enterprisesResource, { values: [] }).values;
  const href = enterprises[0]?.slug === 'seb' ? '/seb' : `/e/${enterprises[0]?.slug}`;

  useContextualNavigation(
    {
      navLinks:
        enterprises.length > 0
          ? [
              {
                label: enterprises[0].name,
                href,
                identifier: 'enterprise',
                icon: <GroupIcon />,
              },
            ]
          : [],
    },
    [enterprisesResource.state],
  );
  return children;
}

export default function Routes() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [log] = useJourneyLogger();
  const initialValue = v4();
  const [anonymousId] = useLocalStorage<UUID>(ANONYMOUS_USER_ID_KEY, initialValue);

  const [accessToken] = useAccessToken();
  const trackView = useDebouncedCallback(values => trackPageView(values), 500); // Deal with duplicate events, redirects etc

  const prevLocationRef = useRef(history.location);
  useEffect(() => {
    history.listen((location: any) => {
      const prevlocation = prevLocationRef.current;
      const isPathChanged = prevlocation?.pathname !== location.pathname;
      if (isPathChanged) {
        trackView({
          path: location.pathname,
          queryParams: new URLSearchParams(location.search),
          anonymousId: anonymousId,
        });
        updateRoute(); // Intercom
        dispatch<LocationChangeAction>({
          type: LOCATION_CHANGED_ACTION,
          pathname: location.pathname,
          previousPathname: prevlocation?.pathname,
        });
      }
      prevLocationRef.current = location;
    });
  }, []);

  // Track initial page view, but wait until we know who the user is
  useEffect(() => {
    if (accessToken.state !== 'fetching') {
      trackView({
        path: location.pathname,
        queryParams: new URLSearchParams(location.search),
        anonymousId: anonymousId,
      });
    }
  }, [accessToken.state]);

  useEffect(() => {
    log(`entrypoint at ${location.pathname}`);
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Suspense fallback={<LoadingBar isComplete={false} />}>
        <Switch>
          <Route exact path="/dynamic_link" component={RedirectFromDynamicLink} />
          <Route exact path="/auth0/callback" component={Auth0Callback} />
          <Route exact path="/register/verify" component={VerifyUserEmail} />
          <Route exact path="/delete-confirmation" component={DeleteConfirmation} />
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/unsubscribe" component={Unsubscribe} />
          {/* The /register, /registercompany and /registerorganization routes requires the user to be logged in.
              However, in order to make auth0 pre-fill email and keep any query params from the url,
              we need to keep the routes outside of the AuthenticatedRoutes, and redirect them manually.
          */}
          <Route exact path="/register" component={UserRegistration} />
          <Route exact path="/registercompany" component={CompanyRegistration} />
          <Route exact path="/registerorganization" component={OrganizationRegistration} />
          <Route exact path="/complete-registration" component={CompleteRegistration} />
          <Route exact path="/logout" component={Logout} />
          <Route path="/legal/privacy-policy" component={PrivacyPolicy} />
          <Route path="/legal/terms-of-use" component={TermsOfUse} />
          <Route path="/organizations/:slug" component={OrganizationRoutes} />
          <Route path="/companies/:companyId" component={CompanyRoutes} />
          <Route path="/communities/:communitySlug" component={CommunityRoutes} />
          <Route exact path="/collections/:id" component={ShortlistRoutes} />
          <Route path="/events/:id" component={EventRoutes} />
          <AuthenticatedRoutes>
            <InitialLoadWrapper>
              <UserRoutesContainer>
                {/* Suspense: https://github.com/mui/material-ui/issues/33748 */}
                <Suspense fallback={<LoadingBar isComplete={false} />}>
                  <Switch>
                    <Route exact path="/ctx/:companyId/:type/:contentId" component={RedirectFromContent} />
                    <Route exact path="/registercommunity" component={CommunityRegistration} />
                    <Route path="/events/:id" component={EventPage} />
                    <Route
                      path="/backoffice"
                      render={props => (
                        <BackofficeRoute {...props}>
                          <Backoffice />
                        </BackofficeRoute>
                      )}
                    />
                    <Route exact path="/slack/auth/:communitySlug" component={SlackAuth} />
                    <Route exact path="/explore/matches" component={ExperimentsExploreMatchmaking} />
                    <Route
                      exact
                      path="/explore"
                      render={() => {
                        // NB! Normal redirect will not work here
                        // It's not clear to me exactly why, but it has to do with lazy/suspense
                        startTransition(() => {
                          history.push('/discover');
                        });
                        return <LoadingBar isComplete={false} />;
                      }}
                    />
                    <Route path="/discover" component={Discover} />
                    <Route path="/help" component={HelpCenter} />
                    <Route exact path="/profile" component={Profile} />
                    <Route path="/profile/edit" component={EditProfile} />
                    <Route exact path="/people/:cwUserId" component={UserProfile} />
                    <Route path="/e/:slug" render={({ match }) => <EnterpriseOverview slug={match.params.slug} />} />
                    <Route path="/seb" render={() => <EnterpriseOverview slug="seb" />} />
                    <Route path="/" component={Dashboard} />
                  </Switch>
                </Suspense>
              </UserRoutesContainer>
              <RightHandSidebar />
            </InitialLoadWrapper>
          </AuthenticatedRoutes>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}
