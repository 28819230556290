import React from 'react';
import Button from 'ui/elements/buttons/Button';
import CopyIcon from 'ui/elements/icons/CopyIcon';
import MailIcon from 'ui/elements/icons/EnvelopeIcon';
import cx from 'classnames';

import styles from './styles.scss';
import useNotify from 'hooks/useNotify';

interface Props {
  email: string;
}

export default function Email({ email }: Props) {
  const notify = useNotify();

  const onCopyEmail = () => {
    navigator.clipboard.writeText(email);
    notify('success', 'Email address copied to clipboard');
  };

  return (
    <div className={cx(styles.emailContainer, 'u-flex-align-center u-flex-space-between')}>
      <a href={`mailto:${email}`} className="u-flex u-flex-align-center text-link-small text-link-dark">
        <MailIcon fontSize="small" className="u-half-spacing-right" />
        Send email
      </a>
      <Button kind="primary" size="small" onClick={onCopyEmail}>
        <CopyIcon className="u-quarter-spacing-right" style={{ fontSize: '15px' }} /> <span>Copy</span>
      </Button>
    </div>
  );
}
