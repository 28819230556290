import React, { useState } from 'react';
import UpdateList from './UpdateList';
import { range } from 'util/arrayUtils';
import { TinyCommunity } from 'types/company/community';
import { reloadAllUpdates } from './useCommunityUpdates';
import { PaginationOptions } from 'types/api';
import { UpdatesFilter } from 'actions/content/updates';

export default function PaginatedUpdates({
  community,
  paginationOptions,
  useMasonry,
  filterBy,
  shouldDisplayTopicSelector,
}: {
  community: TinyCommunity;
  paginationOptions: PaginationOptions;
  useMasonry?: boolean;
  filterBy: UpdatesFilter;
  shouldDisplayTopicSelector: boolean;
}) {
  const { id: communityId } = community;
  const [lastPage, setPage] = useState(paginationOptions.page || 1);
  const [shouldPreload, setShouldPreload] = useState(false);
  setTimeout(() => setShouldPreload(true), 2000); // wait a bit before preloading next page to avoid loading too much at the same time

  return (
    <>
      {range(paginationOptions.page || 1, lastPage).map(page => (
        <UpdateList
          key={page}
          paginationOptions={{
            page,
            limit: paginationOptions.limit,
          }}
          loadPage={() => setPage(page + 1)}
          filter={filterBy}
          community={community}
          onChange={() => reloadAllUpdates(communityId)}
          showSeeMore={page === lastPage}
          useMasonry={useMasonry}
          shouldDisplayTopicSelector={shouldDisplayTopicSelector}
        />
      ))}
      {/* preload next page */}
      {shouldPreload && (
        <div style={{ display: 'none' }}>
          <UpdateList
            paginationOptions={{
              page: lastPage + 1,
              limit: paginationOptions.limit,
            }}
            filter={filterBy}
            community={community}
            onChange={() => reloadAllUpdates(communityId)}
            shouldDisplayTopicSelector={shouldDisplayTopicSelector}
          />
        </div>
      )}
    </>
  );
}
