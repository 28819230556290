import React from 'react';
import { ICompany } from 'types/company';
import { TinyCommunity } from 'types/company/community';
import Logo from 'ui/domain/Logo';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import { Content } from 'ui/views/dialogs/Dialog';
import { PostAs } from '.';
import Avatar from 'ui/elements/avatars/Avatar';
import { SelfUserProfile } from 'types/user';

interface Props {
  myCompanyMemberships: ICompany[];
  community: TinyCommunity;
  setChosenCompany: (v: PostAs) => void;
  canPostAsCommunity: boolean;
  canPostAsUser: boolean;
  user?: SelfUserProfile;
}

export default function SelectCompany({
  myCompanyMemberships,
  community,
  setChosenCompany,
  canPostAsCommunity,
  canPostAsUser,
  user,
}: Props) {
  return (
    <Content paddingY="bottom">
      <div className="u-content-spacing-y">
        <p className="text-body">
          You manage several companies that are part of this community. Please select which company to post from:
        </p>
      </div>
      <CardStack className="u-flex u-flex--column" backgroundColor="white">
        {myCompanyMemberships.map(company => {
          return (
            <div className="u-flex" key={company.id}>
              <ClickableCard
                color="light-grey"
                className="u-flex-grow"
                onClick={() => setChosenCompany({ type: 'company', company })}
                avatar={<Logo type="company" url={company.logoURL} size={60} />}
                title={company.name}
              />
            </div>
          );
        })}
        {canPostAsCommunity && (
          <div className="u-flex" key={community.id}>
            <ClickableCard
              color="light-grey"
              className="u-flex-grow"
              onClick={() => setChosenCompany({ type: 'community', community })}
              avatar={<Logo type="community" url={community.logoURL} size={60} />}
              title={community.name}
            />
          </div>
        )}
        {canPostAsUser && user && (
          <div className="u-flex" key={community.id}>
            <ClickableCard
              color="light-grey"
              className="u-flex-grow"
              onClick={() => setChosenCompany({ type: 'user', user })}
              avatar={<Avatar imageUrl={user.imageUrl} size={60} />}
              title={user.name}
            />
          </div>
        )}
      </CardStack>
    </Content>
  );
}
