import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function GmailIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 76 59">
      <path d="M2 8.67093L2.34505 7.0607L2.92013 5.68051L3.95527 4.41534L5.22045 3.26518L6.83067 2.46006L7.7508 2.11502L9.01597 2L10.9712 2.11502L12.0064 2.46006L12.9265 2.92013L38 21.4377L60.8882 4.53035L63.1885 2.92013L63.9936 2.46006L64.7987 2.23003L65.7189 2H66.754L67.7891 2.11502L68.7093 2.34505L69.5144 2.57508L70.3195 3.03514L71.1246 3.49521L71.8147 4.1853L72.5048 4.8754L73.1949 5.79553L74 7.86581V51.8019L73.655 53.0671L73.0799 54.3323L71.5847 55.4824L69.9744 56.0575L57.6677 56.1725V28.2236L38 42.9457L18.3323 28.2236V56.1725L6.02556 56.0575L4.41534 55.3674L3.15016 54.4473L2.34505 53.1821L2 51.9169V8.67093Z" />
    </SvgIcon>
  );
}
