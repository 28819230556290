import { useTheme } from '@mui/material';
import DocumentAPI from 'apis/DocumentAPI';
import useWindowWidth from 'hooks/useWindowWidth';
import React from 'react';
import { ICompany, TrustLevel } from 'types/company';
import { DocumentDTO, FolderDTO } from 'types/documents';
import { UserProfile } from 'types/user';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import FolderIcon from 'ui/elements/icons/FolderIcon';
import { dateMonthYear } from 'util/dateUtils';
import i18n from 'util/i18n';
import DocumentActions from 'domain/companies/Documents/DocumentCenter/DocumentActions';
import FolderActions from './components/Actions/FolderActions';
import Table from 'ui/elements/tables/Table/Table';
import { communityUrls, companyUrls } from 'urls';
import { NameCell } from './components/NameCell';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { getUserProfiles } from 'actions/userProfiles';
import { invalidate } from 'hooks/useSWR';
import { documentCenterDocumentsKey, useDocumentCenterDocuments } from 'apis/DocumentAPI/useDocuments';
import { downloadBlob } from '../shared/Files/actions';
import useDownloadWarning from './useDownloadWarning';

interface Props {
  company: ICompany;
  isCommunity: boolean;
  trustLevels: TrustLevel[];
  userProfiles: UserProfile[];
  canUploadDocuments: boolean;
  folderId?: string;
  folderNavigation: string | ((folderId: string) => void);
  folders: FolderDTO[];
  foldersToShow: FolderDTO[];
}

export type DocumentColumnContent =
  | {
      mobile: boolean;
      companyId: number;
      type: 'folder';
      value: FolderDTO;
      folders: FolderDTO[];
      trustLevels: TrustLevel[];
    }
  | {
      mobile: boolean;
      type: 'document';
      companyId: number;
      value: DocumentDTO;
      folderId?: string;
      actions: {
        onCopy: (documentId: DocumentDTO['id']) => void;
      };
    };

function ActionsCell({
  original,
  canUploadDocuments,
  isCommunity,
}: {
  original: DocumentColumnContent;
  canUploadDocuments: boolean;
  isCommunity: boolean;
}) {
  const notify = useNotify();
  const [deleteDocument, isDeleting] = useLazyResource(
    (documentId: string) => DocumentAPI.deleteDocument(original.companyId, documentId),
    {
      onSuccess: () => {
        original.type === 'document' &&
          original.folderId &&
          invalidate(documentCenterDocumentsKey(original.companyId, original.folderId));
        notify('success', 'Document deleted');
      },
      onFailure: () => notify('error', 'Could not delete document'),
    },
  );

  const [downloadDocument, isDownloading] = useLazyResource(
    (documentId: string) =>
      DocumentAPI.download(original.companyId, documentId).then(response => downloadBlob(response)),
    {
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );

  const { onClick, WarningDialog } = useDownloadWarning(original.value.name, () => downloadDocument(original.value.id));

  if (original.type === 'folder') {
    return (
      <FolderActions
        companyId={original.companyId}
        trustLevels={original.trustLevels}
        folder={original.value}
        folders={original.folders}
        mobile={original.mobile}
        canUploadDocument={canUploadDocuments}
        isCommunity={isCommunity}
      />
    );
  }

  return (
    <>
      <DocumentActions
        document={original.value}
        onCopy={original.actions.onCopy}
        mobile={original.mobile}
        companyId={original.companyId}
        listDownloadedBy={pagOpts => DocumentAPI.listDownloadedBy(original.companyId, original.value.id, pagOpts)}
        deleteDocument={deleteDocument}
        isDeleting={isDeleting}
        downloadDocument={onClick ? onClick : () => {}}
        canUploadDocuments={canUploadDocuments}
        isDownloading={isDownloading}
      />
      {WarningDialog}
    </>
  );
}

export default function DocumentListing(props: Props) {
  const { canUploadDocuments } = props;
  const notify = useNotify();

  const onCopy = (documentId: DocumentDTO['id']) => {
    const downloadUrl = props.isCommunity
      ? `${location.origin}${communityUrls.documents.download(props.company.id, documentId)}`
      : `${location.origin}${companyUrls.documents.download(props.company.slug, documentId)}`;
    navigator.clipboard.writeText(downloadUrl);
    notify('success', i18n('en').documents.linkCopied);
  };

  const { resource: documents } = useDocumentCenterDocuments(props.company.id, props.folderId, getUserProfiles);

  const width = useWindowWidth();
  const theme = useTheme();
  const mobile = width < theme.breakpoints.values.md;
  const docs = documents.state === 'fetched' ? documents.resource.documents : [];
  const getCreatorName = (createdByCwUserId: string) => {
    const creator = props.userProfiles.find(u => u.cwUserId === createdByCwUserId);
    return creator ? creator.name : '-';
  };

  const data: DocumentColumnContent[] = [
    ...props.foldersToShow.map(folder => {
      const folderData: DocumentColumnContent = {
        companyId: props.company.id,
        type: 'folder',
        value: folder,
        mobile,
        folders: props.folders,
        trustLevels: props.trustLevels,
      };
      return folderData;
    }),
    ...docs.map(doc => {
      const documentData: DocumentColumnContent = {
        type: 'document',
        value: doc,
        folderId: props.folderId,
        actions: {
          onCopy,
        },
        mobile,
        companyId: props.company.id,
      };
      return documentData;
    }),
  ];

  return (
    <Table
      isLoading={documents.state === 'fetching'}
      infoMessages={{ noResults: 'No folders or documents have been uploaded yet.' }}
      columnClassNames={['', '', 'u-desktop-up-only', 'u-desktop-up-only']}
    >
      <Table.Header>
        <Table.HeaderCell />
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell align="left">Uploader</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Header>
      <Table.Body>
        {data.map(it => (
          <Table.Row key={it.value.id}>
            <Table.DataCell>
              {it.type === 'document' ? <FileIcon name={it.value.name} /> : <FolderIcon />}
            </Table.DataCell>
            <Table.DataCell>
              <NameCell
                folderNavigation={
                  typeof props.folderNavigation === 'string'
                    ? `${props.folderNavigation}/folders/${it.value.id}`
                    : props.folderNavigation
                }
                original={it}
                canUploadDocuments={canUploadDocuments}
                companyId={props.company.id}
                userProfiles={props.userProfiles}
                context={props.isCommunity ? 'community' : 'company'}
              />
            </Table.DataCell>
            <Table.DataCell>{it.type === 'folder' ? '' : dateMonthYear(it.value.createdAt)}</Table.DataCell>
            <Table.DataCell align="left">
              {it.type === 'folder' ? '' : getCreatorName(it.value.createdByCwUserId)}
            </Table.DataCell>
            <Table.DataCell align="right" style={{ width: mobile ? 58 : it.type === 'folder' ? 118 : 172 }}>
              <ActionsCell original={it} canUploadDocuments={canUploadDocuments} isCommunity={props.isCommunity} />
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
