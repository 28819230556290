import { communityRoles } from 'domain/communities/roleUtils';
import { RoomRoles } from './types';
import { roleDisplayNamePlural as companyRoleDisplayNamePlural } from 'domain/companies/roleUtils';

export const roleDisplayNamePlural = (role: RoomRoles) => {
  switch (role) {
    case 'admin':
      return 'admins';
    case 'user':
      return 'users';
  }
};

export const getRelevantRoles = (
  context: 'company' | 'community',
  companyId: number,
): { value: string; label: string }[] =>
  context === 'company'
    ? [
        { value: 'all', label: 'All profiles' },
        { value: 'investor', label: companyRoleDisplayNamePlural('investor') },
        { value: 'board', label: companyRoleDisplayNamePlural('board') },
        {
          value: 'prospective_investor',
          label: companyRoleDisplayNamePlural('prospective_investor'),
        },
      ]
    : [
        { value: 'all', label: 'All profiles' },
        ...communityRoles('investor_arena', companyId).map(role => ({
          value: role,
          label: companyRoleDisplayNamePlural(role),
        })),
      ];
