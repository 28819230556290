import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import React from 'react';
import Attachments from 'ui/domain/Attachments/Attachments';
import { EventDTO } from 'ui/domain/Event/types';
import { getOrUndefined } from 'util/resource';
import styles from './styles.scss';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import { TinyOrganizationDTO } from 'types/organization';
import { Divider } from '@mui/material';
import { EventInterest } from './EventInterest';
import PartnersAndHosts from './PartnersAndHosts';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import Card from 'ui/views/cards/Card';
import LocationMap from 'domain/shared/Location/LocationMap';
import UserElement from 'ui/domain/Users/UserElement';
import SectionHeading from 'ui/elements/SectionHeading';
import { UserProfile } from 'types/user';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import HtmlContentWithMentions from 'domain/shared/HtmlContentWithMentions';

export function ShowEvent({ event }: { event: EventDTO }) {
  const [isAdminUserDialogOpen, setIsAdminUserDialogOpen] = React.useState<UserProfile | undefined>(undefined);
  const communityEventAPI = communitiesApi.events;
  const organizationEventAPI = OrganizationAPI.events;

  const { resource: organizationResource } = useResource<TinyOrganizationDTO>(
    event.organizationId ? OrganizationAPI.getById(event.organizationId) : undefined,
  );
  const organization = getOrUndefined(organizationResource);

  const { resource: communityResource } = useCommunitySummary(event.communityId);

  const community = getOrUndefined(communityResource);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <HtmlContentWithMentions className="ql-rich-text">{event.content}</HtmlContentWithMentions>
          {event.attachments.length > 0 && (
            <Attachments
              contentId={event.id}
              attachmentIds={event.attachments}
              listAttachments={() =>
                event.communityId
                  ? communityEventAPI.listAttachments(event.communityId!, event.id)
                  : event.organizationId
                    ? organizationEventAPI.listAttachments(event.organizationId!, event.id)
                    : Promise.resolve({ documents: [] })
              }
              downloadAttachment={(_, documentId) =>
                event.communityId
                  ? communityEventAPI.download(event.communityId!, event.id, documentId)
                  : event.organizationId
                    ? organizationEventAPI.download(event.organizationId!, event.id, documentId)
                    : Promise.resolve({ path: '', filename: '', contentType: '' })
              }
              isPreview={false}
              imgCount={0}
              onReadMore={() => {}}
            />
          )}
        </div>

        <div className={styles.right}>
          <SectionHeading heading="Contact us" />
          <div className="u-content-spacing-bottom">
            {event.contactPerson && (
              <div className="u-quarter-spacing" key={event.contactPerson.cwUserId}>
                <Card onClick={() => setIsAdminUserDialogOpen(event.contactPerson)}>
                  <UserElement user={event.contactPerson} />
                </Card>
              </div>
            )}

            {event.richLocation && (
              <LocationMap latitude={event.richLocation?.latitude} longitude={event.richLocation?.longitude} />
            )}

            {event.richLocation && (
              <Card>
                <span className="text-subheading-2">LOCATION:</span>
                <span className="text-small u-quarter-spacing-left">{event.richLocation.description}</span>
              </Card>
            )}
          </div>
          <UserProfileDialog
            isOpen={isAdminUserDialogOpen !== undefined}
            cwUserId={isAdminUserDialogOpen?.cwUserId ?? ''}
            onClose={() => setIsAdminUserDialogOpen(undefined)}
          />

          <PartnersAndHosts event={event} organization={organization} community={community?.details} />
          <Divider className="u-content-spacing-bottom" />
          <EventInterest event={event} community={community?.details} organization={organization} />
        </div>
      </div>
    </>
  );
}
