import { useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { notify } from 'actions/snackbar';
import Resource from 'util/resource/Resource';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import { ProgressHandler } from 'hooks/useProgressHandler';
import curry from 'ramda/src/curry';
import React, { useMemo } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { ReplyContentDTO, TopicDTO, TopicReplyDTO } from 'types/company/community';
import { addItem, updateInList } from 'util/paginationUtils';
import { createReply, fetchDiscussion, fetchReplies, saveReply } from './actions';
import ReplyToTopic from './components/ReplyToTopic';
import TopicDialogTitle from './components/TopicDialogTitle';
import TopicReplies from './components/TopicReplies';
import useNotify from 'hooks/useNotify';

interface Props {
  communityId: number;
  companyId: number;
  discussionId: UUID;
  onClose: () => void;
}

interface ReplyContainerProps {
  topic: TopicDTO;
  replies: PaginatedResult<TopicReplyDTO>;
  saveReply: (replyId: string, dto: ReplyContentDTO, progressHandler: ProgressHandler) => Promise<void>;
  onSeeMore: (pagOpts: PaginationOptions) => Promise<void>;
  onSubmit: (afterCompleted: () => void, reply: ReplyContentDTO, progressHandler: ProgressHandler) => Promise<void>;
  onClose: () => void;
}

function ReplyContainer(props: ReplyContainerProps) {
  const { topic, replies, onSeeMore, onClose } = props;
  // avoid creating a new list every render
  // this list is used in a effect, which runs every time the list changes.
  const sliced = useMemo(() => replies.values.slice(1), [replies]);
  return (
    <>
      <TopicDialogTitle title={topic.title} description={replies.values[0]} onClose={onClose} />
      <div className="u-horizontal-divider" />
      <div className="u-content-padding-x">
        <TopicReplies replies={sliced} onSave={props.saveReply} notify={notify} />
        <SeeMore
          text="Show more replies"
          className="u-content-spacing-top u-flex-center"
          resource={replies}
          limit={replies.limit}
          loadResource={onSeeMore}
        />
        <ReplyToTopic onSubmit={props.onSubmit} topicTitle={topic.title} />
      </div>
    </>
  );
}

export default function DiscussionDialog(props: Props) {
  const { communityId, companyId, discussionId } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const notify = useNotify();

  const [topicResource] = useResourceLegacy(
    () => fetchDiscussion(communityId, companyId, discussionId),
    [communityId, companyId, discussionId],
  );

  const [repliesResource, , setReplies, onSeeMore] = usePaginatedResourceLegacy(
    pagOpts => fetchReplies(communityId, companyId, discussionId, pagOpts),
    [communityId, companyId, discussionId],
  );

  return (
    <Dialog fullScreen={fullScreen} maxWidth="sm" fullWidth open onClose={props.onClose} scroll="body">
      <Resource resource={topicResource}>
        {topic => (
          <Resource resource={repliesResource}>
            {replies => {
              const actualSaveReply = curry(saveReply)(notify, communityId, companyId, discussionId, savedReply =>
                setReplies({
                  resource: updateInList(
                    replies,
                    r => r.id === savedReply.id,
                    () => savedReply,
                  ),
                  state: 'fetched',
                  isValidating: false,
                }),
              );
              const onPost = curry(createReply)(notify, communityId, companyId, discussionId, bornReply => {
                notify('success', 'Reply has been posted');
                setReplies({ resource: addItem(replies, bornReply, 'after'), state: 'fetched', isValidating: false });
              });
              return (
                <ReplyContainer
                  topic={topic}
                  replies={replies}
                  saveReply={actualSaveReply}
                  onSubmit={onPost}
                  onSeeMore={onSeeMore}
                  onClose={props.onClose}
                />
              );
            }}
          </Resource>
        )}
      </Resource>
    </Dialog>
  );
}
