import useLocalStorage from 'hooks/useLocalStorage';

export function useHasReadMentorWelcome(communityId?: number) {
  const [hasReadWelcome, setHasReadWelcome] = useLocalStorage(
    `has-read-mentor-welcome-${communityId ?? 'global'}`,
    false,
  );

  return { hasReadWelcome, setHasReadWelcome };
}

export function useHasDismissedInsightMessage(companyId?: number) {
  const [hasDismissedMessage, setHasDismissedMessage] = useLocalStorage(
    `has-dismissed-insight-message-${companyId ?? 'global'}`,
    false,
  );

  return { hasDismissedMessage, setHasDismissedMessage };
}

export function useHasReadMentoringWidget(communityId?: number) {
  const [hasReadMentoringWidget, sethasReadMentoringWidget] = useLocalStorage(
    `has-read-mentor-mentoring-widget-${communityId ?? 'global'}`,
    false,
  );

  return { hasReadMentoringWidget, sethasReadMentoringWidget };
}
