import HtmlContent from 'ui/elements/HtmlContent';
import { asUserAuthor } from 'domain/shared/author';
import React from 'react';
import { TopicReplyDTO } from 'types/company/community';
import { getOrUndefined } from 'util/resource';
import { UserProfile } from 'types/user';
import Byline from 'ui/domain/Byline';
import EditAction from 'ui/elements/actions/EditAction';
import Card from 'ui/views/cards/Card';
import IconButton from 'ui/elements/icons/IconButton';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

interface Props {
  reply: TopicReplyDTO;
  creator?: UserProfile;
  openEditDialog: (reply: TopicReplyDTO) => void;
}

export default function TopicReply(props: Props) {
  const { reply, creator, openEditDialog } = props;
  const { resource: userProfileResource } = useSelfUserProfile();

  const isOriginalPoster = getOrUndefined(userProfileResource)?.cwUserId === reply.creatorCwUserId;

  return (
    <Card>
      <div className="u-content-spacing-bottom u-flex u-flex-space-between u-flex-align-center">
        {creator && <Byline timestamp={reply.createdAt} author={asUserAuthor(props.creator)} />}
        <span>
          {isOriginalPoster && (
            <IconButton color="indigo" hoverColor="blue" onClick={() => openEditDialog(reply)}>
              <EditAction tooltip="Edit reply" />
            </IconButton>
          )}
        </span>
      </div>
      <HtmlContent>{reply.content.htmlContent}</HtmlContent>
    </Card>
  );
}
