import { bluePlanetTheme } from 'ui/theme';
import IconButton from 'ui/elements/icons/IconButton';
import Avatar from '@mui/material/Avatar';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import AddIcon from 'ui/elements/icons/AddIcon';
import React from 'react';
import { IconProps } from 'ui/elements/icons/SvgIcon';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { ICompany } from 'types/company';
import useNotify from 'hooks/useNotify';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import SendIcon from 'ui/elements/icons/SendIcon';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import CodeIcon from 'ui/elements/icons/CodeIcon';
import QrCodeIcon from 'ui/elements/icons/QrCodeIcon';
import config from 'config';
import { companyUrls } from 'urls';
import { notEmpty } from 'util/arrayUtils';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getIframeCode } from 'pages/CompanySettings/utils';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import { QrCodeDialog } from 'domain/companies/profile/ShareCompany';
import useDialogHandler from 'hooks/useDialogHandler';
import PopMenu from 'ui/modules/PopMenu';
import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import Resource from 'util/resource/Resource';
import CompanyShortlistPicker from 'domain/Shortlists/CompanyShortlistPicker';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import useFollowCompany from 'domain/companies/Follow/useFollowCompany';

function IconAvatarTextButton({
  onClick,
  Icon,
  text,
  isLoading,
  avatarSize,
  iconSize,
  textSize,
  textSpacing,
}: {
  onClick: () => void;
  Icon: React.ComponentType<IconProps>;
  text: string;
  isLoading: boolean;
  avatarSize: string;
  iconSize: string;
  textSize: string;
  textSpacing: string;
}) {
  return (
    <div className={'u-flex--column u-flex-center u-flex--align-items-center'}>
      <IconButton onClick={onClick} color={'inherit'}>
        <Avatar
          sx={{
            backgroundColor: bluePlanetTheme.bluePlanetPalette.blue.light,
            width: avatarSize,
            height: avatarSize,
          }}
        >
          {isLoading ? (
            <CircularProgress style={{ fontSize: iconSize }} />
          ) : (
            <Icon style={{ fontSize: iconSize, color: 'black' }} />
          )}
        </Avatar>
      </IconButton>
      <div className={`${textSize} text-weight-bold u-${textSpacing}-top`}>{text}</div>
    </div>
  );
}

function CompanySharePopMenu({
  company,
  onShareDialogHandlerOpen,
  onQrCodeDialogHandlerOpen,
  icon,
}: {
  company: ICompany;
  onShareDialogHandlerOpen: () => void;
  onQrCodeDialogHandlerOpen: () => void;
  icon: React.JSX.Element;
}) {
  const notify = useNotify();

  const {
    copy: copyProfileLink,
    showCopiedSuccess: showProfileLinkCopiedSuccess,
    clear: clearProfileLinkCopiedSuccess,
  } = useCopyToClipboard(
    `${location.origin}${companyUrls.overview(
      company.slug,
      'profile',
    )}?utm_source=direct&utm_medium=copy-link&utm_campaign=company&utm_term=card`,
  );

  const {
    copy: copyEmbedCode,
    showCopiedSuccess: showEmbedCodeCopiedSuccess,
    clear: clearEmbedCodeCopiedSuccess,
  } = useCopyToClipboard(getIframeCode(company.slug, 'detailed'));

  const sharingMenuItems = [
    {
      icon: <SendIcon />,
      text: <>Send in chat</>,
      onClick: onShareDialogHandlerOpen,
    },
    {
      icon: showProfileLinkCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <LinkIcon />,
      text: <>Copy link to profile</>,
      onClick: () => {
        clearEmbedCodeCopiedSuccess();
        copyProfileLink();
        notify('success', `Profile link of ${company.name} copied to clipboard`);
      },
    },
    {
      icon: showEmbedCodeCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <CodeIcon />,
      text: <>Copy embed code</>,
      onClick: () => {
        clearProfileLinkCopiedSuccess();
        copyEmbedCode();
        notify('success', `Embed code of ${company.name} copied to clipboard`);
      },
    },
    {
      icon: <QrCodeIcon />,
      text: <>Show QR</>,
      onClick: onQrCodeDialogHandlerOpen,
    },
    navigator.share
      ? {
          text: <span>More options</span>,
          icon: <ShareIcon />,
          onClick: () => {
            navigator.share({
              title: company.name,
              text: `Check out ${company.name} on CrowdWorks!`,
              url: `${config.PORTAL_URL}${companyUrls.overview(company.slug, 'profile')}?utm_source=direct&utm_medium=native-share&utm_term=card&utm_campaign=profile`,
            });
          },
        }
      : undefined,
  ].filter(notEmpty);

  return <PopMenu icon={icon} items={sharingMenuItems} />;
}

interface PitchDeckActionSlideProps {
  slideHeight?: number;
  slideWidth?: number;
  slideStyle?: React.CSSProperties;
  showInCarousel: boolean;
  company: ICompany;
  isFollowing: boolean;
  showFollowAction: boolean;
  hasShortlisted: boolean;
}

export default function PitchDeckActionSlide(props: PitchDeckActionSlideProps) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const isMediumDesktop = useMediaQuery(bluePlanetTheme.breakpoints.down('lg'));
  const avatarSize = props.showInCarousel ? '30px' : isMobile ? '90px' : isMediumDesktop ? '120px' : '120px';
  const iconSize = props.showInCarousel ? '12px' : isMobile ? '40px' : isMediumDesktop ? '50px' : '50px';
  const textSize = props.showInCarousel
    ? 'text-x-micro'
    : isMobile
      ? 'text-h5'
      : isMediumDesktop
        ? 'text-h3'
        : 'text-h3';
  const textPadding = props.showInCarousel ? '' : 'content-padding';
  const topTextBottomPadding = props.showInCarousel ? 'quarter-padding' : 'section-padding';

  const { onFollow, isSendingFollowRequest } = useFollowCompany(props.company);

  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();

  const showTitle = !props.showInCarousel;

  const shareDialogHandler = useDialogHandler();
  const qrCodeDialogHandler = useDialogHandler();

  return (
    <>
      <div
        className={'u-flex-center u-flex--align-items-center'}
        style={{
          height: props.slideHeight ?? '100%',
          width: props.slideWidth ?? '100%',
          backgroundColor: bluePlanetTheme.bluePlanetPalette.blue.veryLight,
          ...props.slideStyle,
        }}
      >
        <div className={'u-flex--column u-flex-center'}>
          {!props.showFollowAction || props.hasShortlisted ? (
            <>
              {showTitle && (
                <div className={`${textSize} text-weight-bold u-${topTextBottomPadding}-bottom`}>
                  Know someone else that should see this pitch?
                </div>
              )}
              <div className={'u-flex--column u-flex-center u-flex--align-items-center'}>
                <CompanySharePopMenu
                  company={props.company}
                  onShareDialogHandlerOpen={shareDialogHandler.open}
                  onQrCodeDialogHandlerOpen={qrCodeDialogHandler.open}
                  icon={
                    <Avatar
                      sx={{
                        backgroundColor: bluePlanetTheme.bluePlanetPalette.blue.light,
                        width: avatarSize,
                        height: avatarSize,
                      }}
                    >
                      <ShareIcon style={{ fontSize: iconSize, color: 'black' }} />{' '}
                    </Avatar>
                  }
                />
                <div className={`${textSize} text-weight-bold u-${textPadding}-top`}>Share pitch deck</div>
              </div>
            </>
          ) : (
            <>
              {showTitle && (
                <div className={`${textSize} text-weight-bold u-section-padding-bottom`}>
                  Get future traction updates from this company
                </div>
              )}
              <div className={'u-flex-space-around'}>
                <div className={'u-flex--column u-flex-center u-flex--align-items-center'}>
                  <Resource resource={shortlistResource}>
                    {shortLists => (
                      <CompanyShortlistPicker
                        company={props.company}
                        shortlists={shortLists.values}
                        setShortlists={setShortlistResource}
                        menuItemHeight={'50px'}
                        dropdownWidth={'250px'}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        DropDownIcon={
                          <Avatar
                            sx={{
                              backgroundColor: bluePlanetTheme.bluePlanetPalette.blue.light,
                              width: avatarSize,
                              height: avatarSize,
                            }}
                          >
                            <BookmarkIcon style={{ fontSize: iconSize, color: 'black' }} />{' '}
                          </Avatar>
                        }
                      />
                    )}
                  </Resource>
                  <div className={`${textSize} text-weight-bold u-${textPadding}-top`}>Add to my shortlist...</div>
                </div>
                <IconAvatarTextButton
                  onClick={props.isFollowing ? () => {} : () => onFollow()}
                  avatarSize={avatarSize}
                  iconSize={iconSize}
                  textSize={textSize}
                  isLoading={isSendingFollowRequest}
                  Icon={props.isFollowing ? CheckmarkIcon : AddIcon}
                  text={props.isFollowing ? 'Following' : 'Follow'}
                  textSpacing={textPadding}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {shareDialogHandler.isOpen && (
        <UserConversationDialog
          sharedContent={{ type: 'company', companyId: props.company.id }}
          conversation={{ type: 'new' }}
          closeConversation={shareDialogHandler.close}
        />
      )}
      {qrCodeDialogHandler.isOpen && (
        <QrCodeDialog
          companyName={props.company.name}
          url={`${config.PORTAL_URL}/companies/${props.company.slug}`}
          handler={qrCodeDialogHandler}
          filename={props.company.name}
        />
      )}
    </>
  );
}
