import React from 'react';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import { saveUser } from 'actions/userProfiles';
import ExpertiseForm from './ExpertiseForm';
import { getOrElse } from 'util/resource';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { useBusinessDomains } from 'apis/CompanyAPI/labels/useBusinessDomains';
import { useIndustries } from 'apis/CompanyAPI/labels/useIndustries';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';

interface Props {
  userprofile: SelfUserProfile;
  onComplete: () => void;
}

export default function Expertise(props: Props) {
  const notify = useNotify();
  const [onSave, isSaving] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      props.onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  const { resource: industriesResource } = useIndustries();
  const industries = getOrElse(industriesResource, { parents: [], children: [] });
  const { resource: businessDomainsResource } = useBusinessDomains();
  const businessDomains = getOrElse(businessDomainsResource, emptyPaginatedResult()).values;

  return (
    <ExpertiseForm
      saving={isSaving}
      userprofile={props.userprofile}
      industries={industries}
      businessDomains={businessDomains}
      save={data =>
        onSave({
          ...data,
          industryInvestmentPreferences: props.userprofile.industryInvestmentPreferences.map(i => i.id),
          industries: data.industries,
          businessDomains: data.businessDomains,
          isMentor: true,
        })
      }
    />
  );
}
