import React, { ReactNode } from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import CircularProgressBar from '../Progress/CircularProgressBar';
import FadeOutRight from 'ui/modules/Animations/FadeOutRight';
import Card from 'ui/views/cards/Card';

interface Props {
  text: string;
  percent: number;
  elevation?: number;
  completeBehavior?: 'slide out' | 'stay';
  className?: string;
  onClick?: () => void;
}

function InnerBody({ onClick, children }: { onClick?: () => void; children: ReactNode }) {
  if (onClick) {
    return (
      <button className={styles.checklistLink} onClick={onClick}>
        {children}
      </button>
    );
  }

  return <>{children}</>;
}

export default function ChecklistPercent(props: Props) {
  const isFinished = props.percent >= 100;
  const completeBehavior = props.completeBehavior || 'slide out';

  return (
    <FadeOutRight fadeOut={completeBehavior === 'slide out' && isFinished}>
      <InnerBody onClick={props.onClick}>
        <Card elevation={props.elevation} hover="highlight" className={cx(props.className, styles.setupStep)}>
          <CircularProgressBar percent={props.percent} className="u-content-spacing-right" />
          <div style={{ flex: 1 }} className="u-flex-space-between u-flex-align-center">
            <div style={{ flex: 1 }}>
              <div className="text-body">{props.text}</div>
            </div>
            {!isFinished && <ChevronRightIcon className="u-half-spacing-left" fontSize="medium" />}
          </div>
        </Card>
      </InnerBody>
    </FadeOutRight>
  );
}
