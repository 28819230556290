import List from '@mui/material/List';
import React from 'react';

import { PaginatedResult } from 'types';
import { TopicDTO } from 'types/company/community';

import TopicListItem from './TopicListItem';

interface Props {
  discussions: PaginatedResult<TopicDTO>;
  onClick: (topicId: TopicDTO['id']) => void;
  canEdit: boolean;
  openEditDialog: (topic: TopicDTO) => void;
}

export default function TopicList(props: Props) {
  return (
    <List>
      {props.discussions.values.map(topic => (
        <TopicListItem
          key={`discussion-${topic.id}`}
          topic={topic}
          onClick={props.onClick}
          canEdit={props.canEdit}
          openEditDialog={props.openEditDialog}
        />
      ))}
    </List>
  );
}
