import React from 'react';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  document: {
    id: UUID;
    name: string;
  };
  onDelete: (documentId: string) => Promise<any>;
  isDeleting: boolean;
}

export default function DeleteDocumentDialog({ isOpen, isDeleting, onClose, document, onDelete }: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Title onClose={onClose}>Confirm delete</Title>
      <Content>
        <p>
          Are you sure you want to delete <em>{document.name}</em>?
        </p>
      </Content>
      <DialogActions>
        <ButtonList>
          <Button isLoading={isDeleting} onClick={() => onDelete(document.id).then(onClose)} kind="primary">
            Yes, delete
          </Button>
          <Button onClick={onClose} kind="tertiary">
            Cancel
          </Button>
        </ButtonList>
      </DialogActions>
    </Dialog>
  );
}
