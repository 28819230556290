export function joinQueryParams(values: [string, string | undefined][]) {
  if (!values) return '';
  const result = values.filter(value => value[1] !== undefined).map(value => `${value[0]}=${value[1]}`);
  return result.length < 1 ? '' : `?${result.join('&')}`;
}

export function withoutQueryParams(url: string) {
  return url.split('?')[0];
}

export const isInternalLink = (url: string): boolean => {
  try {
    const link = new URL(url, window.location.origin);
    return link.origin === window.location.origin;
  } catch (error) {
    return false;
  }
};

export const extractInternalPath = (url: string): string => {
  try {
    const link = new URL(url);
    return link.pathname + link.search + link.hash;
  } catch (error) {
    return url;
  }
};
