import React from 'react';
import { CommunityRecentComment, CommunityRecentLike, TinyCommunity } from 'types/company/community';
import SectionHeading from 'ui/elements/SectionHeading';
import { useCommunityActivity } from '../CommunityUpdates/useCommunityActivity';
import styled from '@emotion/styled';
import { quarterSpacing } from 'ui/theme/themeConstants';
import ClickableCard from 'ui/views/cards/ClickableCard';
import useRoute from 'hooks/useRoute';
import Avatar from 'ui/elements/avatars/Avatar';
import { useUserProfiles } from 'apis/CompanyAPI/users/useUserProfiles';
import { getOrUndefined } from 'util/resource';
import Resources from 'util/resource/Resources';
import LikeIcon from 'ui/elements/icons/LikeIcon';
import { bluePlanetTheme } from 'ui/theme';
import ReplyNotificationIcon from 'ui/elements/icons/ReplyNotificationIcon';
import { communityUrls } from 'urls';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${quarterSpacing};
`;

const IconContainer = styled.div`
  position: absolute;
  right: -5px;
  bottom: -5px;
  background-color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
  color: white;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 16px;
  }
`;

function renderText(activity: CommunityRecentComment | CommunityRecentLike, userName: string) {
  const innerText =
    activity.type === 'comment' ? (activity.isReply ? 'replied to a comment on' : 'commented on') : 'liked the update';
  return (
    <div className="text-weight-regular">
      <span className="text-weight-medium">{userName}</span> {innerText}:
      <br />
      <span className="text-weight-medium">{activity.excerpt}</span>
    </div>
  );
}

export default function RecentActivity({ community }: { community: TinyCommunity }) {
  const { resource: activities } = useCommunityActivity(community.id);
  const { push } = useRoute();

  const userProfiles = useUserProfiles(getOrUndefined(activities)?.values.map(a => a.cwUserId) ?? []);

  return (
    <>
      <Resources resources={[activities, userProfiles.resource]} renderLoading="Nothing">
        {([activities, userProfiles]) =>
          activities.values.length > 0 && (
            <div className="u-section-spacing-bottom">
              <SectionHeading heading="Recent activity" actionsAlignment="left" addMobileMargin />
              <Container>
                {activities.values.slice(0, 5).map(activity => (
                  <ClickableCard
                    key={activity.id}
                    className="text-align-center"
                    avatar={
                      <div className="u-relative">
                        <Avatar
                          size={50}
                          imageUrl={userProfiles.values.find(u => u.cwUserId === activity.cwUserId)?.imageUrl}
                        />
                        {activity.type === 'like' && (
                          <IconContainer>
                            <LikeIcon fontSize="small" strokeWidth={3} />
                          </IconContainer>
                        )}
                        {activity.type === 'comment' && activity.isReply && (
                          <IconContainer>
                            <ReplyNotificationIcon fontSize="small" />
                          </IconContainer>
                        )}
                      </div>
                    }
                    disableIcon
                    title={renderText(
                      activity,
                      userProfiles.values.find(u => u.cwUserId === activity.cwUserId)?.name ?? 'User',
                    )}
                    onClick={() =>
                      push(
                        communityUrls.updateActivity(
                          community.slug,
                          activity.updateId,
                          activity.type === 'comment' ? activity.id : undefined,
                        ),
                      )
                    }
                    timestamp={activity.createdAt}
                  />
                ))}
              </Container>
            </div>
          )
        }
      </Resources>
    </>
  );
}
