import React, { useState } from 'react';
import { LabelDTO } from 'types/company';

import useNotify from 'hooks/useNotify';
import bluePlanetTheme from 'ui/theme/themeBluePlanet';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Button from 'ui/elements/buttons/Button';
import useLazyResource from 'util/resource/useLazyResource';
import EditIcon from 'ui/elements/icons/EditIcon';
import Dialog, { Content, Title, DialogActions } from 'ui/views/dialogs/Dialog';
import IconButton from 'ui/elements/icons/IconButton';
import LabelChip from 'ui/domain/Chips/LabelChip';
import Label from 'ui/elements/form/Label';
import Resources from 'util/resource/Resources';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import Tooltip from 'ui/elements/Tooltip';
import { IResource } from 'util/resource';
import { List } from 'types/api';
import { halfSpacing } from 'ui/theme/themeConstants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { FormHelperText } from '@mui/material';

const colors = [
  bluePlanetTheme.bluePlanetPalette.grey.medium,
  bluePlanetTheme.bluePlanetPalette.green.light,
  bluePlanetTheme.bluePlanetPalette.violet.light,
  bluePlanetTheme.bluePlanetPalette.blue.light,
  bluePlanetTheme.bluePlanetPalette.red.light,
  bluePlanetTheme.bluePlanetPalette.yellow.light,
  bluePlanetTheme.bluePlanetPalette.orange.light,
  bluePlanetTheme.bluePlanetPalette.grey.main,
  bluePlanetTheme.bluePlanetPalette.green.medium,
  bluePlanetTheme.bluePlanetPalette.violet.medium,
  bluePlanetTheme.bluePlanetPalette.blue.medium,
  bluePlanetTheme.bluePlanetPalette.red.medium,
  bluePlanetTheme.bluePlanetPalette.yellow.medium,
  bluePlanetTheme.bluePlanetPalette.orange.medium,
  bluePlanetTheme.bluePlanetPalette.grey.dark,
  bluePlanetTheme.bluePlanetPalette.green.dark,
  bluePlanetTheme.bluePlanetPalette.violet.dark,
  bluePlanetTheme.bluePlanetPalette.blue.dark,
  bluePlanetTheme.bluePlanetPalette.red.dark,
  bluePlanetTheme.bluePlanetPalette.yellow.dark,
  bluePlanetTheme.bluePlanetPalette.orange.dark,
] as string[];

export default function LabelsDialog({
  title,
  isChecked,
  isDisabled,
  toggleLabel,
  onClose,
  renderContent,
  actions,
  labelsResource,
  mutateLabels,
  api,
  entityName,
}: {
  labelsResource: IResource<List<LabelDTO>>;
  mutateLabels: () => void;
  title: string;
  isChecked?: (label: LabelDTO) => boolean;
  isDisabled?: boolean;
  toggleLabel?: (label: LabelDTO) => void;
  onClose: () => void;
  renderContent?: (content: React.ReactNode) => React.ReactNode;
  actions: React.ReactNode;
  api: {
    create: (name: string, color: string) => Promise<LabelDTO>;
    patch: (label: LabelDTO, name: string, color: string) => Promise<LabelDTO>;
    delete: (label: LabelDTO) => Promise<void>;
  };
  entityName: string;
}) {
  const [mode, setMode] = useState<
    { type: 'list' } | { type: 'create' } | { type: 'edit'; label: LabelDTO } | { type: 'delete'; label: LabelDTO }
  >({ type: 'list' });

  const notify = useNotify();

  const [deleteLabel, isDeleting] = useLazyResource((label: LabelDTO) => api.delete(label), {
    onSuccess: () => {
      mutateLabels();
      setMode({ type: 'list' });
    },
    onFailure: e => notify('error', e || 'Failed to remove label'),
  });

  const dialogClose = mode.type === 'list' ? onClose : () => setMode({ type: 'list' });

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '',
    },
    onSubmit: async values => {
      if (mode.type === 'edit') {
        await api.patch(mode.label, values.name, values.color).catch(() => {
          notify('error', 'Unable to update label');
        });
      } else {
        await api.create(values.name, values.color).catch(() => {
          notify('error', 'Unable to create label');
        });
      }
      mutateLabels();
      setMode({ type: 'list' });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide a name'),
      color: Yup.string().required('Please select a color'),
    }),
    validateOnBlur: true,
  });

  const setModeToEdit = (label: LabelDTO) => {
    setMode({ type: 'edit', label });
    formik.setValues({ name: label.name, color: label.color });
  };

  const setModeToCreate = () => {
    setMode({ type: 'create' });
    formik.resetForm();
  };

  const isSaving = isDeleting || formik.isSubmitting;

  const content = (
    <Resources resources={[labelsResource]}>
      {([labels]) => {
        if (mode.type === 'list') {
          return (
            <div>
              {labels.values.map(label => {
                const hasLabel = !!(isChecked && isChecked(label));

                return (
                  <div className="u-flex u-flex-space-between" key={label.id}>
                    <div>
                      {toggleLabel ? (
                        <Checkbox
                          label={<LabelChip label={label.name} color={label.color} />}
                          disabled={isDisabled}
                          onChange={() => toggleLabel(label)}
                          checked={hasLabel}
                        />
                      ) : (
                        <LabelChip label={label.name} color={label.color} />
                      )}
                    </div>
                    <Tooltip title="Edit label" placement="left">
                      <span>
                        <IconButton color="indigo" onClick={() => setModeToEdit(label)}>
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                );
              })}
              <Button kind="tertiary" onClick={setModeToCreate} className="u-content-spacing-top">
                Create a new label
              </Button>
            </div>
          );
        } else if (mode.type === 'delete') {
          return (
            <div>
              <p>This label will be removed from all {entityName}. There is no undo.</p>
            </div>
          );
        } else {
          return (
            <>
              <FormikTextField formikProps={formik} autoFocus label="name" name="name" />
              <div className="u-content-spacing-top">
                <Label error={!!formik.errors.color}>Select a color</Label>
              </div>
              <div
                className="u-half-spacing-top"
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7, 1fr)',
                  gap: halfSpacing,
                }}
              >
                {colors.map(c => (
                  <LabelChip
                    key={c}
                    label=""
                    color={c}
                    outline={c === formik.values.color}
                    onClick={() => {
                      formik.setFieldValue('color', c);
                    }}
                  />
                ))}
              </div>
              {formik.errors.color && <FormHelperText error>{formik.errors.color}</FormHelperText>}
            </>
          );
        }
      }}
    </Resources>
  );

  return (
    <>
      <Dialog open onClose={dialogClose} mobileLayout="drawer">
        <Title onClose={dialogClose}>{title}</Title>
        <Content>{renderContent ? renderContent(content) : content}</Content>
        <DialogActions>
          {mode.type === 'edit' && (
            <ButtonList className="u-content-spacing-top u-flex-grow">
              <Button isLoading={isSaving} onClick={() => formik.handleSubmit()} kind="primary">
                Save
              </Button>
              <Button onClick={() => setMode({ type: 'list' })} kind="tertiary">
                Cancel
              </Button>
              <div className="u-flex u-flex-space-between u-flex-grow">
                <span />
                <Button
                  isLoading={isSaving}
                  onClick={() => setMode({ type: 'delete', label: mode.label })}
                  color="red"
                  kind="secondary"
                >
                  Delete
                </Button>
              </div>
            </ButtonList>
          )}
          {mode.type === 'create' && (
            <ButtonList className="u-content-spacing-top">
              <Button isLoading={isSaving} onClick={() => formik.handleSubmit()} kind="primary">
                Create
              </Button>
              <Button onClick={() => setMode({ type: 'list' })} kind="tertiary">
                Cancel
              </Button>
            </ButtonList>
          )}
          {mode.type === 'delete' && (
            <Button isLoading={isSaving} kind="primary" color="red" onClick={() => deleteLabel(mode.label)}>
              Delete
            </Button>
          )}
          {mode.type === 'list' && actions}
        </DialogActions>
      </Dialog>
    </>
  );
}
