import CreateDiscussionTopicDialog from './CreateDiscussionTopicDialog';
import useDialogHandler, { DialogHandlerProps } from 'hooks/useDialogHandler';
import curry from 'ramda/src/curry';
import React, { useState } from 'react';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { TopicDTO } from 'types/company/community';
import i18n from 'util/i18n';
import TopicListDialog from './TopicListDialog';
import { editDiscussionTopic } from '../actions';
import useNotify from 'hooks/useNotify';

interface Props {
  communityId: number;
  companyId: number;
  companyName: string;
  setActiveTopic: (topicId: string) => void;
  dialogHandler: DialogHandlerProps;
  canEdit: boolean;
  discussions: PaginatedResult<TopicDTO>;
  addPageToDiscussions: (paginationOptions: PaginationOptions) => Promise<void>;
  updateDiscussionTopic: (topic: TopicDTO) => void;
}

export default function DiscussionListContainer(props: Props) {
  const { communityId, canEdit, setActiveTopic } = props;
  const [editing, setEditing] = useState<TopicDTO>();
  const editTopicDialogHandler = useDialogHandler(false);
  const notify = useNotify();

  function handleOpen(discussion: TopicDTO) {
    setEditing(discussion);
    editTopicDialogHandler.open();
  }

  return (
    <>
      {props.discussions && (
        <TopicListDialog
          discussions={props.discussions}
          addPageToDiscussions={props.addPageToDiscussions}
          companyId={props.companyId}
          companyName={props.companyName}
          communityId={communityId}
          dialogHandler={props.dialogHandler}
          setActiveTopic={setActiveTopic}
          editTopic={setEditing}
          canEdit={canEdit}
          openEditDialog={handleOpen}
        />
      )}
      {canEdit && (
        <CreateDiscussionTopicDialog
          communityId={props.communityId}
          companyId={props.companyId}
          companyName={props.companyName}
          onCreate={async () => {}}
          onEdit={curry(editDiscussionTopic)(notify, props.communityId, props.companyId, topic => {
            notify('success', i18n('en').common.saveOrCreate.success('Save', 'Discussion topic'));
            props.updateDiscussionTopic(topic);
          })}
          dialogHandler={editTopicDialogHandler}
          topicBeingEdited={editing}
        />
      )}
    </>
  );
}
