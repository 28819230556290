import { SelfUserProfile } from 'types/user';
import { notEmpty } from 'util/arrayUtils';

export type MentorOnboardingStage = 'Introduction' | 'Contact info' | 'Expertise' | 'Bio' | 'All done';
export const numberOfMentorOnboardingStages = 4;
const numberOfMandatoryStages = 3;

export function getCompletedMentorOnboardingStages(
  userProfile: SelfUserProfile,
  introIsRead: boolean,
): MentorOnboardingStage[] {
  const completedStages: (MentorOnboardingStage | undefined)[] = [
    !!(userProfile.name && userProfile.phone && userProfile.location) ? 'Contact info' : undefined,
    !!(
      userProfile.mentorExpertise.length > 0 &&
      userProfile.industries.length > 0 &&
      userProfile.businessDomains.length > 0 &&
      userProfile.isMentor
    )
      ? 'Expertise'
      : undefined,
    !!userProfile.description ? 'Bio' : undefined,
  ];
  const result = completedStages.filter(notEmpty);
  // Introduction is not mandatory, so if all the mandatory steps are done we consider Introduction completed aswell
  return result.length >= numberOfMandatoryStages || introIsRead ? result.concat(['Introduction']) : result;
}
