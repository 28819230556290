import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import React, { useEffect } from 'react';
import styles from './styles.scss';
import MaxWidth, { maxWidths } from 'ui/views/containers/MaxWidth';
import Breadcrumb from './Breadcrumb';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { ICompany } from 'types/company';
import CompanyHeaderMobile from 'ui/views/layouts/Mobile/CompanyHeaderMobile';
import { useMobileHeader } from 'domain/shared/Navigation';
import ScrollableBody from '../BaseLayout/ScrollableBody';
import { scrollToTop } from 'util/scroll';

interface Props {
  company: ICompany;
  title: string;
  documentTitle?: string;
  children: React.ReactNode;
  showCompanyNameBelowHeaderOnMobile?: boolean;
}

const Content = styled.div(`
  margin: ${contentSpacing};
  flex-grow: 1;
  ${bluePlanetTheme.breakpoints.down('md')} {
    margin: ${halfSpacing};
  }
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin: 0;
  }
`);

const InnerContent = styled.div(
  `
  margin: 0 auto;
  max-width: ${maxWidths.xl};
`,
);

export function Main({ children }: { children: React.ReactNode }) {
  return (
    <Content>
      <InnerContent>{children}</InnerContent>
    </Content>
  );
}

export default function CompanyPage(props: Props) {
  const { company, showCompanyNameBelowHeaderOnMobile } = props;

  useEffect(() => {
    scrollToTop();
  }, []);

  const documentTitle = `${props.documentTitle ?? props.title}${company?.name ? ` – ${company?.name}` : ''}`;

  useMobileHeader(props.title);

  return (
    <>
      <HeadTags title={documentTitle} />
      <ScrollableBody accountForMobileHeader className={styles.bottomMobileNavigationOffset}>
        {showCompanyNameBelowHeaderOnMobile && <CompanyHeaderMobile company={company} />}
        <div className={styles.bannerHeader}>
          <MaxWidth className={styles.innerBannerHeader} width="xl">
            {company && <Breadcrumb title={props.title} company={company} />}
          </MaxWidth>
        </div>
        {props.children}
      </ScrollableBody>
    </>
  );
}
