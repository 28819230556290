import React from 'react';
import Logo from 'ui/domain/Logo';
import Chip from 'ui/elements/Chip';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import { CompanyInvestorOnboardingDTO } from 'types/company';
import ClickableCard from 'ui/views/cards/ClickableCard';
import styled from '@emotion/styled';
import FollowButton from '../../../companies/Follow/FollowButton';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { Industry } from 'types';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    background: bluePlanetTheme.bluePlanetPalette.white,
    padding: bluePlanetTheme.spacing(1),
    gap: bluePlanetTheme.spacing(0.5),
    boxShadow: bluePlanetTheme.shadows[3],
  },
}));

const Container = styled.div<{ isFollowed: boolean }>`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  &:hover {
    background: ${({ isFollowed }) =>
      isFollowed ? bluePlanetTheme.bluePlanetPalette.grey.light : bluePlanetTheme.bluePlanetPalette.blue.veryLight};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

type Props = {
  company: CompanyInvestorOnboardingDTO;
  industryInvestmentPreferences: Industry[];
  isFollowed: boolean;
  setIsFollowed: any;
};

export default function CompanyMatchingCard({
  company,
  industryInvestmentPreferences,
  isFollowed,
  setIsFollowed,
}: Props) {
  const sortedIndustries = company.industries.sort(
    (a, b) =>
      +industryInvestmentPreferences.some(pref => pref.id === b.id) -
      +industryInvestmentPreferences.some(pref => pref.id === a.id),
  );

  return (
    <Container isFollowed={isFollowed}>
      <ClickableCard
        avatar={<Logo size={50} url={company.details.logoURL} type="company" />}
        title={company.details.name}
        key={company.details.id}
        color="transparent"
      >
        <ChipList className="u-flex-align-baseline">
          <Chip color="yellow" label={company.fundingStage} />
          <TruncatedList
            values={sortedIndustries}
            limit={1}
            renderItem={label => <Chip color="blue" label={label.name} />}
            renderExpandButton={label => (
              <HtmlTooltip
                title={
                  <>
                    {sortedIndustries.slice(1).map(label => (
                      <Chip key={label.id} color="blue" label={label.name} />
                    ))}
                  </>
                }
              >
                <span>
                  <Chip color="blue" label={label.name} />
                </span>
              </HtmlTooltip>
            )}
          />
        </ChipList>
      </ClickableCard>
      <ButtonContainer
        onClick={e => {
          e.stopPropagation();
          setIsFollowed();
        }}
      >
        <FollowButton
          mode="regular"
          kind="tertiary"
          company={company.details}
          trackingKey="investor-company-matching-request-to-follow"
        />
      </ButtonContainer>
    </Container>
  );
}
