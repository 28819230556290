import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CalendarBasicIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 107 107">
      <path
        d="M80.25 8.91699V17.8337M26.75 8.91699V17.8337"
        stroke="#182444"
        strokeWidth="6.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1445 54.5843C11.1445 35.1582 11.1445 25.445 16.7269 19.41C22.3093 13.375 31.2939 13.375 49.2633 13.375H57.7341C75.7034 13.375 84.6883 13.375 90.2706 19.41C95.8529 25.445 95.8529 35.1582 95.8529 54.5843V56.8741C95.8529 76.3004 95.8529 86.0133 90.2706 92.0485C84.6883 98.0833 75.7034 98.0833 57.7341 98.0833H49.2633C31.2939 98.0833 22.3093 98.0833 16.7269 92.0485C11.1445 86.0133 11.1445 76.3004 11.1445 56.8741V54.5843Z"
        strokeWidth="6.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26.75 34.667H80.25" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6.6875" />
      <rect x="30" y="62" width="8" height="8" rx="1" fill="#182444" />
      <rect x="30" y="77" width="8" height="8" rx="1" fill="#182444" />
      <rect x="50" y="77" width="8" height="8" rx="1" fill="#182444" />
      <rect x="50" y="62" width="8" height="8" rx="1" fill="#182444" />
      <rect x="70" y="62" width="8" height="8" rx="1" fill="#182444" />
      <rect x="50" y="46" width="8" height="8" rx="1" fill="#182444" />
      <rect x="70" y="46" width="8" height="8" rx="1" fill="#182444" />
    </SvgIcon>
  );
}
