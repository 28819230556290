import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import React, { useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import FullscreenDialog, { Content, Title } from 'ui/views/dialogs/FullscreenDialog';
import Dialog, { Content as DialogContent } from 'ui/views/dialogs/Dialog';
import i18n from 'util/i18n';
import CompanyUserList from 'domain/communities/InnovasjonNorge/CompanyUserList';
import UserCard from 'ui/domain/Users/UserCard';
import { MentorNetworkMentorship } from 'apis/MentorAPI/types';
import UserPreview from 'domain/users/UserProfile/UserPreview';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notify } from 'actions/snackbar';
import { TinyCommunity } from 'types/company/community';

interface Props {
  onClose: () => void;
  community: TinyCommunity;
  companyId: number;
  companyName: string;
  mentorship: MentorNetworkMentorship;
  onAssign: () => void;
}

export default function MentorAssignmentDialog(props: Props) {
  const { mentorship } = props;
  const [isAssigning, setAssigning] = useState<number[]>([]);
  const [, setSelectedUser] = useState<number>();
  const [, setErrors] = useState<string[]>([]);

  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      notify,
    },
    dispatch,
  );

  const add = (userId: number) => {
    setAssigning(isAssigning => [...isAssigning, userId]);
    setSelectedUser(userId);
    communitiesApi.mentorNetwork.mentorships.mentorAssignments
      .add(props.community.slug, props.companyId, props.mentorship.id, userId)
      .then(() => {
        setAssigning(isAssigning => isAssigning.filter(id => id !== userId));
        setSelectedUser(undefined);
        props.onClose();
        props.onAssign();
      })
      .catch(e => {
        setAssigning(isAssigning => isAssigning.filter(id => id !== userId));
        setSelectedUser(undefined);
        setErrors([i18n('en').errors.tryAgain]);
        actions.notify('error', `Something went wrong when trying to assign mentor: ${e.data?.message ?? ''}`);
      });
  };

  const [userPreviewId, setUserPreviewId] = useState<number | undefined>(undefined);

  return (
    <FullscreenDialog open={true} onClose={props.onClose}>
      <Title onClose={props.onClose}>Assign a mentor to {props.companyName}</Title>
      <Content>
        <CompanyUserList
          community={props.community}
          defaultFilter="mentor"
          defaultMentorExpertise={mentorship.mentorAskExpertise}
          renderUser={user => (
            <UserCard
              user={user}
              onClick={() => setUserPreviewId(user.id)}
              actions={
                <>
                  <Button kind="secondary" onClick={() => add(user.id)} isLoading={isAssigning.includes(user.id)}>
                    Assign
                  </Button>
                  <Dialog open={userPreviewId === user.id} onClose={() => setUserPreviewId(undefined)}>
                    <DialogContent>
                      <UserPreview cwUserId={user.cwUserId}></UserPreview>
                    </DialogContent>
                  </Dialog>
                </>
              }
            ></UserCard>
          )}
        />
      </Content>
    </FullscreenDialog>
  );
}
