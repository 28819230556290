import { OnboardingPart, OnboardingStep, Step } from 'apis/OnboardingAPI';
import { updateInList } from 'util/arrayUtils';

export function getChecklistStepText(stepId: OnboardingStep) {
  switch (stepId) {
    case 'CompanyInfo':
      return 'Add general company information';
    case 'IndustriesAndStage':
      return 'Add industry and stage';
    case 'CompanyMission':
      return 'Add company mission';
    case 'Kpis':
      return 'Add your KPIs';
    case 'CompanyBranding':
      return 'Upload company logo and banner image';
    case 'ProblemSolutionUnfairAdvantage':
      return 'Add executive summary';
    case 'PitchDeck':
      return 'Upload your pitch deck';
    case 'InviteYourStakeholders':
      return 'Invite shareholders';
    case 'PostFirstUpdate':
      return 'Post first company update';
    case 'InvestmentPreferences':
      return 'Add investment preferences';
    case 'InvestorType':
      return 'Add investor type';
    case 'InvestmentRange':
      return 'Add preferred range & capacity';
    case 'CompanyMatching':
      return 'Follow a few companies to get started (optional)';
    case 'CommunityBranding':
      return 'Upload community logo';
    case 'CommunityDescription':
      return 'Write a description of your community';
    case 'InviteYourTeam':
      return 'Invite your team';
    case 'InviteMembers':
      return 'Invite members';
    case 'ChooseYourRole':
      return 'Choose your role';
    case 'UserProfile':
      return 'Add user information';
    case 'BasicProfile':
      return 'Complete your basic profile';
    case 'IndustryAndBusinessFocus':
      return 'Add your industry and business focus';
    case 'ProductAndFundingStage':
      return 'Add your product and funding stage';
    case 'OrganizationType':
      return 'Add your organization type';
    case 'OrganizationIndustries':
      return 'Add your organization industries';
    case 'Services':
      return 'Add your services';
    case 'OrganizationProfile':
      return 'Complete your organization profile';
    case 'InviteOrganizationMembers':
      return 'Invite your team';
    case 'SebLegal':
      return 'Legal';
    case 'Interests':
      return 'What are your interests?';
    case 'BoardPosition':
      return 'Are you open for requests regarding board positions?';
    default:
      return 'Unknown step';
  }
}

export const setStepCompleted = (prev: OnboardingPart, step: OnboardingStep) => ({
  ...prev,
  steps: updateInList(
    prev.steps,
    item => item.step === step,
    () => ({ step, completenessPercentage: 100, isCompleted: true }),
  ),
});

export function calculateCompletedPercentage(onboardingSteps: Step[]) {
  return Math.ceil(
    onboardingSteps.map(it => it.completenessPercentage).reduce((a, b) => a + b, 0) / onboardingSteps.length,
  );
}
