import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { AllIndustries, BusinessDomain, Industry } from 'types';
import { MentorExpertise, mentorExpertise } from 'apis/MentorAPI/types';
import { DetailedUserProfile } from 'types/user';
import BusinessDomainChip from 'ui/domain/Chips/BusinessDomainChip';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import SkillChip from 'ui/domain/Chips/SkillChip';
import Button from 'ui/elements/buttons/Button';
import FormRow from 'ui/elements/form/FormRow';
import Label from 'ui/elements/form/Label';
import MultiSelect from 'ui/elements/form/Select/MultiSelect';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';

interface Props {
  userprofile: DetailedUserProfile;
  saving: boolean;
  industries: AllIndustries;
  businessDomains: BusinessDomain[];
}

interface DispatchProps {
  save: (user: { mentorExpertise: MentorExpertise[]; industries: number[]; businessDomains: number[] }) => void;
}

interface ExpertiseFormValues {
  mentorExpertise: { value: MentorExpertise; label: string }[];
  industries: Industry[];
  businessDomains: BusinessDomain[];
}

type AllProps = Props & DispatchProps & FormikProps<ExpertiseFormValues>;

const mentorExpertises = Object.keys(mentorExpertise).map((key: MentorExpertise) => ({
  value: key,
  label: mentorExpertise[key],
}));

function ExpertiseForm(props: AllProps) {
  const { saving } = props;

  return (
    <>
      <Content>
        <Form>
          <FormRow className="u-section-spacing-bottom">
            <Label htmlFor="industries">Industries</Label>
            <MultiSelect
              id="industries"
              name="industries"
              placeholder="Select industries where you can contribute"
              error={props.errors.industries}
              touched={!!props.touched.industries}
              values={props.values.industries}
              options={props.industries.parents}
              onChange={value => props.setFieldValue('industries', value)}
              renderValue={IndustryChip}
              getOptionLabel={option => option.name}
            />
          </FormRow>
          <FormRow className="u-section-spacing-bottom">
            <Label htmlFor="businessDomains">Business domains</Label>
            <MultiSelect
              id="businessDomains"
              name="businessDomains"
              placeholder="Select business domains where you can contribute"
              error={props.errors.businessDomains}
              touched={!!props.touched.businessDomains}
              values={props.values.businessDomains}
              options={props.businessDomains}
              onChange={values => props.setFieldValue('businessDomains', values)}
              getOptionLabel={option => option.name}
              renderValue={BusinessDomainChip}
            />
          </FormRow>
          <FormRow className="u-content-spacing-bottom">
            <Label htmlFor="mentorExpertise">Areas of mentor contribution</Label>
            <MultiSelect
              id="mentorExpertise"
              name="mentorExpertise"
              placeholder="Select mentor expertise"
              error={props.errors.mentorExpertise}
              touched={!!props.touched.mentorExpertise}
              values={props.values.mentorExpertise}
              options={mentorExpertises}
              onChange={value => props.setFieldValue('mentorExpertise', value)}
              renderValue={SkillChip}
              getOptionLabel={option => option.label}
            />
          </FormRow>
        </Form>
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={() => props.handleSubmit()} isLoading={saving}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
}

export default withFormik<Props & DispatchProps, ExpertiseFormValues>({
  mapPropsToValues: props => ({
    mentorExpertise:
      props.userprofile.mentorExpertise.map(expertise => ({ value: expertise, label: mentorExpertise[expertise] })) ??
      [],
    industries: props.userprofile.industries ?? [],
    businessDomains: props.userprofile.businessDomains ?? [],
  }),
  validationSchema: Yup.object().shape({
    mentorExpertise: Yup.array().min(1, 'Please select at least one area you can contribute as a mentor'),
    industries: Yup.array<Industry>().min(1, 'Please select at least one industry'),
    businessDomains: Yup.array<BusinessDomain>().min(1, 'Please select at least one business domain'),
  }),
  handleSubmit: (values, { props }) => {
    const data = {
      mentorExpertise: values.mentorExpertise,
      industries: values.industries,
      businessDomains: values.businessDomains,
    };
    props.save({
      industries: values.industries.map(industry => industry.id),
      businessDomains: values.businessDomains.map(businessDomain => businessDomain.id),
      mentorExpertise: data.mentorExpertise.map(expertise => expertise.value),
    });
  },
})(ExpertiseForm);
