import { ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import React from 'react';
import EditIcon from 'ui/elements/icons/EditIcon';
import { yearMonthDate } from 'util/dateUtils';
import { TopicDTO } from 'types/company/community';
import IconButton from 'ui/elements/icons/IconButton';

interface Props {
  topic: TopicDTO;
  onClick: (topicId: TopicDTO['id']) => void;
  canEdit: boolean;
  openEditDialog: (topic: TopicDTO) => void;
}

export default function TopicList(props: Props) {
  return (
    <div key={`discussion-${props.topic.id}`}>
      <ListItem onClick={() => props.onClick(props.topic.id)} component="button">
        <ListItemText
          disableTypography
          primary={
            <div className="u-half-spacing-bottom">
              <p className="text-h4">{props.topic.title}</p>
            </div>
          }
          secondary={
            props.topic.createdAt ? (
              <p className="text-metadata">Created {yearMonthDate(props.topic.createdAt)}</p>
            ) : null
          }
        />
        {props.canEdit && (
          <ListItemSecondaryAction>
            <IconButton color="indigo" hoverColor="blue" onClick={() => props.openEditDialog(props.topic)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </div>
  );
}
