import Hidden from '@mui/material/Hidden';
import React from 'react';
import { SelfUserProfile } from 'types/user';
import collaboratorsIllustration from 'ui/illustrations/collaborators.svg';
import Bio from './Bio';
import ContactInfo from './ContactInfo';
import Expertise from './Expertise';
import Dialog, { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Wizard from 'ui/modules/wizards/Wizard';
import Button from 'ui/elements/buttons/Button';
import { MentorOnboardingStage } from './util';
import { TinyCommunity } from 'types/company/community';

interface Props {
  community: TinyCommunity;
  userProfile: SelfUserProfile;
  setIntroIsRead: (value: boolean) => void;
  setAlmostDoneIsRead: React.Dispatch<React.SetStateAction<boolean>>;
  completedStages: MentorOnboardingStage[];
}

export default function MentorOnboardingWidget(props: Props) {
  const { userProfile, community } = props;

  return (
    <Dialog open={true} onClose={() => {}} backdropColor="medium">
      <Wizard
        completedStages={props.completedStages}
        stages={[
          {
            name: 'Introduction',
            title: 'Build your mentor profile',

            content: ({ onComplete }) => (
              <>
                <Content className="u-flex" paddingY="bottom">
                  <div>
                    <p className="text-body u-half-spacing-bottom">
                      Welcome to {community.name}.<br />
                      In order to match you with mentee companies, please provide us with some information about
                      yourself.
                    </p>
                    <p className="text-body">
                      When you have provided the required information in the forms below, you will be matched with
                      suitable mentee companies.
                    </p>
                  </div>
                  <Hidden smDown>
                    <img
                      style={{ maxWidth: '15rem' }}
                      className="u-section-padding-x"
                      src={collaboratorsIllustration}
                    ></img>
                  </Hidden>
                </Content>
                <DialogActions>
                  <Button
                    onClick={() => {
                      props.setIntroIsRead(true);
                      onComplete();
                    }}
                    kind="primary"
                  >
                    Continue
                  </Button>
                </DialogActions>
              </>
            ),
          },
          {
            name: 'Contact info',
            title: 'Build your mentor profile',

            content: ({ onComplete }) => <ContactInfo userProfile={userProfile} onComplete={onComplete} />,
          },
          {
            name: 'Expertise',
            title: 'Build your mentor profile',

            content: ({ onComplete }) => <Expertise userprofile={userProfile} onComplete={onComplete} />,
          },
          {
            name: 'Bio',
            title: 'Build your mentor profile',
            content: ({ onComplete }) => <Bio userprofile={userProfile} onComplete={onComplete} />,
          },
        ]}
        debrief={{
          name: 'All done',
          title: 'Build your mentor profile',

          content: ({ onComplete }) => (
            <>
              <Content>
                <p className="text-body text-large">
                  You have done everything you need in order to be assigned a mentee company - you will be notified by
                  email when it happens.
                </p>
              </Content>
              <DialogActions>
                <Button
                  className="u-section-spacing-top"
                  onClick={() => {
                    props.setAlmostDoneIsRead(true);
                    onComplete();
                  }}
                  kind="primary"
                >
                  Okay, Thank you!
                </Button>
              </DialogActions>
            </>
          ),
        }}
      />
    </Dialog>
  );
}
