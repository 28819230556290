import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CloseCircleIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m9.2 9.2 5.6 5.6m0-5.6-5.6 5.6" />
      <path d="M22 12a10 10 0 1 0-20 0 10 10 0 0 0 20 0Z" />
    </SvgIcon>
  );
}
