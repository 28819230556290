import React from 'react';
import CompanyLogo from 'ui/elements/CompanyLogo';
import { getOrUndefined } from 'util/resource';
import Avatar from 'ui/elements/avatars/Avatar';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { CommunityLisItemDetailed } from 'types/company/community';

export function MyCommunityMembershipStatus({
  community,
  style,
}: {
  community: CommunityLisItemDetailed;
  style?: React.CSSProperties;
}) {
  const founderOf = community.myCompanyMemberships.find(m => m.visibility === 'Visible')?.company;
  const pendingAsCompany = community.myCompanyMemberships.find(m => m.visibility === 'Pending')?.company;

  const roles = community.roles.filter(r => r !== 'company_admin_in_community');

  const { resource: userProfileResource } = useSelfUserProfile();

  return (
    <div style={style} className="u-flex u-flex-align-center u-flex--gap-half text-grey text-micro">
      {founderOf ? (
        <>
          <CompanyLogo size={24} company={founderOf}></CompanyLogo>
          <span>{founderOf.name} is a member</span>
        </>
      ) : pendingAsCompany ? (
        <>
          <CompanyLogo size={24} company={pendingAsCompany}></CompanyLogo>
          <span>{pendingAsCompany.name} is pending approval</span>
        </>
      ) : roles.length > 0 ? (
        <>
          <Avatar
            size={24}
            imageUrl={getOrUndefined(userProfileResource)?.imageUrl}
            userName={getOrUndefined(userProfileResource)?.name}
          ></Avatar>
          <span>You are {roles.includes('company_master') ? 'an admin' : 'a member'}</span>
        </>
      ) : (
        <>
          <Avatar
            size={24}
            imageUrl={getOrUndefined(userProfileResource)?.imageUrl}
            userName={getOrUndefined(userProfileResource)?.name}
          ></Avatar>
          <span>You are pending approval</span>
        </>
      )}
    </div>
  );
}
