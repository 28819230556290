import React, { useState } from 'react';
import { CommunityInviteAs, CommunityInviteDetails } from 'types/company/community';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { communityUrls } from 'urls';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { halfSpacing } from 'ui/theme/themeConstants';
import useResource from 'util/resource/useResource';
import Resource from 'util/resource/Resource';
import CopyButton from 'ui/elements/CopyButton';
import { EmailInput } from 'domain/shared/EmailInput';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${halfSpacing};
  ${bluePlanetTheme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

export default function InviteDialog({ communitySlug, onClose }: { communitySlug: string; onClose: () => void }) {
  const [emailsToInvite, setEmailsToInvite] = useState<{ email: string; invitedAs: CommunityInviteAs }[]>([]);
  const setEmailsByInvitedAs = (emails: string[], invitedAs: CommunityInviteAs) => {
    setEmailsToInvite([
      ...emailsToInvite.filter(e => e.invitedAs !== invitedAs),
      ...emails.map(email => ({ email, invitedAs })),
    ]);
  };

  const { resource } = useResource<CommunityInviteDetails>(
    communityAPIUrls.invitations.getInviteToken(communitySlug, 'NotSpecified'),
  );

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <Title onClose={onClose}>Invite new members and build a stronger community</Title>
      <Content>
        <Wrapper>
          <EmailInput
            setValidEmails={emails => setEmailsByInvitedAs(emails, 'NotSpecified')}
            validEmails={emailsToInvite.filter(email => email.invitedAs === 'NotSpecified').map(e => e.email)}
            autoFocus
          />
          <div className="u-flex-shrink-0">
            <Resource resource={resource}>
              {inviteDetails => (
                <CopyButton
                  minHeight={44} // make the button the same height as the text field
                  minWidth={116} // avoid jumping when the button changes state
                  displayString="Copy link"
                  copyString={`${location.origin}${communityUrls.join(communitySlug, inviteDetails.inviteCode)}`}
                  kind="secondary"
                />
              )}
            </Resource>
          </div>
        </Wrapper>
        <div className="text-metadata u-quarter-spacing-top">
          Members can choose their role. For specific role invitations, go to{' '}
          <Link to={communityUrls.manageMembers.invite(communitySlug)} className="text-link-small">
            user management.
          </Link>
        </div>
      </Content>
      <DialogActions>
        <ButtonList>
          <Button
            kind="primary"
            onClick={() => {
              if (emailsToInvite.length === 0) {
              } else {
                onClose();
              }
            }}
          >
            Invite
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonList>
      </DialogActions>
    </Dialog>
  );
}
