import React from 'react';
import { UserProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import ClickableCard from 'ui/views/cards/ClickableCard';

interface Props {
  user: UserProfile;
  onClick?: () => void;
  href?: string;
  actions?: Children;
}

export default function UserCard(props: Props) {
  const { user, href, onClick, actions } = props;
  return (
    <ClickableCard
      title={<span>{user.name}</span>}
      padding="half"
      href={href}
      onClick={onClick}
      avatar={<Avatar imageUrl={user.imageUrl} userName={user.name} />}
      actions={actions}
    >
      <span className="text-metadata">
        {user.position}
        {user.employer && user.position ? ' @ ' : ''}
        <span>{user.employer}</span>
      </span>
    </ClickableCard>
  );
}
