import React from 'react';
import { reloadAllUpdates } from './useCommunityUpdates';
import { TinyCommunity } from 'types/company/community';
import { useLocation } from 'react-router';
import { BODY_ID } from 'ui/views/layouts/BaseLayout/ScrollableBody';
import PostUpdateDialog from './PostUpdateDialog';
import useCommunityRole from 'hooks/useCommunityRole';
import { canPostCommunityUpdate } from './utils';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import Resource from 'util/resource/Resource';

export default function PostUpdate({
  community,
  updatesRef,
}: {
  community: TinyCommunity;
  updatesRef: React.RefObject<HTMLDivElement>;
}) {
  const { myCompanyMemberships, hasScope, roles } = useCommunityRole(community.slug);
  const canPostUpdate = canPostCommunityUpdate(hasScope, roles);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isPostingUpdate = params.get('writeUpdate') === '1';
  const { resource: communitySummary } = useCommunitySummary(community.slug);

  const scrollToUpdatesSection = () => {
    if (updatesRef.current) {
      const updatesPosition = updatesRef.current.offsetTop;
      const body = document.getElementById(BODY_ID);
      body?.scrollTo({ top: updatesPosition - 30, behavior: 'smooth' });
    }
  };

  return (
    <>
      {canPostUpdate && (
        <Resource resource={communitySummary}>
          {({ configuration }) => (
            <div data-intercom-target="community-post-updates" className="u-content-spacing-bottom">
              <PostUpdateDialog
                initiallyOpen={isPostingUpdate}
                myCompanyMemberships={myCompanyMemberships.filter(m => m.visibility === 'Visible').map(m => m.company)}
                communityConfiguration={configuration}
                hasScope={hasScope}
                community={community}
                onUpdatePosted={() => {
                  reloadAllUpdates(community.id);
                  scrollToUpdatesSection();
                }}
              />
            </div>
          )}
        </Resource>
      )}
    </>
  );
}
