import Resource from 'util/resource/Resource';
import LabelChip from 'ui/domain/Chips/LabelChip';
import React, { useState } from 'react';
import EditAction from 'ui/elements/actions/EditAction';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import ChipList from 'ui/elements/Chip/ChipList';
import useCommunityRole from 'hooks/useCommunityRole';
import CommunityLabelsDialog from './CommunityLabelsDialog';
import { useCommunityCompanyLabels } from 'apis/CompanyAPI/communities/useCommunityLabels';

interface Props {
  communitySlug: string;
  communityId: number;
  companyId: number;
  companyName: string;
}

export default function CommunityCompanyLabels(props: Props) {
  const { communityId, companyId } = props;
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const access = useCommunityRole(props.communitySlug);
  const hasWriteAccess = access.hasScope('Write', 'Community');
  const canSeeLabels = access.hasScope('Read', 'Community.Labels');
  const { resource: labels, mutate: refreshLabels } = useCommunityCompanyLabels(canSeeLabels, communityId, companyId);

  return (
    <Resource resource={labels} renderLoading={() => <span />}>
      {labels =>
        labels.values.length > 0 || hasWriteAccess ? (
          <>
            <SectionHeading addMobileMargin heading="Community labels">
              {hasWriteAccess && (
                <>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      setIsEditDialogOpen(true);
                    }}
                  >
                    <EditAction tooltip="Manage labels" />
                  </button>
                  {isEditDialogOpen && (
                    <CommunityLabelsDialog
                      labels={labels.values}
                      onClose={() => setIsEditDialogOpen(false)}
                      companyId={companyId}
                      companyName={props.companyName}
                      communitySlug={props.communitySlug}
                      refresh={() => refreshLabels()}
                    />
                  )}
                </>
              )}
            </SectionHeading>
            <Card>
              {labels.values.length > 0 ? (
                <div className="u-flex u-flex-align-center u-flex--wrap">
                  <ChipList>
                    {labels.values.map(l => (
                      <LabelChip label={l.name} key={l.id} color={l.color} />
                    ))}
                  </ChipList>
                </div>
              ) : null}
            </Card>
          </>
        ) : null
      }
    </Resource>
  );
}
