import { useMediaQuery } from '@mui/material';
import { ContentAPI } from 'apis/ContentAPI';
import { companyUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { useCompanyChecklistSessionStorage } from 'apis/OnboardingAPI';
import config from 'config';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';
import UpdateIdeas, { getHelpOptions } from 'domain/Updates/CompanyUpdate/UpdateIdeas';
import { useUpdateForm } from 'domain/Updates/shared/UpdateForm/useUpdateForm';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import Dropzone from 'domain/shared/Files/Upload/Dropzone';
import useNotify from 'hooks/useNotify';
import React, { lazy, useState } from 'react';
import { CompanyProfile } from 'types/company';
import { CommunityListItem } from 'types/company/community';
import Loading from 'ui/elements/Loading';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { companyUserMentionSearchFunction } from 'ui/elements/form/RichTextEditor/utils';
import { bluePlanetTheme } from 'ui/theme';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import AttachmentsCarousel from 'domain/Updates/shared/UpdateForm/AttachmentsCarousel';

const RichTextEditor = lazy(() => import('ui/elements/form/RichTextEditor'));

export default function PostUpdateForm({
  companyProfile,
  communities,
  onBack,
  onClose,
  onComplete,
}: {
  onBack: () => void;
  onClose: () => void;
  onComplete: () => void;
  companyProfile: CompanyProfile;
  communities: CommunityListItem[];
}) {
  const [showForm, setShowForm] = useState(false);

  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyProfile.id);

  const { markStepAsCompleted } = useCompanyChecklistSessionStorage(companyProfile.slug);

  const contentAPI = ContentAPI(`${config.CONTENT_API_URL}/companies/${companyProfile.id}`);
  const api = companyUpdateAPI(companyProfile.id);

  const [postUpdate, isPostingUpdate] = useLazyResource(
    (data: CompanyUpdateFormValues) => api.create({ ...data, visibility: 'Public' }),
    {
      onSuccess: () => {
        setLastUpdated(new Date());

        markStepAsCompleted('PostFirstUpdate');
        onComplete();
      },
      onFailure(e) {
        notify('error', e || 'Something went wrong. Please try again');
      },
    },
  );

  const {
    onAttachmentRemoved,
    onSubmit,
    onUploadFile,
    formik,
    newAttachments,
    removeFile,
    onCancel: onCancel,
  } = useUpdateForm({
    onSave: postUpdate,
  });

  const mentionSearchFunction = (searchQuery: string) =>
    companyUserMentionSearchFunction(searchQuery, 'Public', companyProfile.id);

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const getImageUploadUrl = (filename: string) => contentAPI.images.getCompanyUploadUrl(companyProfile.id, filename);

  return (
    <>
      <Content>
        <UpdateIdeas
          onSelect={() => setShowForm(true)}
          showCustomInput
          helpOptions={getHelpOptions(communities[0]?.details)}
        />

        <React.Suspense fallback={<Loading spacing="content-spacing" position="center" />}>
          {showForm && (
            <Dropzone disableOnClick onDrop={onUploadFile} tabIndex={-1}>
              <RichTextEditor
                toolbarType="none"
                name="content"
                deltaName="structuredContent"
                content={formik.values.content}
                delta={formik.values.structuredContent || {}}
                linkPreview={formik.values.linkPreview}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
                validateField={formik.validateField}
                handleChange={formik.handleChange}
                error={formik.touched.content ? formik.errors.content : undefined}
                getImageUploadUrl={getImageUploadUrl}
                mentionSearchFunction={mentionSearchFunction}
                onUploadFile={onUploadFile}
                autoFocus
                attachmentSection={
                  <AttachmentsCarousel
                    attachmentIdList={formik.values.attachments}
                    existingAttachments={[]}
                    newAttachments={newAttachments}
                    downloadAttachment={docId => api.attachments.download('', docId)}
                    removeExistingAttachment={onAttachmentRemoved}
                    removeNewAttachment={removeFile}
                  />
                }
              />
            </Dropzone>
          )}
        </React.Suspense>
        {!isMobile && (
          <div className="u-content-spacing-left text-metadata">
            Who will see this?{' '}
            <span style={{ borderBottom: '1px solid grey' }}>All Crowdworks users visiting your company profile</span>
          </div>
        )}
        {isMobile && (
          <div className="u-half-spacing-top text-metadata">
            Who will see this? All Crowdworks users visiting your company profile
          </div>
        )}
      </Content>
      <DialogActions align="space-between">
        <ButtonList>
          <Button isLoading={isPostingUpdate} onClick={onSubmit} kind="primary">
            Post update
          </Button>
          <Button onClick={() => onCancel(onBack)} disabled={isPostingUpdate} kind="primary" color="greyLight">
            Back
          </Button>
        </ButtonList>

        <Button onClick={() => onCancel(onClose)} kind="tertiary" color="grey" disabled={isPostingUpdate}>
          Pick it up later
        </Button>
      </DialogActions>
    </>
  );
}
