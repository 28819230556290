import { asCompanyAuthor, asUserAuthor, AuthorImage, nameOf } from 'domain/shared/author';
import React, { useState, useEffect } from 'react';
import { ICompany } from 'types/company';
import { UpdateVisibility } from 'types/content/update';
import TextField from 'ui/elements/form/TextField';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import SelectCompany from './SelectCompany';
import { getOrUndefined } from 'util/resource';
import { useHistory, useLocation } from 'react-router-dom';
import useNotify from 'hooks/useNotify';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { CommunityConfiguration, TinyCommunity } from 'types/company/community';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { invalidate } from 'hooks/useSWR';
import UpdateForm from '../UpdateForm';
import useLazyResource from 'util/resource/useLazyResource';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import Dialog, { Title } from 'ui/views/dialogs/Dialog';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';
import { HasScope } from 'hooks/useCommunityRole';
import { SelfUserProfile } from 'types/user';

interface Props {
  initiallyOpen?: boolean;
  myCompanyMemberships: ICompany[];
  community: TinyCommunity;
  onUpdatePosted: () => void;
  hasScope: HasScope;
  communityConfiguration: CommunityConfiguration;
}
export type PostAs =
  | { type: 'user'; user: SelfUserProfile }
  | { type: 'company'; company: ICompany }
  | { type: 'community'; community: TinyCommunity };

export default function PostUpdateDialog({
  initiallyOpen,
  myCompanyMemberships,
  community,
  onUpdatePosted,
  hasScope,
  communityConfiguration,
}: Props) {
  const location = useLocation();
  const history = useHistory();
  const notify = useNotify();
  const queryParams = new URLSearchParams(location.search);

  function removeUrlParam(param: string) {
    if (queryParams.has(param)) {
      queryParams.delete(param);
      history.replace({
        search: queryParams.toString(),
      });
    }
  }

  const { resource: userResource } = useSelfUserProfile();
  const user = getOrUndefined(userResource);

  const canPostAsSeveral =
    (hasScope('Write', 'Update', 'Admin') ? 1 : 0) +
      (hasScope('Write', 'Update', 'Self') ? 1 : 0) +
      (myCompanyMemberships.length > 0 ? 1 : 0) >
    1;

  const initialCompanyOrCompanyToPostAs: PostAs | undefined = canPostAsSeveral
    ? undefined
    : myCompanyMemberships.length === 1
      ? { type: 'company', company: myCompanyMemberships[0] }
      : hasScope('Write', 'Update', 'Admin')
        ? { type: 'community', community }
        : hasScope('Write', 'Update', 'Self') && user
          ? { type: 'user', user }
          : undefined;

  const [postingAs, setPostingAs] = useState<PostAs | undefined>(initialCompanyOrCompanyToPostAs);

  const [isActive, setActive] = useState(false);
  useEffect(() => {
    if (initiallyOpen) {
      setActive(true);
    }
  }, [initiallyOpen]);

  const onClose = () => {
    setPostingAs(initialCompanyOrCompanyToPostAs);
    setActive(false);

    // To make the checklist on the company page open the update dialog after closing it, we need to reset the url
    removeUrlParam('writeUpdate');
  };

  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);

  const [createUpdate, isSaving] = useLazyResource(
    (dto: CompanyUpdateFormValues & { visibility: UpdateVisibility }) => {
      if (!postingAs || (postingAs.type === 'company' && !postingAs.company)) {
        throw new Error('Posting as company but no company is selected');
      }
      return communityUpdateAPI(community.id).create({
        ...dto,
        postedBy:
          postingAs.type === 'company'
            ? { type: 'company', companyId: postingAs.company?.id }
            : postingAs.type === 'user'
              ? { type: 'user', cwUserId: postingAs.user.cwUserId }
              : { type: 'community' },
      });
    },
    {
      onSuccess: () => {
        onClose();
        onUpdatePosted();
        invalidate(userDashboardKey);
        notify('success', 'Update posted!');
      },
      onFailure: () => {
        notify('error', `Something went wrong when trying to post update`);
      },
    },
  );

  const author =
    postingAs && postingAs.type === 'company'
      ? asCompanyAuthor(userProfile, postingAs.company)
      : postingAs && postingAs.type === 'community'
        ? asCompanyAuthor(userProfile, community)
        : asUserAuthor(userProfile);

  if (isActive && postingAs) {
    return (
      <UpdateForm
        author={author}
        selectedVisibility="CommunityMembers"
        onSave={(dto, visibility) => createUpdate({ ...dto, visibility })}
        isSaving={isSaving}
        onClose={onClose}
        community={community}
        postedBy={postingAs}
        communityConfiguration={communityConfiguration}
      />
    );
  }

  function closeSelectCompany() {
    removeUrlParam('writeUpdate');
    setActive(false);
  }

  if (isActive && !postingAs) {
    return (
      <Dialog open onClose={closeSelectCompany}>
        <Title onClose={closeSelectCompany}>Post community update</Title>
        <SelectCompany
          canPostAsCommunity={hasScope('Write', 'Update', 'Admin')}
          canPostAsUser={hasScope('Write', 'Update', 'Self')}
          community={community}
          setChosenCompany={setPostingAs}
          myCompanyMemberships={myCompanyMemberships}
          user={user}
        />
      </Dialog>
    );
  }

  return (
    <CardStack>
      <Card>
        <div className="u-flex">
          <AuthorImage author={author} />
          <TextField
            onFocus={() => setActive(true)}
            placeholder={`What would you like to share, ${nameOf(author)?.split(' ')[0]}?`}
            sx={{
              '.MuiInput-root': {
                borderColor: 'transparent',
              },
            }}
          />
        </div>
      </Card>
    </CardStack>
  );
}
