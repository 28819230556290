import React, { useState } from 'react';

import Avatar from 'ui/elements/avatars/Avatar';
import Button from 'ui/elements/buttons/Button';
import Card from 'ui/views/cards/Card';

import useProgressHandler, { ProgressHandler } from 'hooks/useProgressHandler';

import { ReplyContentDTO, TopicDTO } from 'types/company/community';
import TextField from 'ui/elements/form/TextField';
import { getOrUndefined } from 'util/resource';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

interface Props {
  onSubmit: (afterCompleted: () => void, reply: ReplyContentDTO, progressHandler: ProgressHandler) => Promise<void>;
  topicTitle: TopicDTO['title'];
}

const emptyReplyContentDTO = {
  htmlContent: '',
  structuredContent: '',
} as ReplyContentDTO;

export default function ReplyToTopic(props: Props) {
  const [reply, setReply] = useState<ReplyContentDTO>(emptyReplyContentDTO);

  const [replying, progressHandler] = useProgressHandler();
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);

  return (
    <Card className="u-content-spacing-bottom u-content-spacing-top">
      <div className="u-flex u-half-spacing-bottom">
        {userProfile && (
          <Avatar className="u-content-spacing-right" userName={userProfile.name} imageUrl={userProfile.imageUrl} />
        )}
        <h3 className="text-h3">{userProfile && userProfile.name}</h3>
      </div>
      <TextField
        inputProps={{
          style: {
            fontWeight: 300,
          },
        }}
        value={reply.htmlContent}
        onChange={e => {
          const val = e.target.value;
          setReply({
            htmlContent: val,
            structuredContent: val,
          });
        }}
        minRows={3}
        maxRows={12}
        placeholder={`Reply to "${props.topicTitle}"`}
        fullWidth
      />
      <div className="u-content-spacing-top">
        <Button
          onClick={() => props.onSubmit(() => setReply(emptyReplyContentDTO), reply, progressHandler)}
          isLoading={replying}
          kind="primary"
          disabled={reply.structuredContent.length === 0}
        >
          Reply
        </Button>
      </div>
    </Card>
  );
}
