import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import { useCommunityTopics } from 'apis/CompanyAPI/communities/useCommunityTopics';
import React, { useEffect, useState } from 'react';
import { CommunityContentTopic } from 'types/company/community';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import { getOrElse } from 'util/resource';
import Resource from 'util/resource/Resource';

interface Props {
  communityId: number;
  onChange: (topicId: string) => void;
}

export function CommunityTopicListWrapper(props: Props) {
  const { resource: communitySummary } = useCommunitySummary(props.communityId);

  return (
    <Resource resource={communitySummary}>
      {communitySummary =>
        communitySummary.configuration.updateTopicsIsEnabled ? (
          <CommunityTopicList communityId={props.communityId} onChange={props.onChange} />
        ) : null
      }
    </Resource>
  );
}

export default function CommunityTopicList(props: Props) {
  const { resource: communityContentTopicList } = useCommunityTopics(props.communityId);
  const [selectedTopic, setSelectedTopic] = useState<string>();

  const topicList = getOrElse(communityContentTopicList, { values: [] });

  function handleSelectTopic(topic: CommunityContentTopic) {
    setSelectedTopic(topic.id);
    props.onChange(topic.id);
  }

  const getSelectedTopic = () => {
    const topic = topicList.values.find(t => t.id === selectedTopic);
    return topic ? [{ ...topic, icon: topic.emoji }] : [];
  };

  useEffect(() => {
    if (communityContentTopicList.state === 'fetched') {
      handleSelectTopic(communityContentTopicList.resource.values[0]);
    }
  }, [communityContentTopicList.state]);

  return (
    <div className="u-half-spacing-bottom">
      <p className="text-weight-bold text-small u-quarter-spacing">Choose your topic</p>
      <Resource resource={communityContentTopicList}>
        {topicList => (
          <SelectCloud
            options={topicList.values.map(t => ({
              name: t.name,
              id: t.id,
              isDefault: t.isDefault,
              icon: t.emoji,
            }))}
            values={getSelectedTopic()}
            onSelect={t => handleSelectTopic(t)}
            onRemove={() => {}}
          />
        )}
      </Resource>
    </div>
  );
}
