import Divider from '@mui/material/Divider';
import Resource from 'util/resource/Resource';
import useDialogHandler from 'hooks/useDialogHandler';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import { createDiscussionTopic as create, editDiscussionTopic as edit, fetchCommunityDiscussions } from '../actions';
import DiscussionListContainer from 'domain/communities/CompanyDialog/Discussions/components/DiscussionListContainer';
import curry from 'ramda/src/curry';
import React, { useState } from 'react';
import { PaginatedResult } from 'types';
import { TinyCommunity, TopicDTO } from 'types/company/community';
import { getOrUndefined } from 'util/resource';
import AddAction from 'ui/elements/actions/AddAction';
import Button from 'ui/elements/buttons/Button';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import i18n from 'util/i18n';
import { addItem, updateInList } from 'util/paginationUtils';
import CreateDiscussionTopicDialog from './CreateDiscussionTopicDialog';
import { UserProfile } from 'types/user';
import { List } from 'types/api';
import useNotify from 'hooks/useNotify';
import DiscussionDialog from '../DiscussionDialog';
import useCommunityRole from 'hooks/useCommunityRole';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

interface Props {
  community: TinyCommunity;
  companyId: number;
  companyName: string;
  dealLeads: List<UserProfile>;
}

export default function DiscussionTopics({ community, companyId, companyName, dealLeads }: Props) {
  const dialogHandler = useDialogHandler(false);
  const discussionListDialogHandler = useDialogHandler(false);
  const DISCUSSIONS_TO_SHOW = 3;

  const [discussionsState, , setDiscussionsState, onSeeMore] = usePaginatedResourceLegacy(
    async paginationOptions => await fetchCommunityDiscussions(community.id, companyId, paginationOptions),
    [community.id, companyId],
  );

  const notify = useNotify();

  const access = useCommunityRole(community.slug);
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const isDealLead = dealLeads.values.some(dl => dl.cwUserId === userProfile?.cwUserId);

  const canCreateTopic = isDealLead || access.hasScope('Write', 'Community');

  const [activeTopic, setActiveTopic] = useState<string | undefined>();

  const updateDiscussionTopic = (discussions: PaginatedResult<TopicDTO>, topic: TopicDTO) =>
    discussionsState.state === 'fetched' &&
    setDiscussionsState({
      state: 'fetched',
      resource: updateInList(
        discussions,
        t => t.id === topic.id,
        () => topic,
      ),
      isValidating: false,
    });

  return (
    <>
      <SectionHeading heading="Latest discussions" addMobileMargin>
        {canCreateTopic && (
          <button onClick={dialogHandler.open}>
            <AddAction>New discussion</AddAction>
          </button>
        )}
      </SectionHeading>
      <Card>
        <Resource resource={discussionsState}>
          {discussions => (
            <>
              <CardStack>
                {discussions.values.slice(0, DISCUSSIONS_TO_SHOW).map((discussion, i) => (
                  <div key={discussion.id}>
                    <ClickableCard onClick={() => setActiveTopic(discussion.id)} title={discussion.title} />
                    {i < discussions.values.length - 1 && i < DISCUSSIONS_TO_SHOW - 1 && <Divider />}
                  </div>
                ))}
              </CardStack>
              {discussions.total > DISCUSSIONS_TO_SHOW && (
                <div className="u-content-spacing-top">
                  <Button kind="secondary" onClick={discussionListDialogHandler.open}>
                    {i18n('en').common.seeMore()}
                  </Button>
                </div>
              )}
              <DiscussionListContainer
                dialogHandler={discussionListDialogHandler}
                communityId={community.id}
                companyId={companyId}
                setActiveTopic={setActiveTopic}
                companyName={companyName}
                discussions={discussions}
                updateDiscussionTopic={topic => updateDiscussionTopic(discussions, topic)}
                addPageToDiscussions={onSeeMore}
                canEdit={canCreateTopic}
              />
              {canCreateTopic && (
                <CreateDiscussionTopicDialog
                  onCreate={curry(create)(notify, community.id, companyId, topic => {
                    notify('success', i18n('en').common.saveOrCreate.success('Create', 'Discussion topic'));
                    setDiscussionsState({
                      state: 'fetched',
                      resource: addItem(discussions, topic, 'before'),
                      isValidating: false,
                    });
                  })}
                  onEdit={curry(edit)(notify, community.id, companyId, topic => {
                    notify('success', i18n('en').common.saveOrCreate.success('Save', 'Discussion topic'));
                    setDiscussionsState({
                      state: 'fetched',
                      resource: updateInList(
                        discussions,
                        d => d.id === topic.id,
                        () => topic,
                      ),
                      isValidating: false,
                    });
                  })}
                  dialogHandler={dialogHandler}
                  communityId={community.id}
                  companyId={companyId}
                  companyName={companyName}
                />
              )}
            </>
          )}
        </Resource>
        <p className="text-metadata">
          <EyeIcon className="u-quarter-spacing-right" /> Discussions will be visible to investor users and community
          admins.
        </p>
      </Card>
      {activeTopic && (
        <DiscussionDialog
          communityId={community.id}
          companyId={companyId}
          discussionId={activeTopic}
          onClose={() => setActiveTopic(undefined)}
        ></DiscussionDialog>
      )}
    </>
  );
}
