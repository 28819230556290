import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { EventDTO } from 'ui/domain/Event/types';

export const eventsKey = (communitySlug: string) => communityAPIUrls.events.list(communitySlug);
export function useCommunityEvents(communitySlug: string) {
  const swrResult = useSWR<EventDTO[]>(eventsKey(communitySlug));
  return useSWRResource(swrResult);
}
