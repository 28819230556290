import React, { useState, useEffect } from 'react';
import { WizardType } from 'pages/Dashboard/Dashboard';
import CompanyOnboardingWizard from 'domain/onboarding/company/Wizard/CompanyWizard';
import {
  OnboardingAPI,
  onboardingApiUrls,
  Onboarding as OnboardingDTO,
  OnboardingPart,
  OnboardingStep,
  OnboardingWizardStatus,
} from 'apis/OnboardingAPI';
import { setStepCompleted } from 'domain/onboarding/utils';
import Dialog from 'ui/views/dialogs/Dialog';
import useRoute from 'hooks/useRoute';
import { communityUrls, companyUrls, organizationUrls } from 'urls';
import UserOnboardingWizard from 'domain/onboarding/investor/UserOnboardingWizard';
import { SelfUserProfile } from 'types/user';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resources from 'util/resource/Resources';
import useResource from 'util/resource/useResource';
import useLazyResource from 'util/resource/useLazyResource';
import { ICompany } from 'types/company';
import { invalidate } from 'hooks/useSWR';
import { onboardinWizardStatusKey } from './InitialLoadWrapper';
import OrganizationWizard from 'domain/onboarding/organization/OrganizationWizard';
import { TinyOrganizationDTO } from 'types/organization';
import { getOrElse, getOrUndefined } from 'util/resource';
import { CommunityInviteDetails } from 'types/company/community';
import { useLoginState } from 'auth/useLoginWithRedirect';
import { useCommunityInvite } from 'apis/CompanyAPI/communities/useCommunityInvite';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { emptyList } from 'types/api';
import featureToggle from 'featureToggle';

export default function OnboardingWrapper({
  onboardingStatus,
  children,
}: {
  onboardingStatus: OnboardingWizardStatus;
  children: React.ReactNode;
}) {
  const { resource: userResource } = useSelfUserProfile();

  const { loginState } = useLoginState();
  const { resource: communityInviteResource } = useCommunityInvite(loginState?.joinCommunity?.code);
  const { resource: onboardingResource, mutate: setOnboarding } = useResource<OnboardingDTO>(
    onboardingStatus.organization
      ? onboardingApiUrls.getOrganizationOnboarding(onboardingStatus.organization.slug)
      : onboardingStatus.company
        ? onboardingApiUrls.getCompanyOnboarding(onboardingStatus.company.slug)
        : onboardingApiUrls.getUserOnboarding,
  );
  return (
    <Resources resources={[userResource, onboardingResource]} renderLoading="Bar">
      {([user, onboarding]) =>
        // make sure that invite is done loading before rendering the onboarding
        // cannot use Resource here because it will be idle if the invite is not present.
        //  AND we want to render it even if it fails to load
        communityInviteResource.state === 'fetching' ? null : (
          <Onboarding
            onboarding={onboarding}
            setOnboarding={o => setOnboarding(o, { revalidate: false })}
            userProfile={user}
            onboardingStatus={onboardingStatus}
            communityInvite={getOrUndefined(communityInviteResource)}
          >
            {children}
          </Onboarding>
        )
      }
    </Resources>
  );
}

function Onboarding({
  userProfile,
  onboarding,
  setOnboarding,
  onboardingStatus,
  communityInvite,
  children,
}: {
  onboarding: OnboardingDTO;
  setOnboarding: (onboarding: OnboardingDTO) => void;
  userProfile: SelfUserProfile;
  onboardingStatus: OnboardingWizardStatus;
  communityInvite?: CommunityInviteDetails;
  children: React.ReactNode;
}) {
  const [patchUserOnboarding] = useLazyResource((status: 'Skipped' | 'Completed') =>
    OnboardingAPI.patchUserOnboarding(status),
  );

  const [patchCompanyOnboarding] = useLazyResource(
    ({ company, status }: { company: ICompany; status: 'Skipped' | 'Completed' }) =>
      OnboardingAPI.patchCompanyOnboarding(company.slug, status),
  );

  const [patchOrganizationOnboarding] = useLazyResource(
    ({ organization, status }: { organization: TinyOrganizationDTO; status: 'Skipped' | 'Completed' }) =>
      OnboardingAPI.patchOrganizationOnboarding(organization.slug, status),
  );

  const getWizardType = (): WizardType => {
    if (onboardingStatus.organization) {
      return { type: 'organization', organization: onboardingStatus.organization };
    } else if (onboardingStatus.company) {
      return { type: 'company', company: onboardingStatus.company };
    } else if (!onboardingStatus.company) {
      return { type: 'user' };
    }
    return {
      type: 'none',
    };
  };

  const [wizardType, setWizardType] = useState<WizardType>(getWizardType());
  const onboardingState = onboarding.wizard;

  const { resource: communitiesResource } = useCommunities();
  const maybeSEBCommunityUserIsPartOf = getOrElse(communitiesResource, emptyList()).values.find(community =>
    featureToggle.communityIsConnectedToSeb(community.slug),
  );

  // In the user onboarding, if the user chooses to register a company
  // we need to switch from the user onboarding to the company onboarding
  useEffect(() => {
    setWizardType(getWizardType());
  }, [onboardingStatus]);

  const setOnboardingState = (part: OnboardingPart) => setOnboarding({ ...onboarding, wizard: part });
  const setWizardStepCompleted = (step: OnboardingStep) => setOnboardingState(setStepCompleted(onboardingState, step));
  const { push } = useRoute();

  if (wizardType.type !== 'none') {
    return (
      <Dialog
        mobileLayout="fullscreen"
        verticalAlign="top"
        maxWidth="md"
        open
        backdropColor="white"
        paperProps={{ sx: { boxShadow: 'initial' } }}
      >
        {wizardType.type === 'organization' && (
          <OrganizationWizard
            organizationSlug={wizardType.organization.slug}
            wizard={onboardingState}
            setStepCompleted={setWizardStepCompleted}
            onClose={status => {
              invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
              invalidate(onboardingApiUrls.getOrganizationOnboarding(wizardType.organization.slug));
              patchOrganizationOnboarding({ organization: wizardType.organization, status }).finally(() => {
                // if we change url before the api call is done, react crashes with a suspend error 🙃
                push(organizationUrls.view(wizardType.organization.slug));
              });
            }}
          />
        )}
        {wizardType.type === 'company' && (
          <CompanyOnboardingWizard
            onClose={status => {
              invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
              invalidate(onboardingApiUrls.getCompanyOnboarding(wizardType.company.slug));
              patchCompanyOnboarding({ company: wizardType.company, status }).finally(() => {
                // if we change url before the api call is done, react crashes with a suspend error 🙃
                push(companyUrls.overview(wizardType.company.slug, 'profile'));
              });
            }}
            company={wizardType.company}
            wizard={onboardingState}
            setStepCompleted={setWizardStepCompleted}
          />
        )}
        {wizardType.type === 'user' && (
          <UserOnboardingWizard
            invite={communityInvite}
            onShowWizard={wizard => setWizardType(wizard)}
            user={userProfile}
            onboarding={onboarding}
            onClose={() => {
              setWizardType({ type: 'none' });
              patchUserOnboarding('Skipped')
                .then(() => invalidate(onboardingApiUrls.getStatus))
                .finally(() => {
                  if (maybeSEBCommunityUserIsPartOf) {
                    push(communityUrls.overview(maybeSEBCommunityUserIsPartOf.slug));
                  }
                });
            }}
            setOnboarding={setOnboarding}
            onWizardCompleted={() => {
              setWizardType({ type: 'none' });
              patchUserOnboarding('Completed')
                .then(() => {
                  invalidate(onboardingApiUrls.getStatus);
                  invalidate(onboardingApiUrls.getUserOnboarding);
                })
                .finally(() => {
                  if (maybeSEBCommunityUserIsPartOf) {
                    push(communityUrls.overview(maybeSEBCommunityUserIsPartOf.slug));
                  }
                });
            }}
          />
        )}
      </Dialog>
    );
  }

  return children;
}
