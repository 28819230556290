import { CompanyListItem } from 'types/company';
import { CommunityListItem } from 'types/company/community';

export const communitiesByRole = (communities: CommunityListItem[]) => {
  const communitiesIManage = communities.filter(c => c.roles.includes('company_master'));
  const myCommunities = communities.filter(c => !c.roles.includes('company_master'));

  return { communitiesIManage, myCommunities };
};

export const companiesByRole = (companies: CompanyListItem[]) => ({
  companiesIManage: companies.filter(c => c.roles.includes('company_master')),
  myInvestments: companies.filter(c => c.roles.includes('investor')),
  myBoardPositions: companies.filter(c => c.roles.includes('board')),
  companiesIFollow: companies.filter(c => c.roles.includes('prospective_investor')),
});

export const companiesCommunitiesByRole = (companies: CompanyListItem[], communities: CommunityListItem[]) => {
  return {
    ...communitiesByRole(communities),
    ...companiesByRole(companies),
  };
};
