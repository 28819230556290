import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import React from 'react';
import { splitFilenameExtension } from 'util/stringUtils';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import styles from './styles.scss';

interface Props {
  name: string;
  className?: string;
  iconSize?: 'small' | 'large';
  children?: JSX.Element;
  onDownload?: () => void;
  isDownloading?: boolean;
}

function Text({ name }: { name: string }) {
  const file = splitFilenameExtension(name);
  return (
    <span className="text-font-display">
      {file.filename} {file.extension && `(.${file.extension})`}
    </span>
  );
}

export default function DocumentListItem({ onDownload, isDownloading, name, className, iconSize, children }: Props) {
  const { onClick, WarningDialog } = useDownloadWarning(name, onDownload);

  return (
    <>
      <div className={cx(className, styles.listItem, 'u-flex u-flex-align-center u-flex-space-between')}>
        {!isDownloading ? (
          <button onClick={onClick} className={cx(styles.document, 'u-flex u-flex-align-center')}>
            <FileIcon name={name} fontSize={iconSize || 'large'} className="u-half-spacing-right" />
            <Text name={name} />
          </button>
        ) : (
          <span className="u-flex u-flex-align-center">
            <CircularProgress className="u-half-spacing-right" size={20} />
            <Text name={name} />
          </span>
        )}
        {children}
      </div>
      {WarningDialog}
    </>
  );
}
