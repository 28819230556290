import { useState } from 'react';
import { DOCUMENT_UPLOAD_LIMIT, SUPPORTED_DOCUMENT_FILE_TYPES } from 'util/constants';
import { isAllowedFiletype } from 'domain/shared/Files/utils';
import useNotify from 'hooks/useNotify';
import { FileRejection } from 'react-dropzone';
import pluck from 'ramda/src/pluck';

export type UploadStatus = 'UPLOADING' | 'COMPLETED' | 'UPLOAD_ERROR';

export interface FileBeingUploaded {
  fileId?: UUID;
  tempId: string;
  status: UploadStatus;
  errorMessage?: string;
  file: File;
}

export default function useFileUpload(
  uploadFile: (file: File) => Promise<UUID>,
  props?: {
    deleteFile?: (fileId: UUID) => Promise<void>;
    onSuccess?: (files: { documentId: string; name: string }[]) => void;
  },
) {
  const [files, setFiles] = useState<FileBeingUploaded[]>([]);
  const notify = useNotify();

  function onDrop(accepted: File[], rejected: FileRejection[]) {
    const newFiles: FileBeingUploaded[] = accepted.map((f, i) => ({
      file: f,
      status: 'UPLOADING',
      tempId: i + new Date().getTime().toString(),
    }));

    setFiles(prevState =>
      prevState
        .concat(
          rejected.map((f, i) => ({
            file: f.file,
            status: 'UPLOAD_ERROR',
            tempId: new Date().getTime().toString() + i,
          })),
        )
        .concat(newFiles),
    );

    const upload = async function (a: FileBeingUploaded) {
      try {
        const uploadResult = uploadFile(a.file);
        const documentId = await uploadResult;
        return { ...a, fileId: documentId, status: 'COMPLETED' } as FileBeingUploaded;
      } catch (error) {
        const msg = (error && error.message) || 'Error during file upload';
        const shortMsg = msg.replace(`${a.file.name} `, '');
        notify('error', shortMsg);
        return { ...a, status: 'UPLOAD_ERROR', errorMessage: shortMsg } as FileBeingUploaded;
      }
    };

    Promise.all(newFiles.map(upload)).then((uploadedFiles: FileBeingUploaded[]) => {
      setFiles(prevState =>
        prevState.map(d => {
          const foundUploadedFile = uploadedFiles.find(f => f.tempId === d.tempId);
          return foundUploadedFile
            ? {
                ...d,
                status: foundUploadedFile.status,
                fileId: foundUploadedFile.fileId,
                errorMessage: foundUploadedFile.errorMessage,
              }
            : d;
        }),
      );
      props?.onSuccess &&
        props?.onSuccess(uploadedFiles.filter(f => f.fileId).map(f => ({ documentId: f.fileId!, name: f.file.name })));
    });

    if (rejected.length > 0) {
      const rejectedFile = rejected[0]?.file;

      if (rejectedFile.size > DOCUMENT_UPLOAD_LIMIT.bytes) {
        notify(
          'error',
          `${rejectedFile.name} exceeds the upload limit of ${DOCUMENT_UPLOAD_LIMIT.human}. Use a smaller file.`,
        );
      }

      if (!isAllowedFiletype(rejectedFile.type)) {
        notify(
          'error',
          `Supported document file types are: ${pluck('extension', SUPPORTED_DOCUMENT_FILE_TYPES).join(', ')}`,
        );
      }
      return;
    }
  }

  function reset() {
    setFiles([]);
  }

  function removeFile(temporaryId: string) {
    const file = files.find(doc => doc.tempId === temporaryId);
    if (file?.fileId && props?.deleteFile) {
      props.deleteFile(file.fileId);
    }
    setFiles(docs => docs.filter(doc => doc.tempId !== temporaryId));
  }

  const result: [FileBeingUploaded[], typeof onDrop, typeof removeFile, typeof reset] = [
    files,
    onDrop,
    removeFile,
    reset,
  ];
  return result;
}
