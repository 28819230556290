import React, { useEffect, useState } from 'react';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import { Shortlist } from 'types/user';
import BookmarkActiveIcon from 'ui/elements/icons/BookmarkActiveIcon';
import Button from 'ui/elements/buttons/Button';
import styles from './styles.scss';
import cx from 'classnames';
import { CircularProgress, Menu, PopoverOrigin, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import IconButton from 'ui/elements/icons/IconButton';
import { KeyedMutator } from 'swr';
import { List } from 'types/api';
import useLazyResource from 'util/resource/useLazyResource';
import { IResource } from 'util/resource';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import useNotify from 'hooks/useNotify';
import append from 'ramda/src/append';
import without from 'ramda/src/without';
import useLocalStorage from 'hooks/useLocalStorage';
import { ICompany } from 'types/company';
import useJourneyLogger from 'hooks/useJourneyLogger';
import Tooltip from 'ui/elements/Tooltip';
import { useRouteMatch } from 'react-router';
import { bluePlanetTheme } from 'ui/theme';
import DrawerBottom, { DrawerItem } from 'ui/elements/DrawerBottom';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';

type Props = {
  company: ICompany;
  shortlists: Shortlist[];
  setShortlists: KeyedMutator<List<Shortlist>>;
  dropdownButton?: (onClick: React.MouseEventHandler<HTMLButtonElement>) => JSX.Element;
  menuItemHeight?: string;
  dropdownWidth?: string;
  DropDownIcon?: JSX.Element;
  anchorOrigin?: PopoverOrigin;
};

type AddInputFieldProps = {
  createShortlist: (args: string) => Promise<IResource<Shortlist>>;
  isSaving: boolean;
  isActive: boolean;
  setActive: (isActive: boolean) => void;
  menuItemHeight?: string;
};

const ShortlistSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name for this list'),
});

const getShortlistCompanies = (companyId: number, shortlistCompanies: number[]) => {
  if (shortlistCompanies.includes(companyId)) {
    return without([companyId], shortlistCompanies);
  } else {
    return append(companyId, shortlistCompanies);
  }
};

function AddInputField({ createShortlist, isSaving, isActive, setActive, menuItemHeight }: AddInputFieldProps) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: (values, { resetForm }) => {
      createShortlist(values.name).then(() => resetForm());
    },
    validationSchema: ShortlistSchema,
    enableReinitialize: true,
    validateOnChange: false,
  });

  if (isMobile) {
    if (isActive) {
      return (
        <DrawerItem
          innerClassName="u-flex--align-items-start"
          suffix={
            <Button onClick={() => formik.handleSubmit()} kind="primary" isLoading={isSaving} disabled={isSaving}>
              Add
            </Button>
          }
        >
          <FormikTextField formikProps={formik} name="name" placeholder="List name" autoFocus fullWidth />
        </DrawerItem>
      );
    }

    return (
      <DrawerItem
        color={bluePlanetTheme.bluePlanetPalette.blue.main}
        onClick={() => setActive(true)}
        className="u-half-spacing-top"
        prefix={<BookmarkIcon />}
      >
        Create new list
      </DrawerItem>
    );
  }

  if (isActive) {
    return (
      <div className={styles.inputItem}>
        <FormikTextField formikProps={formik} name="name" placeholder="List name" autoFocus />
        <Button onClick={() => formik.handleSubmit()} kind="primary" isLoading={isSaving} disabled={isSaving}>
          Add
        </Button>
      </div>
    );
  }

  return (
    <button className={cx(styles.createListButton)} style={{ height: menuItemHeight }} onClick={() => setActive(true)}>
      <BookmarkIcon className="u-half-spacing-x" color="blue" />
      <span className="text-link-small">Create new list</span>
    </button>
  );
}

export default function CompanyShortlistPicker({
  company,
  shortlists,
  setShortlists,
  dropdownButton,
  menuItemHeight,
  dropdownWidth,
  DropDownIcon,
  anchorOrigin,
}: Props) {
  const match = useRouteMatch<{ communitySlug?: string }>();
  const communityContextSlug = match.params.communitySlug;

  // used for rendering the loading icon, and for disabling dropdown actions while a list is being saved
  const [shortlistSaveId, setShortlistSaveId] = useState<string>();
  const [isInputActive, setInputActive] = useState(false);
  const notify = useNotify();
  const [log] = useJourneyLogger();

  const isShortlisted = shortlists.filter(sl => sl.companies.map(c => c.id).includes(company.id)).length > 0;

  const [updateListCompanies, isUpdatingListCompanies] = useLazyResource(
    ({ listId, companyIds }: { listId: UUID; companyIds: number[] }) =>
      usersApi.shortlists.updateCompanies(
        listId,
        companyIds.map(c => c.toString()),
        communityContextSlug,
      ),
    {
      onSuccess: updatedShortlist => {
        setShortlists(
          {
            values: !shortlists.map(sl => sl.id).includes(updatedShortlist.id)
              ? [updatedShortlist, ...shortlists]
              : shortlists.map(shortlist => (shortlist.id === updatedShortlist.id ? updatedShortlist : shortlist)),
          },
          { revalidate: false },
        );
        setShortlistSaveId(undefined);
      },
      onFailure: message => notify('error', message ?? 'Could not update the list'),
    },
  );

  // Automatically shortlist the company.
  // If the user has visited the company on an internet public page and clicked “add to list” before the user was logged in, this flag is set in localstorage.
  // We use it to make sure that the follow request is sent when they have registered or logged in
  const [shouldAutoShortlistCompany, , removeAutoShortlistCompany] = useLocalStorage<string | undefined>(
    'auto-shortlist-company',
    undefined,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setInputActive(false);
  };

  useEffect(() => {
    if (shouldAutoShortlistCompany === company.slug && !isShortlisted) {
      removeAutoShortlistCompany();
    }
    if (shouldAutoShortlistCompany === company.slug && !isShortlisted && shortlists.length > 0) {
      const shortlist = shortlists[0];
      setShortlistSaveId(shortlist.id);
      updateListCompanies({
        listId: shortlist.id,
        companyIds: getShortlistCompanies(
          company.id,
          shortlist.companies.map(c => c.id),
        ),
      });
    }
  }, [shouldAutoShortlistCompany]);

  const [createListWithCompany, isCreatingList] = useLazyResource(
    (listName: string) => usersApi.shortlists.create(listName),
    {
      onSuccess: newShortlist => {
        setShortlistSaveId(newShortlist.id);
        updateListCompanies({
          listId: newShortlist.id,
          companyIds: newShortlist.companies.map(c => c.id).concat(company.id),
        });
        setInputActive(false);
        notify('success', 'List created successfully');
        handleClose();
      },
      onFailure: message => notify('error', message ?? 'Could not save the list'),
    },
  );

  function getIcon(isSaving: boolean, isInList: boolean) {
    if (isSaving) {
      return <CircularProgress className="u-content-spacing-x" size={20} />;
    }
    if (isInList) {
      return <BookmarkActiveIcon className="u-content-spacing-x" fillColor="blue" color="blue" />;
    }
    return null;
  }
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const onShortlist = (sl: Shortlist) => {
    if (shortlistSaveId) {
      return;
    }
    setShortlistSaveId(sl.id);
    const isRemoved = sl.companies.map(c => c.id).includes(company.id);
    if (isRemoved) {
      log(`removed shortlisting for company ${company.id}`);
    } else {
      log(`shortlisted company ${company.id}`);
    }
    updateListCompanies({
      listId: sl.id,
      companyIds: getShortlistCompanies(
        company.id,
        sl.companies.map(c => c.id),
      ),
    });
    handleClose();
  };

  return (
    <>
      {dropdownButton ? (
        dropdownButton(handleOpen)
      ) : (
        <Tooltip title={'Add to list'}>
          <span>
            <IconButton onClick={handleOpen} hoverColor="blue">
              {isShortlisted
                ? (DropDownIcon ?? <BookmarkActiveIcon fillColor="blue" color="blue" fontSize="small" />)
                : (DropDownIcon ?? <BookmarkIcon fontSize="small" />)}
            </IconButton>
          </span>
        </Tooltip>
      )}

      {isMobile ? (
        <DrawerBottom onClose={handleClose} isOpen={isOpen}>
          <SectionHeadingLarge className="u-content-spacing-x" heading={'Choose list'}>
            <Button onClick={handleClose} kind="tertiary">
              Cancel
            </Button>
          </SectionHeadingLarge>
          {shortlists.map((sl, i) => (
            <DrawerItem
              disabled={!!shortlistSaveId}
              key={i}
              onClick={() => onShortlist(sl)}
              suffix={getIcon(shortlistSaveId === sl.id, sl.companies.map(c => c.id).includes(company.id))}
            >
              <span className={sl.companies.map(c => c.id).includes(company.id) ? 'text-weight-bold' : ''}>
                {sl.name}
              </span>
            </DrawerItem>
          ))}
          <AddInputField
            createShortlist={createListWithCompany}
            isSaving={isCreatingList || isUpdatingListCompanies}
            isActive={isInputActive}
            setActive={setInputActive}
            menuItemHeight={menuItemHeight ?? '50px'}
          />
        </DrawerBottom>
      ) : (
        <Menu
          slotProps={{
            paper: {
              style: {
                width: dropdownWidth,
              },
            },
          }}
          anchorOrigin={
            anchorOrigin ?? {
              vertical: 'bottom',
              horizontal: 'left',
            }
          }
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={isOpen}
        >
          <div>
            <div className={styles.menuItemList}>
              {shortlists.map((sl, i) => (
                <button
                  key={i}
                  className={cx(styles.menuItem, {
                    [styles.disabled]: !!shortlistSaveId,
                  })}
                  style={{ minHeight: menuItemHeight ?? '50px' }}
                  onClick={() => onShortlist(sl)}
                >
                  <div
                    className={cx(styles.menuItemText, {
                      [styles.disabled]: !!shortlistSaveId,
                    })}
                  >
                    <div className="u-ellipsis">{sl.name}</div>
                  </div>
                  {getIcon(shortlistSaveId === sl.id, sl.companies.map(c => c.id).includes(company.id))}
                </button>
              ))}
            </div>
            <AddInputField
              createShortlist={createListWithCompany}
              isSaving={isCreatingList || isUpdatingListCompanies}
              isActive={isInputActive}
              setActive={setInputActive}
              menuItemHeight={menuItemHeight ?? '50px'}
            />
          </div>
        </Menu>
      )}
    </>
  );
}
