import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import DocumentListItem from '../DocumentListItem';
import { downloadBlob } from 'domain/shared/Files/actions';
import useNotify from 'hooks/useNotify';
import React from 'react';
import { Mentorship } from 'apis/MentorAPI/types';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import useLazyResource from 'util/resource/useLazyResource';

interface Props {
  communitySlug: string;
  companyId: number;
  mentorship: Mentorship;
}

export default function MentorAgreementDocument(props: Props) {
  const notify = useNotify();

  const [download, isDownloading] = useLazyResource(
    () =>
      communitiesApi.mentorNetwork.mentorships
        .downloadMentorAgreement(props.communitySlug, props.companyId, props.mentorship.id)
        .then(response => downloadBlob(response)),
    {
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );

  return props.mentorship.mentorAgreementFileId ? (
    <Card>
      <SectionHeading heading="Mentor agreement" addMobileMargin />
      <DocumentListItem
        className="text-medium"
        key={props.mentorship.mentorAgreementFileId}
        name="Mentor agreement"
        onDownload={() => download(props.mentorship.mentorAgreementFileId)}
        isDownloading={isDownloading}
      />
    </Card>
  ) : null;
}
