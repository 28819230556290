import React from 'react';
import { Link } from 'react-router-dom';
import Resource from 'util/resource/Resource';
import SectionHeading from 'ui/elements/SectionHeading';
import { communityUrls } from 'urls';
import ClickableCard from 'ui/views/cards/ClickableCard';
import useRoute from 'hooks/useRoute';
import { formatEventDate } from 'util/dateUtils';
import styled from '@emotion/styled';
import { quarterSpacing } from 'ui/theme/themeConstants';
import { useCommunityEvents } from '../useCommunityEvents';
import CalendarBasicIcon from 'ui/elements/icons/CalendarBasicIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${quarterSpacing};
`;

export default function UpcomingEvents({ communitySlug }: { communitySlug: string }) {
  const { push } = useRoute();
  const { resource: events } = useCommunityEvents(communitySlug);

  return (
    <Resource resource={events} renderLoading="Nothing">
      {events =>
        events.length > 0 && (
          <div className="u-section-spacing-bottom">
            <SectionHeading heading="Upcoming events" actionsAlignment="left" addMobileMargin>
              <Link to={communityUrls.overview(communitySlug, 'events')} className="text-link-small">
                See all
              </Link>
            </SectionHeading>
            <Container>
              {events.slice(0, 3).map(event => (
                <ClickableCard
                  key={event.id}
                  avatar={
                    <IconAvatar size={{ width: '50px', height: '50px' }}>
                      <CalendarBasicIcon fontSize="medium" />
                    </IconAvatar>
                  }
                  title={event.title}
                  onClick={() => push(`/events/${event.id}`)}
                >
                  <span className="text-metadata">
                    {formatEventDate(new Date(event.startsAt), event.endsAt ? new Date(event.endsAt) : undefined)}
                  </span>
                </ClickableCard>
              ))}
            </Container>
          </div>
        )
      }
    </Resource>
  );
}
