import React, { useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import FullscreenDialog, { Content, Title } from 'ui/views/dialogs/FullscreenDialog';
import Dialog, { Content as DialogContent } from 'ui/views/dialogs/Dialog';
import UserCard from 'ui/domain/Users/UserCard';
import UserPreview from 'domain/users/UserProfile/UserPreview';
import { UserProfile } from 'types/user';
import DialogActions from '@mui/material/DialogActions';
import PlusIcon from 'ui/elements/icons/PlusIcon';
import { RoomRoles } from './types';
import { roleDisplayNamePlural } from './utils';
import styles from './styles.scss';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import { PaginatedResult } from 'types';
import { UsersSearchFilter } from 'types/company/companyAPI';
import CompanyUserList from './CompanyUserList';

interface Props {
  onClose: () => void;
  fetchUsers: (companySlug: string, filter: UsersSearchFilter) => Promise<PaginatedResult<UserProfile>>;
  role: RoomRoles;
  assignUser: (userProfile: UserProfile) => void;
  unAssignUser: (userProfile: UserProfile) => void;
  assignedUsers: UserProfile[];
  companySlug: string;
  relevantRoles: { value: string; label: string }[];
}

export default function UserListDialog(props: Props) {
  const [userPreviewProfile, setUserPreviewProfile] = useState<undefined | UserProfile>(undefined);

  return (
    <FullscreenDialog open={true} onClose={props.onClose}>
      <Title onClose={props.onClose}>Assign {roleDisplayNamePlural(props.role)}</Title>
      <Content>
        <CompanyUserList
          companySlug={props.companySlug}
          relevantRoles={props.relevantRoles}
          fetchUsers={props.fetchUsers}
          renderUser={user => (
            <UserCard
              user={user}
              onClick={() => setUserPreviewProfile(user)}
              actions={
                <>
                  {props.assignedUsers.find(u => u.id === user.id) ? (
                    <Button
                      style={{ minWidth: '56px' }}
                      className={styles.unselectButton}
                      kind="primary"
                      onClick={() => props.unAssignUser(user)}
                    >
                      <CheckmarkIcon />
                    </Button>
                  ) : (
                    <Button style={{ minWidth: '56px' }} kind="tertiary" onClick={() => props.assignUser(user)}>
                      <PlusIcon />
                    </Button>
                  )}
                </>
              }
            />
          )}
        />
      </Content>
      <DialogActions>
        <Button kind="secondary" onClick={props.onClose}>
          Okay
        </Button>
      </DialogActions>
      <Dialog open={!!userPreviewProfile} onClose={() => setUserPreviewProfile(undefined)}>
        <DialogContent>
          {userPreviewProfile && <UserPreview cwUserId={userPreviewProfile.cwUserId}></UserPreview>}
        </DialogContent>
      </Dialog>
    </FullscreenDialog>
  );
}
