import useProgressHandler from 'hooks/useProgressHandler';
import React, { useState } from 'react';
import { MentorNetworkMentorship } from 'apis/MentorAPI/types';
import Button from 'ui/elements/buttons/Button';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import SectionHeading from 'ui/elements/SectionHeading';
import UserElement from 'ui/domain/Users/UserElement';
import Card from 'ui/views/cards/Card';
import { unassignMentor } from './actions';
import MentorAssignmentDialog from './MentorAssignmentDialog';
import ContactInfoBlock from 'ui/domain/Contact/ContactDetails';
import useDialogHandler from 'hooks/useDialogHandler';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import useNotify from 'hooks/useNotify';
import { TinyCommunity } from 'types/company/community';

interface Props {
  mentorship: MentorNetworkMentorship;
  companyId: number;
  companyName: string;
  community: TinyCommunity;
  refresh: () => void;
}

export default function MentorAssignment(props: Props) {
  const notify = useNotify();
  const [loading, unassignProgressHandler] = useProgressHandler();
  const { mentor } = props.mentorship;

  const assignMentorDialogHandler = useDialogHandler(false);
  const [userDialogId, setUserDialogId] = useState<UUID | undefined>(undefined);

  return (
    <>
      <SectionHeading heading="Mentor" addMobileMargin />
      <Card>
        {mentor ? (
          <>
            <div className="u-flex u-flex-align-center u-content-spacing-top u-flex-space-between">
              <button onClick={() => setUserDialogId(mentor.cwUserId)}>
                <UserElement user={mentor} />
              </button>
              <Button
                kind="tertiary"
                isLoading={loading}
                onClick={() =>
                  unassignMentor(
                    props.community.slug,
                    props.companyId,
                    props.mentorship.id,
                    props.refresh,
                    unassignProgressHandler,
                    notify,
                  )
                }
              >
                <TrashIcon color="red" />
              </Button>
            </div>
            <ContactInfoBlock email={mentor.email} phone={mentor.phone} />
          </>
        ) : (
          <div>
            <div>No mentor assigned.</div>
            <Button
              onClick={assignMentorDialogHandler.open}
              className="u-half-spacing-top u-half-spacing-right"
              kind="secondary"
            >
              Assign mentor
            </Button>
          </div>
        )}
        {assignMentorDialogHandler.isOpen && (
          <MentorAssignmentDialog
            onClose={assignMentorDialogHandler.close}
            community={props.community}
            companyId={props.companyId}
            companyName={props.companyName}
            mentorship={props.mentorship}
            onAssign={props.refresh}
          />
        )}
        {userDialogId && <UserProfileDialog cwUserId={userDialogId} onClose={() => setUserDialogId(undefined)} />}
      </Card>
    </>
  );
}
