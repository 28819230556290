import React from 'react';
import InfoMessage from 'ui/views/messages/InfoMessage';
import HornIcon from 'ui/elements/icons/HornIcon';
import Button from 'ui/elements/buttons/Button';
import { Link } from 'react-router-dom';
import { communityUrls, companyUrls } from 'urls';
import { useHasReadMentoringWidget, useHasReadMentorWelcome } from '../utils';
import { TinyCommunity } from 'types/company/community';
import { ICompany } from 'types/company';

interface Props {
  community: TinyCommunity;
  menteeCompanies: ICompany[];
}

export default function MentorCtaBanner({ community, menteeCompanies }: Props) {
  const { hasReadWelcome, setHasReadWelcome } = useHasReadMentorWelcome(community.id);
  const { hasReadMentoringWidget, sethasReadMentoringWidget } = useHasReadMentoringWidget(community.id);
  if (menteeCompanies.length === 0) {
    return !hasReadWelcome ? (
      <InfoMessage
        className="u-content-spacing-bottom"
        icon={<HornIcon />}
        confirmButton={
          <Link to={communityUrls.members.overview(community.slug, 'companies')}>
            <Button kind="primary" color="white" onClick={() => setHasReadWelcome(true)}>
              Show companies
            </Button>
          </Link>
        }
      >
        Welcome! Feel free to explore and follow the companies you like and we will notify you when you get your first
        assignment
      </InfoMessage>
    ) : null;
  } else if (menteeCompanies.length === 1) {
    return (
      <InfoMessage
        className="u-content-spacing-bottom"
        icon={<HornIcon />}
        confirmButton={
          <Link to={companyUrls.overview(menteeCompanies[0].slug, 'profile')}>
            <Button kind="primary" color="white">
              Show mentorship
            </Button>
          </Link>
        }
      >
        You are currently mentoring {menteeCompanies[0].name}
      </InfoMessage>
    );
  } else
    return !hasReadMentoringWidget ? (
      <InfoMessage
        className="u-content-spacing-bottom"
        icon={<HornIcon />}
        confirmButton={
          <Button kind="primary" color="blue" onClick={() => sethasReadMentoringWidget(true)}>
            Okay, Thank you!
          </Button>
        }
      >
        You are currently mentoring {menteeCompanies.length} companies. You can follow the progress on the mentorship
        tab of each company.
      </InfoMessage>
    ) : null;
}
