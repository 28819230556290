import React, { useRef, useState } from 'react';
import { ClickAwayListener, MenuList, useMediaQuery } from '@mui/material';
import TextFilter from 'domain/shared/Filters/TextFilter';
import useResource from 'util/resource/useResource';
import { MemberSearchResult, communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import Card from 'ui/views/cards/Card';
import { bluePlanetTheme } from 'ui/theme';
import MobileDialog, { DialogTitle, DialogContent } from 'ui/views/dialogs/MobileDialog';
import IconButton from 'ui/elements/icons/IconButton';
import SearchIcon from 'ui/elements/icons/SearchIcon';
import { quarterSpacing } from 'ui/theme/themeConstants';
import SearchResults from './SearchResults';
import { TinyCommunity } from 'types/company/community';

export default function CommunitySearch({ community }: { community: TinyCommunity }) {
  const [searchQuery, setSearchQuery] = useState('');
  const { resource: searchResultsResource } = useResource<MemberSearchResult[]>(
    searchQuery ? communitiesApi.search(community.slug, searchQuery) : undefined,
    { keepPreviousData: true },
  );

  const firstResultRef = useRef<HTMLLIElement>(null);
  const isSmallScreen = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const onClose = () => {
    setShowSearchDialog(false);
    setSearchQuery('');
  };

  return (
    <>
      {isSmallScreen ? (
        <IconButton color="indigo" onClick={() => setShowSearchDialog(true)}>
          <SearchIcon />
        </IconButton>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            if (searchQuery) {
              setSearchQuery('');
            }
          }}
        >
          {/* set a fixed with to so that search input doesn't grow when the (X) button is visible */}
          <span style={{ position: 'relative', width: '260px' }}>
            <TextFilter
              value={searchQuery}
              setValue={setSearchQuery}
              placeholder="Search members"
              alwaysOpen={!isSmallScreen}
              onKeyUp={e => {
                if (e.key === 'ArrowDown') {
                  firstResultRef.current?.focus();
                }
              }}
              prefix={<SearchIcon fontSize="small" />}
            />

            {searchQuery && (
              <Card
                padding="quarter"
                elevation={2}
                style={{
                  zIndex: 30,
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  width: '350px',
                }}
              >
                <MenuList>
                  <SearchResults
                    searchResultsResource={searchResultsResource}
                    community={community}
                    firstResultRef={firstResultRef}
                  />
                </MenuList>
              </Card>
            )}
          </span>
        </ClickAwayListener>
      )}
      <MobileDialog onClose={onClose} open={isSmallScreen && showSearchDialog}>
        <DialogTitle onClose={onClose}>
          <TextFilter
            autoFocus
            value={searchQuery}
            setValue={setSearchQuery}
            placeholder="Search members"
            alwaysOpen
            fullWidth
            onKeyUp={e => {
              if (e.key === 'ArrowDown') {
                firstResultRef.current?.focus();
              }
            }}
          />
        </DialogTitle>
        <DialogContent paddingX="none">
          <MenuList sx={{ gap: quarterSpacing, display: 'flex', flexDirection: 'column' }} disablePadding>
            {searchQuery && (
              <SearchResults
                searchResultsResource={searchResultsResource}
                community={community}
                firstResultRef={firstResultRef}
              />
            )}
          </MenuList>
        </DialogContent>
      </MobileDialog>
    </>
  );
}
