import React from 'react';
import Logo from 'ui/domain/Logo';
import { MenuItem } from '@mui/material';
import { MemberSearchResult, UserResult, UserType } from 'apis/CompanyAPI/communities/communitiesApi';
import Card from 'ui/views/cards/Card';
import { IResource, getOrUndefined, isValidating } from 'util/resource';
import Loading from 'ui/elements/Loading';
import Avatar from 'ui/elements/avatars/Avatar';
import Chip from 'ui/elements/Chip';
import { ICompany } from 'types/company';
import CompanyLogo from 'ui/elements/CompanyLogo';
import { communityUrls } from 'urls';
import { Link } from 'react-router-dom';
import { TinyOrganizationDTO } from 'types/organization';
import useRoute from 'hooks/useRoute';
import styled from '@emotion/styled';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import featureToggle from 'featureToggle';
import { TinyCommunity } from 'types/company/community';

const ListItemLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  // min-width 0 to make ellipsis work on flex items
  return (
    <Link style={{ minWidth: 0 }} className="u-flex-grow u-flex u-flex-align-center u-flex-space-between" to={to}>
      {children}
    </Link>
  );
};

const logoSize = 36;

const LogoName = styled.span`
  display: flex;
  align-items: center;
  // min-width 0 to make ellipsis work on flex items
  min-width: 0;
  font-size: ${fontSizeSmall};
`;

function CompanyLink({ company, communitySlug }: { company: ICompany; communitySlug: string }) {
  return (
    <ListItemLink to={communityUrls.members.companyPitch(communitySlug, company.id)}>
      <LogoName>
        <CompanyLogo className="u-half-spacing-right" size={logoSize} company={company} />
        <span className="u-ellipsis">{company.name}</span>
      </LogoName>
      <Chip className="u-quarter-spacing-left" color="blue-light" label="company" />
    </ListItemLink>
  );
}

function OrganizationLink({
  organization,
  communitySlug,
}: {
  organization: TinyOrganizationDTO;
  communitySlug: string;
}) {
  return (
    <ListItemLink to={communityUrls.members.organization(communitySlug, organization.slug)}>
      <LogoName>
        <Logo className="u-half-spacing-right" size={logoSize} type="organization" url={organization.logoURL} />
        {organization.name}
      </LogoName>
      <Chip className="u-quarter-spacing-left" color="purple-light" label="organization" />
    </ListItemLink>
  );
}

function userTypeDisplayString(userType: UserType, communityId: number) {
  if (userType === 'CommunityTeam') {
    return 'Community Team';
  } else if (userType === 'Advisor' && !featureToggle.communityHasAdvisors(communityId)) {
    return 'User';
  }

  return userType;
}

function UserLink({
  user,
  community,
  chipColor,
}: {
  user: UserResult;
  community: TinyCommunity;
  chipColor: 'yellow-light' | 'green-light';
}) {
  return (
    <ListItemLink to={communityUrls.members.user(community.slug, user.cwUserId)}>
      <LogoName>
        <Avatar className="u-half-spacing-right" size={logoSize} userName={user.name} imageUrl={user.logoURL} />
        <span className="u-ellipsis">{user.name}</span>
      </LogoName>
      <Chip
        className="u-quarter-spacing-left"
        color={chipColor}
        label={userTypeDisplayString(user.userType, community.id)}
      />
    </ListItemLink>
  );
}

export default function SearchResults({
  searchResultsResource,
  community,
  firstResultRef,
}: {
  searchResultsResource: IResource<MemberSearchResult[]>;
  community: TinyCommunity;
  firstResultRef: React.RefObject<HTMLLIElement>;
}) {
  const searchResults = getOrUndefined(searchResultsResource) ?? [];
  const { push } = useRoute();
  return (
    <>
      {searchResults.map((result, i) => {
        const href =
          result.type === 'company'
            ? communityUrls.members.companyPitch(community.slug, result.id)
            : result.type === 'organization'
              ? communityUrls.members.organization(community.slug, result.slug)
              : communityUrls.members.user(community.slug, result.cwUserId);
        return (
          <MenuItem ref={i === 0 ? firstResultRef : null} key={`search-result-${i}`} onClick={() => push(href)}>
            {result.type === 'company' ? (
              <CompanyLink key={result.id} communitySlug={community.slug} company={result} />
            ) : result.type === 'organization' ? (
              <OrganizationLink key={result.id} organization={result} communitySlug={community.slug} />
            ) : (
              <UserLink
                key={result.cwUserId}
                user={result}
                community={community}
                chipColor={result.userType === 'CommunityTeam' ? 'green-light' : 'yellow-light'}
              />
            )}
          </MenuItem>
        );
      })}
      {isValidating(searchResultsResource) && (
        <Card padding="half" className="u-flex-center">
          <Loading size={30} />
        </Card>
      )}
      {searchResultsResource.state === 'fetched' && searchResults.length === 0 && (
        <Card padding="half">No results found</Card>
      )}
    </>
  );
}
