import { calculateCompletedPercentage } from 'domain/onboarding/utils';
import React from 'react';
import ProgressPie from 'ui/elements/Progress/ProgressPie';
import DashboardMessage from './DashboardMessage';
import UserOnboardingWizard from 'domain/onboarding/investor/UserOnboardingWizard';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resources from 'util/resource/Resources';
import Dialog from 'ui/views/dialogs/Dialog';
import useResource from 'util/resource/useResource';
import { Onboarding, OnboardingAPI, onboardingApiUrls } from 'apis/OnboardingAPI';
import useLazyResource from 'util/resource/useLazyResource';
import { invalidate } from 'hooks/useSWR';

export default function FinishUserProfile() {
  const [investorWizardIsVisible, setInvestorWizardIsVisible] = React.useState(false);
  const [displayDashboardMessage, setDisplayDashboardMessage] = React.useState(true);

  const { resource: userProfileResource } = useSelfUserProfile();
  const { resource: onboardingResource } = useResource<Onboarding>(onboardingApiUrls.getUserOnboarding);

  const [patchUserOnboarding] = useLazyResource((status: 'Skipped' | 'Completed') =>
    OnboardingAPI.patchUserOnboarding(status),
  );

  const handleCompleted = () => {
    invalidate(onboardingApiUrls.getUserOnboarding);
    setInvestorWizardIsVisible(false);
    patchUserOnboarding('Completed');
  };

  return (
    <>
      <Resources renderLoading="Nothing" renderError="Nothing" resources={[onboardingResource, userProfileResource]}>
        {([userOnboarding, user]) => {
          // Matching companies to investors is a part of the onboarding, but should not count towards registration completion
          const percentage = calculateCompletedPercentage(
            userOnboarding.wizard.steps.filter(step => step.step !== 'CompanyMatching'),
          );
          return (
            <>
              {displayDashboardMessage && (
                <DashboardMessage
                  key="user-profile-onboarding"
                  color="indigo"
                  topElement={<ProgressPie width={70} thickness={4} fontSize="normal" percentage={percentage} />}
                >
                  <>
                    <p>
                      {percentage < 5
                        ? 'Ready to dive in?'
                        : percentage < 50
                          ? 'Great progress so far! Keep going to get the most out of your experience'
                          : percentage < 100
                            ? `Nearly there, complete your profile ${user.isInvestor ? 'to get more relevant matches' : ''}`
                            : 'Great job, everything looks great! You are ready to meet new connections.'}
                    </p>
                    <button
                      className="text-link-light data-track-dashboard-cta-go-to-user-profile"
                      onClick={() => {
                        percentage < 100 ? setInvestorWizardIsVisible(true) : setDisplayDashboardMessage(false);
                      }}
                    >
                      {percentage < 100 ? 'Complete now' : 'Dismiss'}
                    </button>
                  </>
                </DashboardMessage>
              )}

              <Dialog
                open={investorWizardIsVisible}
                mobileLayout="fullscreen"
                backdropColor="white"
                verticalAlign="top"
                maxWidth="md"
                paperProps={{ sx: { boxShadow: 'initial' } }}
              >
                <UserOnboardingWizard
                  setOnboarding={() => {}} // User has already completed this step at this point, so it's not needed
                  onShowWizard={() => {}}
                  user={user}
                  onboarding={userOnboarding}
                  onClose={() => setInvestorWizardIsVisible(false)}
                  onWizardCompleted={() => handleCompleted()}
                />
              </Dialog>
            </>
          );
        }}
      </Resources>
    </>
  );
}
