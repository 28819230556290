import useSelector from 'hooks/useSelector';
import React from 'react';
import Resource from 'util/resource/Resource';
import SectionHeading from 'ui/elements/SectionHeading';
import { CommunityUpdate } from 'types/content';
import { IResource } from 'util/resource';
import { TinyCommunity } from 'types/company/community';
import useCommunityRole from 'hooks/useCommunityRole';
import { reloadAllUpdates } from './CommunityUpdates/useCommunityUpdates';
import { PaginatedResult } from 'types';
import Update from './CommunityUpdates/Update';
import { getPostedBy } from './CommunityUpdates/UpdateList';
import { communityUrls } from 'urls';

interface Props {
  community: TinyCommunity;
  scrollToUpdatesSection?: () => void;
  resource: IResource<PaginatedResult<CommunityUpdate>>;
}

export default function PinnedUpdateFromCommunity({ community, resource, scrollToUpdatesSection }: Props) {
  return (
    <Resource resource={resource} renderError="Nothing" renderLoading="Nothing">
      {updates => {
        const lastTwoPinnedAndNotArchivedUpdates = updates.values
          .filter(u => u.isPinned && !u.update.archived)
          .slice(0, 2);

        if (lastTwoPinnedAndNotArchivedUpdates.length > 0) {
          return (
            <div className="u-content-spacing-bottom">
              <SectionHeading heading={`Update from ${community.name}`} actionsAlignment="left" addMobileMargin>
                {scrollToUpdatesSection && (
                  <button onClick={scrollToUpdatesSection} className="text-link-small">
                    See all updates
                  </button>
                )}
              </SectionHeading>
              {lastTwoPinnedAndNotArchivedUpdates.map(update => (
                <PinnedUpdate key={update.update.id} community={community} update={update} />
              ))}
            </div>
          );
        } else {
          return null;
        }
      }}
    </Resource>
  );
}

interface PinnedUpdateProps {
  community: TinyCommunity;
  update: CommunityUpdate;
}

function PinnedUpdate({ community, update }: PinnedUpdateProps) {
  const state = useSelector(state => ({
    userProfiles: state.userProfiles.items,
    companyProfiles: state.companyProfiles.items,
  }));

  const { hasScope } = useCommunityRole(community.slug);

  const mutate = () => {
    reloadAllUpdates(community.id);
  };

  const canEditPost = hasScope('Write', 'Update', 'Admin') && update.postedBy.type === 'community';
  return (
    <Update
      className="u-content-spacing-bottom"
      key={`update-preview-${update.update.id}`}
      update={update}
      creator={state.userProfiles.find(u => u.cwUserId === update.update.creatorCwUserId)}
      postedBy={getPostedBy(update, state.userProfiles, state.companyProfiles, companyId =>
        communityUrls.companies.viewFromOverview(community.slug, companyId, 'community-feed'),
      )}
      shownIn="community"
      community={community}
      onEdit={mutate}
      onArchive={mutate}
      onDelete={mutate}
      canEditPost={canEditPost}
      canPinToFeed={canEditPost}
    />
  );
}
