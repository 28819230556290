import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const FadeOutAnimation = keyframes`
  0%, 70% {
    opacity: 1;
    transform:translate(0);
    height: 100%;
  }

  100% {
    opacity: 0;
    transform:translate(399px);
    height: 0%;
  }
`;

const Fader = styled.div`
  animation: ${FadeOutAnimation} 2s forwards cubic-bezier(0.68, -0.9, 0.32, 1.6);
`;

function FadeOut({ children }: { children: ReactNode }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000); //Matches animation timer, removes the element after fading out is done
    return () => clearTimeout(timer);
  }, []);
  return visible ? <Fader>{children}</Fader> : null;
}

export default function FadeOutRight({ fadeOut, children }: { fadeOut: boolean; children: ReactNode }) {
  return fadeOut ? <FadeOut>{children}</FadeOut> : <>{children}</>;
}
