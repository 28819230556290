import React, { CSSProperties } from 'react';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { bluePlanetTheme } from 'ui/theme';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import styled from '@emotion/styled';

interface Props {
  className?: string;
  style?: CSSProperties;
  percent: number;
  thickness?: number;
}
const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props: { isFinished: boolean }) =>
    props.isFinished ? bluePlanetTheme.bluePlanetPalette.green.main : bluePlanetTheme.bluePlanetPalette.grey.medium};
  color: ${(props: { isFinished: boolean }) =>
    props.isFinished ? bluePlanetTheme.bluePlanetPalette.green.dark : bluePlanetTheme.bluePlanetPalette.grey.main};
  border: 1px solid ${bluePlanetTheme.bluePlanetPalette.white};
`;

export default function CircularProgressBar({ className, style, percent, thickness = 6 }: Props) {
  const isFinished = percent >= 100;

  return (
    <IconWrapper isFinished={isFinished} className={className}>
      {isFinished ? (
        <CheckmarkIcon style={{ fontSize: '26px' }} />
      ) : (
        <MuiCircularProgress
          style={{ ...style, color: bluePlanetTheme.bluePlanetPalette.orange.medium }}
          variant="determinate"
          thickness={thickness}
          value={percent}
          sx={{ circle: { strokeLinecap: 'round' } }}
        />
      )}
    </IconWrapper>
  );
}
