import React, { useEffect, useState } from 'react';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import BrandingForm from './BrandingForm';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Resource from 'util/resource/Resource';

interface Props {
  communitySlug: string;
  onStart?: () => void;
  onComplete: () => void;
}

export default function Branding({ communitySlug, onStart, onComplete }: Props) {
  const communitySummaryResource = useCommunitySummary(communitySlug);

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  const [logoURL, setLogoURL] = useState<string | undefined>();

  useEffect(() => {
    if (communitySummaryResource.resource.state === 'fetched') {
      setLogoURL(communitySummaryResource.resource.resource.details.logoURL);
    }
  }, [communitySummaryResource.resource.state]);

  const onLogoUploaded = (logoURL: string) => setLogoURL(logoURL);

  return (
    <Resource resource={communitySummaryResource.resource}>
      {communitySummary => (
        <>
          <Content overflowY="visible" className="u-section-spacing-bottom">
            <BrandingForm community={communitySummary.details} logoURL={logoURL} onLogoUploaded={onLogoUploaded} />
          </Content>
          <DialogActions>
            <Button kind="primary" onClick={onComplete}>
              Done
            </Button>
          </DialogActions>
        </>
      )}
    </Resource>
  );
}
