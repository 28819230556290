import { Location } from 'apis/MapboxAPI';
import { Form, FormikProps, withFormik } from 'formik';
import LocationInput from 'domain/shared/Location/LocationInput';
import React from 'react';
import { SelfUserProfile } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import i18n from 'util/i18n';
import * as Yup from 'yup';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';

interface Props {
  userProfile: SelfUserProfile;
  saving: boolean;
}

interface DispatchProps {
  saveUser: (user: { id: number } & UserProfileFormValues) => void;
}

export interface UserProfileFormValues {
  location?: Location;
  phone: string;
}

type AllProps = Props & DispatchProps & FormikProps<UserProfileFormValues>;

function ContactInfoForm(props: AllProps) {
  const { saving } = props;

  return (
    <>
      <Content>
        <Form className="u-content-spacing-top">
          <FormRow className="u-content-spacing-bottom">
            <FormikTextField
              formikProps={props}
              name="phone"
              label="Phone number"
              type="string"
              placeholder={i18n('en').placeholders.phone}
              helperText="Phone number will be visible on your profile"
            />
          </FormRow>
          <FormRow>
            <LocationInput
              name="location"
              label="Location"
              searchArea='places'
              placeholder={'Search for location'}
              autoComplete="address-level2"
              onChange={v => props.setFieldValue('location', v)}
              value={props.values.location}
              error={props.errors.location}
            />
          </FormRow>
        </Form>
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={() => props.handleSubmit()} isLoading={saving}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
}

export default withFormik<Props & DispatchProps, UserProfileFormValues>({
  mapPropsToValues: props => ({
    phone: props.userProfile.phone ?? '',
    location: props.userProfile.location,
  }),
  validationSchema: Yup.object().shape({
    phone: Yup.string().trim().required('Please provide your phone number'),
    location: Yup.object().required('Please provide your location').nullable(),
  }),
  handleSubmit: (values, { props }) => {
    const data = {
      id: props.userProfile.id,
      phone: values.phone,
      location: values.location,
    };
    props.saveUser(data);
  },
})(ContactInfoForm);
