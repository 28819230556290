import React, { useEffect, useMemo, useState } from 'react';
import {
  getCompletedMentorOnboardingStages,
  numberOfMentorOnboardingStages,
} from 'domain/communities/mentornetworks/MentorOnboardingWidget/util';
import useLocalStorage from 'hooks/useLocalStorage';
import { getOrUndefined } from 'util/resource';
import MentorCtaBanner from 'domain/mentoring/MentorCta/MentorCtaBanner';
import MentorOnboardingWidget from 'domain/communities/mentornetworks/MentorOnboardingWidget';
import { TinyCommunity } from 'types/company/community';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { ICompany } from 'types/company';

interface Props {
  community: TinyCommunity;
  menteeCompanies: ICompany[];
}

export default function MentorOnboarding({ community, menteeCompanies }: Props) {
  const { resource: userResource } = useSelfUserProfile();
  const [mentorIntroIsRead, setMentorIntroIsRead] = useLocalStorage(`mentor-intro-is-read-${community.id}`, false);
  const [mentorAlmostDoneIsRead, setMentorAlmostDoneIsRead] = useState(false);
  const user = getOrUndefined(userResource);

  const completedMentorStages = useMemo(() => {
    if (user) {
      return getCompletedMentorOnboardingStages(user, mentorIntroIsRead);
    }
    return [];
  }, [user, mentorIntroIsRead]);

  useEffect(() => {
    const mentorOnboardingIsCompleted = completedMentorStages.length >= numberOfMentorOnboardingStages;
    setMentorAlmostDoneIsRead(mentorOnboardingIsCompleted);
  }, [completedMentorStages]);

  return (
    <>
      <MentorCtaBanner community={community} menteeCompanies={menteeCompanies} />
      {user && !mentorAlmostDoneIsRead && (
        <MentorOnboardingWidget
          community={community}
          userProfile={user}
          setIntroIsRead={setMentorIntroIsRead}
          setAlmostDoneIsRead={setMentorAlmostDoneIsRead}
          completedStages={completedMentorStages}
        />
      )}
    </>
  );
}
