import { useMediaQuery } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Carousel from 'ui/elements/Carousel';
import { bluePlanetTheme } from 'ui/theme';
import styles from './styles.scss';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import cx from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { defaultBleedAmounts } from 'ui/views/layouts/BaseLayout';
import styled from '@emotion/styled';
import LinkButton from 'ui/elements/buttons/LinkButton';
import StatsIcon from 'ui/elements/icons/StatsIcon';
import useFollowCompany from 'domain/companies/Follow/useFollowCompany';
import { ICompany } from 'types/company';
import { useDownloadPitchDocument } from 'domain/documents/useDownloadDocument';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import CircularProgress from '@mui/material/CircularProgress';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import { getDefaultDateRange } from 'ui/elements/DateRangePresetsPicker';
import PitchDeckInsights from 'pages/Company/Insights/PitchDeckInsights';
import { resize } from 'util/cloudinary';
import { useKeyboardNavigation } from 'contexts/KeyboardNavigationContext';
import usePitchDeckViewLogger from 'hooks/usePitchDeckViewLogger';
import useCompanyRole from 'hooks/useCompanyRole';
import { useFollowAndDownloadWarning } from 'domain/documents/useFollowAndDownloadWarning';
import PitchDeckActionSlide from 'domain/companies/Documents/Document/PitchDeck/PitchDeckActionSlide';
import { PitchFile } from 'types/company/pitchdeck';

const ThumbnailWrapper = styled.div(
  ({ isActive }: { isActive: boolean }) => `
  & canvas {
    width: 100% !important;
  }

  ${isActive ? `border: 2px solid ${bluePlanetTheme.bluePlanetPalette.indigo.light};` : ''}
`,
);

interface Props {
  pitchDeckId: UUID;
  document: PitchFile;
  imageUrls: string[];
  numPages: number;
  company: ICompany;
  pitchDeckTitle?: string;
  onThumbnailClick: (page: number) => void;
  viewAs: CompanyViewAs;
}

const keyboardNavigationContextID = 'PitchDeckPDFViewer';

function PitchDeckPDFPreview({
  pitchDeckId,
  imageUrls,
  numPages,
  onThumbnailClick,
  company,
  document,
  pitchDeckTitle,
  viewAs,
}: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const [pageIndex, setPageIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { onFollow } = useFollowCompany(company);

  const [downloadDocument, isDownloading] = useDownloadPitchDocument(company.id);

  const { onClick: onDownloadClick, WarningDialog: DownloadWarningDialog } = useDownloadWarning(document.name, () =>
    downloadDocument(document.id),
  );

  const { onClick: onClickFollowAndDownload, WarningDialog: FollowAndDownloadWarningDialog } =
    useFollowAndDownloadWarning(document.name, async () => {
      await onFollow();
      return downloadDocument(document.id);
    });

  const leftNavigationRef = React.useRef<HTMLDivElement>(null);
  const rightNavigationRef = React.useRef<HTMLDivElement>(null);
  const [isThumbnailHighlighted, setIsThumbnailHighlighted] = useState(false);
  const [isLeftNavigationHighlighted, setIsLeftNavigationHighlighted] = useState(false);
  const [isRightNavigationHighlighted, setIsRightNavigationHighlighted] = useState(false);

  const { isFollowingCompany, hasOtherRoleThanFollower } = useFollowCompany(company);

  const isTouchScreen = navigator.maxTouchPoints > 0;

  const handleLeftNavigationPointerMove = () => {
    if (!isLeftNavigationHighlighted) {
      setIsLeftNavigationHighlighted(true);
      setIsRightNavigationHighlighted(false);
    }
  };

  const handleRightNavigationPointerMove = () => {
    if (!isRightNavigationHighlighted) {
      setIsRightNavigationHighlighted(true);
      setIsLeftNavigationHighlighted(false);
    }
  };

  const { navigationContext, setNavigationContext } = useKeyboardNavigation();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [pageIndex, navigationContext]);

  const access = useCompanyRole(company.slug);
  const logger = usePitchDeckViewLogger(company.id, pitchDeckId, access.canEditCompany);

  useEffect(() => {
    logger(pageIndex);
  }, [pageIndex]);

  const [mainImageHeight, setMainImageHeight] = useState<number>(0);
  const [thumbnailImageHeight, setThumbnailImageHeight] = useState<number>(0);

  const [isPitchDeckViewsDialogOpen, setPitchDeckViewsDialogOpen] = useState(false);

  const showActionSlideInCarousel = viewAs === 'Follower' || viewAs === 'Visitor';
  const hasToFollowToDownload = viewAs === 'Visitor' || (!isFollowingCompany && !hasOtherRoleThanFollower);

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    preventScrollOnSwipe: true,
    onSwipedLeft: () => setPageIndex(prev => Math.min(prev + 1, numPages - 1)),
    onSwipedRight: () => setPageIndex(prev => Math.max(prev - 1, 0)),
  });

  const goToNextPage = () => {
    if (pageIndex + 1 < numPages + (showActionSlideInCarousel ? 1 : 0)) {
      setPageIndex(pageIndex + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageIndex - 1 >= 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const handleKeyboardNavigation = (event: KeyboardEvent) => {
    if (navigationContext !== keyboardNavigationContextID) return;
    if (event.key === 'ArrowRight') {
      goToNextPage();
    } else if (event.key === 'ArrowLeft') {
      goToPreviousPage();
    }
  };

  // Cacheing viewAs so that the user is able to both follow and shortlist company
  // and not show share right after following
  const [viewAsCached] = useState(viewAs);

  return (
    <>
      <div className="u-flex u-flex--gap-half u-flex-align-center u-flex-space-between u-content-spacing-bottom">
        {pitchDeckTitle && <span className="text-large text-weight-medium">{pitchDeckTitle}</span>}
        <div className="u-margin-left-auto u-flex u-flex--gap-2">
          {viewAs === 'Admin' && (
            <LinkButton
              className="text-link text-medium u-flex u-flex--gap-half"
              onClick={() => setPitchDeckViewsDialogOpen(true)}
              data-intercom-target="view-pitch-deck-insights"
            >
              View insights <StatsIcon fontSize="medium" />
            </LinkButton>
          )}
          <LinkButton
            className="text-link text-medium u-flex u-flex--gap-half"
            onClick={
              hasToFollowToDownload ? (onClickFollowAndDownload ?? (() => null)) : (onDownloadClick ?? (() => null))
            }
          >
            Download pitch
            {isDownloading ? (
              <CircularProgress size={24} />
            ) : (
              <span className="text-link-small u-flex-center u-flex--align-items-center">
                <DownloadIcon fontSize="medium" />
              </span>
            )}
          </LinkButton>
        </div>
      </div>
      <div {...swipeHandlers} onPointerDown={() => setNavigationContext(keyboardNavigationContextID)}>
        <div
          key={`page_${pageIndex}`}
          ref={containerRef}
          className={styles.preview}
          onPointerEnter={() => !isTouchScreen && setIsThumbnailHighlighted(true)}
          onPointerLeave={() => !isTouchScreen && setIsThumbnailHighlighted(false)}
        >
          {!isMobile ? (
            <div
              className={styles.mainPreview}
              ref={containerRef}
              style={{
                height: mainImageHeight,
              }}
            >
              {pageIndex === numPages ? (
                <div>
                  <PitchDeckActionSlide
                    showInCarousel={false}
                    company={company}
                    slideHeight={mainImageHeight}
                    isFollowing={viewAs === 'Follower'}
                    hasShortlisted={false}
                    showFollowAction={viewAsCached !== 'Follower'}
                  />
                </div>
              ) : (
                <img
                  onClick={() => onThumbnailClick(pageIndex + 1)}
                  src={imageUrls[pageIndex]}
                  alt={`Page ${pageIndex + 1}`}
                  onLoad={event => {
                    const imgElement = event.currentTarget;
                    if (mainImageHeight === 0) {
                      setMainImageHeight(imgElement.offsetHeight);
                    }
                  }}
                />
              )}

              <div
                className={cx(styles.navigateLeft, {
                  [styles.highlighted]: isLeftNavigationHighlighted,
                  [styles.hidden]: pageIndex < 1 || !isThumbnailHighlighted,
                })}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  goToPreviousPage();
                }}
                ref={leftNavigationRef}
                onPointerEnter={() => setIsLeftNavigationHighlighted(true)}
                onPointerLeave={() => setIsLeftNavigationHighlighted(false)}
                onPointerMove={handleLeftNavigationPointerMove}
              >
                <div className={styles.navigateArrow}>
                  <ChevronLeftIcon />
                </div>
              </div>
              <div
                className={cx(styles.navigateRight, {
                  [styles.highlighted]: isRightNavigationHighlighted,
                  [styles.hidden]:
                    pageIndex >= numPages - 1 + (showActionSlideInCarousel ? 1 : 0) || !isThumbnailHighlighted,
                })}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  goToNextPage();
                }}
                ref={rightNavigationRef}
                onPointerEnter={() => setIsRightNavigationHighlighted(true)}
                onPointerLeave={() => setIsRightNavigationHighlighted(false)}
                onPointerMove={handleRightNavigationPointerMove}
              >
                <div className={styles.navigateArrow}>
                  <ChevronRightIcon />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: 500, backgroundColor: 'white' }}></div>
          )}
        </div>
        <div style={{ width: '100%' }}>
          <Carousel
            eagerLoadNumberOfNeighbours={isMobile ? 2 : 4}
            bleed={{ ...defaultBleedAmounts, lg: undefined }}
            slidesWidth={{ sm: '45%', md: '45%', lg: '22%' }}
            highlightedSlideIndex={pageIndex}
            options={{ showNavigation: 'on-hover', slidesToScroll: 1 }}
          >
            {Array.from(new Array(numPages + (showActionSlideInCarousel ? 1 : 0)), (_, index) => (
              <ThumbnailWrapper isActive={index === pageIndex} key={`page_${index}`}>
                {index === numPages ? (
                  <div onClick={() => (isMobile ? {} : setPageIndex(index))}>
                    <PitchDeckActionSlide
                      company={company}
                      showInCarousel={true}
                      slideHeight={thumbnailImageHeight}
                      isFollowing={viewAs === 'Follower'}
                      hasShortlisted={false}
                      showFollowAction={viewAsCached !== 'Follower'}
                    />
                  </div>
                ) : (
                  <img
                    key={`thumbnail_${index}`}
                    src={resize(imageUrls[index], {
                      width: 400,
                      quality: 90,
                    })}
                    alt={`Thumbnail ${index}`}
                    onLoad={event => {
                      if (thumbnailImageHeight === 0) {
                        setThumbnailImageHeight(event.currentTarget.offsetHeight);
                      }
                    }}
                    onClick={() => (isMobile ? onThumbnailClick(index + 1) : setPageIndex(index))}
                  />
                )}
              </ThumbnailWrapper>
            ))}
          </Carousel>
        </div>
      </div>
      <CenteredModalDialog
        open={isPitchDeckViewsDialogOpen}
        onClose={() => setPitchDeckViewsDialogOpen(false)}
        overflowY="scroll" // always show scrollbar to prevent window from jumping
        width="wide"
      >
        <PitchDeckInsights company={company} dateRange={getDefaultDateRange()} shouldDisplayInsightsLink />
      </CenteredModalDialog>
      {DownloadWarningDialog}
      {FollowAndDownloadWarningDialog}
    </>
  );
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.document.id === nextProps.document.id && prevProps.viewAs === nextProps.viewAs;
};

export default memo(PitchDeckPDFPreview, areEqual);
