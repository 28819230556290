import Checkbox from '@mui/material/Checkbox';
import ActionHighlightOff from 'ui/elements/icons/CloseCircleIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React, { useState } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { TinyCommunity } from 'types/company/community';
import { UserProfile, UserProfileWithAccess } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import InfoCard from 'ui/views/cards/InfoCard';
import { PAGINATION_DEFAULT_PAGESIZE } from 'util/constants';
import i18n from 'util/i18n';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import Table from 'ui/elements/tables/Table/Table';
import TextFilter from 'domain/shared/Filters/TextFilter';
import { emptyPaginatedResult } from 'util/paginationUtils';
import Pagination from 'ui/modules/Pagination/Pagination';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import { getOrUndefined } from 'util/resource';
import useResource from 'util/resource/useResource';

interface Props {
  dialogHandler: DialogHandlerProps;
  community: TinyCommunity;
  companyId: number;
  refresh: () => void;
  dealLeads: UserProfile[];
}

export default function DealLeadsDialog({
  community,
  companyId,
  refresh,
  dealLeads: propsDealLeads,
  dialogHandler,
}: Props) {
  const [errors, setErrors] = useState<string[]>([]);

  const onError = () => {
    setErrors([i18n('en').errors.tryAgain]);
  };

  const [dealLeads, setDealLeads] = useState(propsDealLeads);

  const toggle = (dealLead: UserProfile) => {
    const isDealLead = dealLeads.find(lead => lead.cwUserId === dealLead.cwUserId);

    if (isDealLead) {
      setDealLeads(dealLeads.filter(dl => dl.cwUserId !== dealLead.cwUserId));
      communitiesApi.dealLeads.remove(community.slug, companyId, dealLead.id).then(refresh).catch(onError);
    } else {
      setDealLeads([...dealLeads, dealLead]);
      communitiesApi.dealLeads.add(community.slug, companyId, dealLead.id).then(refresh).catch(onError);
    }
  };

  const [nameFilter, setNameFilter] = useState('');
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
    limit: PAGINATION_DEFAULT_PAGESIZE,
    page: 1,
  });

  const { resource: usersState } = useResource<PaginatedResult<UserProfileWithAccess>>(
    companiesApi.users.list(community.slug, paginationOptions, {
      name: nameFilter,
      role: community.communityType === 'investor_arena' ? undefined : 'company_master',
    }),
    { keepPreviousData: true },
  );

  const users = getOrUndefined(usersState) ?? emptyPaginatedResult();

  return (
    <Dialog open={true} onClose={dialogHandler.close}>
      <Title onClose={dialogHandler.close}>
        <UsersIcon className="u-quarter-spacing-right" /> Manage deal leads
      </Title>
      <Content>
        {errors.map((err, idx) => (
          <InfoCard key={idx} icon={<ActionHighlightOff fontSize="inherit" />} color="red">
            {err}
          </InfoCard>
        ))}
        <Table
          isLoading={usersState.state === 'fetching'}
          infoMessages={{
            noResults: 'There are no users yet. Invite them to get started.',
            noResultsAfterFilter: "We couldn't find any users with these filters.",
          }}
        >
          <Table.Header>
            <Table.HeaderCell>Is lead</Table.HeaderCell>
            <Table.HeaderCell filter={<TextFilter setValue={setNameFilter} value={nameFilter} />}>
              Name
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {users.values.map(user => {
              const isLead = dealLeads.find(l => l.cwUserId === user.cwUserId) !== undefined;
              return (
                <Table.Row key={user.cwUserId}>
                  <Table.DataCell>
                    <Checkbox checked={isLead} onChange={() => toggle(user)} color="primary" />
                  </Table.DataCell>
                  <Table.DataCell>
                    <div className="u-flex u-flex-align-center">
                      <Avatar userName={user.name} imageUrl={user.imageUrl} className="u-content-spacing-right" />
                      <span>{user.name}</span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pagination resource={users} setPaginationOptions={setPaginationOptions} />
      </Content>
      <DialogActions>
        <Button kind="secondary" onClick={dialogHandler.close}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
