import React from 'react';
import { PulseDTO, PulseItemDTO, PulseSelection } from 'apis/DashboardAPI';
import { timeSince } from 'util/dateUtils';
import ClickableCard from 'ui/views/cards/ClickableCard';
import { CompanyOverviewSection, communityUrls, userUrls } from 'urls';
import { Link } from 'react-router-dom';
import Logo from 'ui/domain/Logo';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import DashboardMessage from '../messages/DashboardMessage';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import CompanyProfileDialog from 'domain/companies/ProfileDialog/CompanyProfileDialog';
import EventDialog from 'domain/events/EventDialog';
import ActivityFromNetworkEmptyState from './ActivityFromNetworkEmptyState';
import { CommunityListItem } from 'types/company/community';
import InfoMessageInline from 'ui/views/messages/InfoMessageInline';
import InfoIcon from 'ui/elements/icons/InfoIcon';
import { UserFeedData } from 'pages/Dashboard/useUserFeed';
import { CompanyListItem } from 'types/company';
import { useRooms } from 'apis/CompanyAPI/rooms/useRooms';
import { useCompanyProfiles } from 'apis/CompanyAPI/companies/useCompanyProfiles';
import { useUserProfiles } from 'apis/CompanyAPI/users/useUserProfiles';
import { notEmpty } from 'util/arrayUtils';
import uniq from 'ramda/src/uniq';
import { getOrUndefined } from 'util/resource';
import { Room } from 'domain/rooms/types';
import { sort } from 'ramda';
import { compareDesc } from 'date-fns';
import ShowUpdate from 'pages/Dashboard/updates/ShowUpdate';
import { bluePlanetTheme } from 'ui/theme';
import { TFilter } from './Pulse';

export function getTitleAndHref(
  myCommunities: CommunityListItem[],
  item: PulseItemDTO,
): {
  title: React.ReactNode;
  href?: string;
  showCompanyIdInDialog?: { companyId: number; initialTab: CompanyOverviewSection };
  showEventIdInDialog?: UUID;
  logo: string | React.ReactNode;
} | null {
  switch (item.type) {
    case 'StartedFundingRound':
      return {
        title: `${item.company.name} started a new funding round`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'ClosedFundingRound':
      return {
        title: `${item.company.name} closed their funding round`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'UpdatedKpis':
      return {
        title: `${item.company.name} updated their metrics`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'kpis' },
        logo: item.company.logoURL,
      };
    case 'UpdatedProfile':
      return {
        title: `${item.company.name} updated their profile`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'UpdatedTeam':
      return {
        title: `${item.company.name} updated their team information`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'team' },
        logo: item.company.logoURL,
      };
    case 'UpdatedPitchDeck':
      return {
        title: `${item.company.name} uploaded a new pitch deck`,
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'PostedUpdate':
      const postedInCommunity = myCommunities
        .filter(c => c.roles.length > 0)
        .find(c => item.audienceCommunityIds.includes(c.details.id));

      return {
        title: postedInCommunity ? (
          <>
            {item.company.name} posted an update in{' '}
            <Link
              style={{ textDecoration: 'underline' }}
              to={communityUrls.overview(postedInCommunity.details.slug, 'updates')}
            >
              {postedInCommunity.details.name}
            </Link>
          </>
        ) : (
          `${item.company.name} posted an update`
        ),
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'updates' },
        logo: item.company.logoURL,
      };
    case 'JoinedCommunity':
      return {
        title: (
          <>
            {item.company.name} joined{' '}
            <Link style={{ textDecoration: 'underline' }} to={communityUrls.overview(item.communitySlug)}>
              {item.communityName}
            </Link>
          </>
        ),
        showCompanyIdInDialog: { companyId: item.company.id, initialTab: 'profile' },
        logo: item.company.logoURL,
      };
    case 'CreatedEvent':
      return {
        title: `New event: ${item.title}`,
        showEventIdInDialog: item.eventId,
        logo: (
          <IconAvatar color="blue-light" size={{ width: '40px', height: '40px' }}>
            <CalendarIcon />
          </IconAvatar>
        ),
      };
  }
}

function getCreators(feed: UserFeedData[]) {
  return feed.map(item => {
    switch (item.type) {
      case 'boardroom':
        return item.boardroomUpdate.update.creatorCwUserId;
      case 'room':
        return item.roomUpdate.update.creatorCwUserId;
      case 'company':
        return item.companyUpdate.update.creatorCwUserId;
      case 'community':
        return item.communityUpdate.update.creatorCwUserId;
    }
  });
}

function getCompanyIds(feed: UserFeedData[], rooms: Room[]) {
  return feed.map(item => {
    switch (item.type) {
      case 'room':
        return rooms.find(r => r.id === item.roomUpdate.roomId)?.companyId;
      case 'company':
        return item.companyUpdate.companyId;
      case 'community':
        return item.communityUpdate.postedBy.type === 'company' ? item.communityUpdate.postedBy.companyId : undefined;
      case 'boardroom':
        return item.boardroomUpdate.companyId;
    }
  });
}

export default function PulseItems({
  pulse,
  feed,
  companies,
  communities,
  selectedFeed,
  activeFilter,
}: {
  pulse: PulseDTO;
  feed: UserFeedData[];
  companies: CompanyListItem[];
  communities: CommunityListItem[];
  selectedFeed: 'All' | 'From my network' | 'From my collections' | 'Suggestions';
  activeFilter: TFilter;
}) {
  const [showCompanyInDialog, setCompanyToShowInDialog] = React.useState<
    { companyId: number; initialTab: CompanyOverviewSection } | undefined
  >();
  const [showEventIdInDialog, setEventIdToShowInDialog] = React.useState<UUID | undefined>();
  const pulseItems = pulse.values;

  const roomIds = feed.map(item => (item.type === 'room' ? item.roomUpdate.roomId : undefined)).filter(notEmpty);
  const userIds = getCreators(feed);

  const [roomsResource] = useRooms(uniq(roomIds));
  const rooms = getOrUndefined(roomsResource)?.values ?? [];

  const companyIds = getCompanyIds(feed, rooms).filter(notEmpty);

  const { resource: companyProfileResource } = useCompanyProfiles(companyIds);
  const { resource: userProfileResource } = useUserProfiles(userIds);
  const companyProfiles = getOrUndefined(companyProfileResource)?.values ?? [];
  const userProfiles = getOrUndefined(userProfileResource)?.values ?? [];

  const updateElements =
    activeFilter === 'Only updates'
      ? []
      : feed.map((item, i) => ({
          element: (
            <ShowUpdate
              update={item}
              key={`update-${i}`}
              communities={communities.map(c => c.details)}
              companies={companies}
              rooms={rooms}
              companyProfiles={companyProfiles}
              userProfiles={userProfiles}
            />
          ),
          timestamp:
            item.type === 'boardroom'
              ? item.boardroomUpdate.update.createdAt
              : item.type === 'community'
                ? item.communityUpdate.update.createdAt
                : item.type === 'company'
                  ? item.companyUpdate.update.createdAt
                  : item.roomUpdate.update.createdAt,
        }));

  const activityElements =
    activeFilter === 'Only posts'
      ? []
      : pulseItems.map((item, i) => {
          const values = getTitleAndHref(communities, item);
          const image = values?.logo ?? '';

          return (
            values && {
              element: (
                <ClickableCard
                  avatar={typeof image === 'string' ? <Logo size={40} url={image} type="company" /> : image}
                  title={values.title}
                  key={`activity-${i}`}
                  href={values.href}
                  onClick={() => {
                    values.showCompanyIdInDialog && setCompanyToShowInDialog(values.showCompanyIdInDialog);
                    values.showEventIdInDialog && setEventIdToShowInDialog(values.showEventIdInDialog);
                  }}
                >
                  <span className="text-metadata">{timeSince(item.timestamp)}</span>
                </ClickableCard>
              ),
              timestamp: item.timestamp,
            }
          );
        });

  const sortedPulseItems = [
    ...sort(
      (a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)),
      [...updateElements, ...activityElements].filter(notEmpty),
    ),
  ];

  return (
    <>
      {/* We need this so that the page doesn't jump when switching between feed tabs */}
      <div style={{ minHeight: '85vh' }}>
        <>
          {sortedPulseItems.length === 0 && selectedFeed === 'From my network' ? (
            <ActivityFromNetworkEmptyState />
          ) : pulseItems.length === 0 && selectedFeed === 'From my collections' ? (
            <Link to={userUrls.discover}>
              <DashboardMessage
                topElement={
                  <IconAvatar color="blue-light" size={{ width: '60px', height: '60px' }}>
                    <BookmarkIcon style={{ fontSize: '30px' }} color="indigo"></BookmarkIcon>
                  </IconAvatar>
                }
              >
                <p>
                  Activity from your collections will appear here. Create your own shortlists and save companies that
                  you find interesting in order to keep tabs on their progress.
                </p>
              </DashboardMessage>
            </Link>
          ) : (
            <div>
              <div className="u-flex u-flex--column" style={{ gap: bluePlanetTheme.spacing(1) }}>
                {sortedPulseItems.map(item => item.element)}
              </div>
              {sortedPulseItems.length < 30 && <PulseSelectionMessage selection={pulse.selection} />}
            </div>
          )}
        </>
      </div>
      {showCompanyInDialog && (
        <CompanyProfileDialog
          analyticsContext="dashboard-feed"
          companyId={showCompanyInDialog.companyId}
          analyticsCommunityId="none"
          initialTab={showCompanyInDialog.initialTab}
          onClose={() => setCompanyToShowInDialog(undefined)}
        />
      )}
      {showEventIdInDialog && (
        <EventDialog eventId={showEventIdInDialog} onClose={() => setEventIdToShowInDialog(undefined)} />
      )}
    </>
  );
}

function PulseSelectionMessage({ selection }: { selection: PulseSelection }) {
  const getMessage = () => {
    switch (selection) {
      case 'PlatformWide':
        return 'Complete your investor profile to get more relevant content';
      case 'Mixed':
        return 'There are few relevant matches with activity at the moment, so we picked a few other popular for you. Stay tuned for more company activity, or change your investor preferences for other suggestions.';
      case 'InvestmentPreferences':
        return 'Great job, you found your way all the way here! Stay tuned for more company activity. Only selected activity last 30 days is available for you at this time.';
      default:
        return undefined;
    }
  };
  const message = getMessage();
  return message ? (
    <InfoMessageInline className="u-half-spacing-top u-half-padding" icon={<InfoIcon></InfoIcon>} color="blue">
      {message}
    </InfoMessageInline>
  ) : null;
}
