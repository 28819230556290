import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import Resource from 'util/resource/Resource';
import MentorAssignment from 'domain/communities/InnovasjonNorge/MentorAssignment';
import FinalReportDocument from 'domain/communities/InnovasjonNorge/MentorDocuments/FinalReportDocument';
import MentorAgreementDocument from 'domain/communities/InnovasjonNorge/MentorDocuments/MentorAgreementDocument';
import { mapMentorStatus } from 'domain/communities/InnovasjonNorge/mentorStatusUtils';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import React from 'react';
import { TinyCommunity } from 'types/company/community';
import { mentorExpertise } from 'apis/MentorAPI/types';
import SkillChip from 'ui/domain/Chips/SkillChip';
import Chip from 'ui/elements/Chip';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import CardHeading from 'ui/views/cards/CardHeading';
import CardStack from 'ui/views/cards/CardStack';
import featureToggle from 'featureToggle';
import User from 'domain/users/Common/User';
import ContactInfoBlock from 'ui/domain/Contact/ContactDetails';
import useCommunityRole from 'hooks/useCommunityRole';

interface MentorshipFeatures {
  programName: boolean;
  mentorAgreement: boolean;
  finalReportDocument: boolean;
}

interface Props {
  community: TinyCommunity;
  companyId: number;
  companyName: string;
}

export default function Mentorships(props: Props) {
  const access = useCommunityRole(props.community.slug);

  const [mentorships, , , reloadMentorships] = useResourceLegacy(
    () => communitiesApi.mentorNetwork.mentorships.list(props.community.slug, props.companyId),
    [props.community.id, props.companyId],
  );

  const canManageMentorAssignments = access.canEditCommunity;

  return (
    <Resource resource={mentorships}>
      {mentorships => (
        <>
          {mentorships.values.length > 0 && <SectionHeading heading="Mentorships" addMobileMargin />}
          {mentorships.values.map(mentorship => {
            const mappedStatus = mapMentorStatus(props.community.communityType, mentorship.status);
            const enabledFeatures: MentorshipFeatures = {
              programName: true,
              mentorAgreement: !featureToggle.isKrisementorNetwork(mentorship.mentorMasterNetworkId),
              finalReportDocument: !featureToggle.isKrisementorNetwork(mentorship.mentorMasterNetworkId),
            };

            const mentor = mentorship.mentor;
            return (
              <CardStack className="u-content-spacing-bottom" key={mentorship.id}>
                {enabledFeatures.programName && (
                  <Card>
                    <CardHeading heading={mentorship.programName} icon={<UsersIcon />}></CardHeading>
                    {mappedStatus && <Chip className="u-content-spacing-top" color="orange" label={mappedStatus} />}
                  </Card>
                )}
                <Card>
                  {mentorship?.mentorAskDescription || mentorship?.mentorAskExpertise?.length ? (
                    <>
                      <SectionHeading heading="Mentor ask" addMobileMargin />
                      <p className="text-body u-half-spacing-bottom">{mentorship?.mentorAskDescription}</p>
                      {mentorship.mentorAskExpertise?.map(expertise => (
                        <SkillChip
                          key={expertise}
                          className="u-half-spacing-right u-half-spacing-top"
                          label={mentorExpertise[expertise]}
                        />
                      ))}
                    </>
                  ) : (
                    <p className="text-body u-half-spacing-bottom text-italic">
                      {props.companyName} haven&apos;t provided a mentor ask
                    </p>
                  )}
                </Card>
                {canManageMentorAssignments && props.community.communityType != 'mentor_master_network' ? (
                  <MentorAssignment
                    companyId={props.companyId}
                    companyName={props.companyName}
                    community={props.community}
                    mentorship={mentorship}
                    refresh={reloadMentorships}
                  />
                ) : (
                  mentor && (
                    <Card>
                      <SectionHeading heading="Mentor" addMobileMargin />
                      <User user={mentor} />
                      <ContactInfoBlock email={mentor.email} phone={mentor.phone} />
                    </Card>
                  )
                )}
                {enabledFeatures.mentorAgreement && (
                  <MentorAgreementDocument
                    communitySlug={props.community.slug}
                    companyId={props.companyId}
                    mentorship={mentorship}
                  />
                )}

                {mentorship.scope && (
                  <Card>
                    <SectionHeading heading="Scope" addMobileMargin />
                    <p className="text-body">{mentorship.scope}</p>
                  </Card>
                )}
                {(mentorship.finalReport || mentorship.finalReportFileId) && (
                  <Card>
                    <SectionHeading heading="Mentee final report" addMobileMargin />
                    <p className="text-body u-content-spacing-bottom"> {mentorship.finalReport}</p>
                    {enabledFeatures.finalReportDocument && (
                      <FinalReportDocument
                        communitySlug={props.community.slug}
                        companyId={props.companyId}
                        mentorship={mentorship}
                      />
                    )}
                  </Card>
                )}
                {mentorship.mentorFinalReport && (
                  <Card>
                    <SectionHeading heading="Mentor final report" addMobileMargin />
                    <p className="text-body u-content-spacing-bottom"> {mentorship.mentorFinalReport}</p>
                  </Card>
                )}
              </CardStack>
            );
          })}
        </>
      )}
    </Resource>
  );
}
