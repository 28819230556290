import { Hidden } from '@mui/material';
import React, { useRef } from 'react';
import CommunityChecklist from 'pages/Community/Overview/Checklist/CommunityChecklist';
import CommunityCompanyProfileRoute from '../../CompanyProfile/CommunityCompanyProfileRoute';
import MentorOnboarding from '../MentorOnboarding';
import FeaturedCommunityCompanies, {
  useFeaturedCommunityCompanies,
} from '../../CommunityMembers/FeaturedCommunityCompanies';
import FeaturedCommunityInvestors, { useFeaturedInvestors } from '../../CommunityMembers/FeaturedCommunityInvestors';
import CommunityRequests from '../CommunityRequests';
import { CommunityConfiguration, CommunityMemberAdminView, TinyCommunity } from 'types/company/community';
import useCommunityRole from 'hooks/useCommunityRole';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { getOrElse, getOrUndefined } from 'util/resource';
import PostUpdate from '../CommunityUpdates/PostUpdate';
import PaginatedUpdates from '../CommunityUpdates/PaginatedUpdates';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import PinnedUpdateFromCommunity from '../PinnedUpdateFromCommunity';
import SectionHeading from 'ui/elements/SectionHeading';
import { Link, Route } from 'react-router-dom';
import { communityUrls } from 'urls';
import Resource from 'util/resource/Resource';
import { UpdateListPreloaded } from '../CommunityUpdates/UpdateList';
import { reloadAllUpdates, useCommunityUpdates } from '../CommunityUpdates/useCommunityUpdates';
import { useSWRResource } from 'util/resource/useSWRResource';
import useSWR from 'hooks/useSWR';
import { PaginatedResult } from 'types';
import { CommunityPendingUser } from 'types/user';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import UpcomingEvents from './UpcomingEvents';
import CompanyActivity from './CompanyActivity';
import RecentActivity from './RecentActivity';
import UpdateDialog from '../CommunityUpdates/UpdateDialog';
import useRoute from 'hooks/useRoute';
import FeaturedCommunityUsers, {
  useFeaturedCommunityUsers,
} from 'pages/Community/CommunityMembers/FeaturedCommunityUsers';

export const communityOverviewPageSize = 8;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 800px 3fr;
  grid-template-areas: 'top top' 'top-updates sidebar' 'mix-1 mix-1' 'updates-2 ...' 'mix-2 mix-2' 'updates-3 ...';
  column-gap: 20rem;

  ${bluePlanetTheme.breakpoints.down('xl')} {
    column-gap: 10rem;
  }

  ${bluePlanetTheme.breakpoints.down(1700)} {
    column-gap: 6rem;
  }

  ${bluePlanetTheme.breakpoints.down(1600)} {
    column-gap: 2rem;
  }

  ${bluePlanetTheme.breakpoints.down(1500)} {
    grid-template-columns: 2fr 1fr;
    column-gap: 1rem;
  }

  ${bluePlanetTheme.breakpoints.down('lg')} {
    grid-template-columns: 100%;
    grid-template-areas: 'top' 'top-updates' 'mix-1' 'updates-2' 'mix-2' 'updates-3';
    gap: 0;
  }
`;

function usePendingCompanies(communitySlug: string, hasScope: (scope: string, resource: string) => boolean) {
  const swrResult = useSWR<PaginatedResult<CommunityMemberAdminView>>(
    hasScope('Write', 'Community') ? communityAPIUrls.companies.listPending(communitySlug) : undefined,
  );

  return useSWRResource(swrResult);
}

function usePendingUsers(communitySlug: string, hasScope: (scope: string, resource: string) => boolean) {
  const swrResult = useSWR<PaginatedResult<CommunityPendingUser>>(
    hasScope('Write', 'Community') ? communityAPIUrls.users.listPending(communitySlug) : undefined,
  );

  return useSWRResource(swrResult);
}

export default function CommunityOverview({
  community,
  communityConfiguration,
}: {
  community: TinyCommunity;
  communityConfiguration: CommunityConfiguration;
}) {
  const { hasScope, roles } = useCommunityRole(community.slug);
  const isMentor = roles.includes('mentor');
  const canViewCompanyList = hasScope('Read', 'Community.Companies');
  const { push, match } = useRoute();

  const { resource: pendingUsersRequest } = usePendingUsers(community.slug, hasScope);
  const { resource: pendingCompaniesRequest } = usePendingCompanies(community.slug, hasScope);

  const itemsToShow = 7;
  const companyMembersIsEnabled = communityConfiguration.companyMembersIsEnabled;

  const { resource: featuredCompaniesResource } = useFeaturedCommunityCompanies(community.slug, {
    orderBy: 'magic_deprioritize_funding',
    limit: itemsToShow,
    shouldFetch: companyMembersIsEnabled,
  });

  const { resource: investorResource } = useFeaturedInvestors(community.slug, itemsToShow);
  const { resource: usersResource } = useFeaturedCommunityUsers(community.slug, {
    limit: itemsToShow,
    shouldFetch: !companyMembersIsEnabled,
  });

  const pendingCompanies = getOrUndefined(pendingCompaniesRequest) || emptyPaginatedResult();
  const pendingUsers = getOrUndefined(pendingUsersRequest) || emptyPaginatedResult();

  const { resource: updatesResource } = useCommunityUpdates(
    community.id,
    { page: 1, limit: communityOverviewPageSize },
    { type: 'latest' },
  );

  const updatesRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <GridContainer ata-intercom-target="main-content">
        <div style={{ gridArea: 'top' }}>
          <CommunityRequests
            className="u-section-spacing-bottom"
            communitySlug={community.slug}
            users={pendingUsers.total}
            companies={pendingCompanies.total}
            showToolTip
          />
          {isMentor && <MentorOnboarding community={community} menteeCompanies={[]} />}
        </div>
        <div style={{ gridArea: 'top-updates' }}>
          <PostUpdate updatesRef={updatesRef} community={community} />
          <PinnedUpdateFromCommunity resource={updatesResource} community={community} />
          <div ref={updatesRef} className="u-section-spacing-bottom">
            <SectionHeading heading="Latest updates" actionsAlignment="left" addMobileMargin>
              <Link className="text-link-small" to={communityUrls.overview(community.slug, 'updates')}>
                See all
              </Link>
            </SectionHeading>
            <UpdateListPreloaded
              shouldHidePinnedPosts={true}
              resource={updatesResource}
              paginationOptions={{ page: 1, limit: 4 }}
              community={community}
              onChange={() => reloadAllUpdates(community.id)}
              shouldDisplayTopicSelector={false}
            />
          </div>
        </div>
        <Hidden lgDown>
          <div style={{ gridArea: 'sidebar' }}>
            {hasScope('Write', 'Community') && <CommunityChecklist communitySlug={community.slug} />}
            {communityConfiguration.usersCanPostUpdates && <RecentActivity community={community} />}
            <UpcomingEvents communitySlug={community.slug} />
            {companyMembersIsEnabled && <CompanyActivity communitySlug={community.slug} />}
          </div>
        </Hidden>
        <div style={{ gridArea: 'mix-1' }}>
          <MobileMargin>
            <Resource resource={usersResource}>
              {users => <FeaturedCommunityUsers communitySlug={community.slug} users={users} />}
            </Resource>
            <Resource resource={featuredCompaniesResource}>
              {companies => (
                <FeaturedCommunityCompanies
                  communitySlug={community.slug}
                  heading={{ singular: 'pitch', plural: 'pitches' }}
                  companies={companies}
                />
              )}
            </Resource>
          </MobileMargin>
          <Hidden lgUp>
            {communityConfiguration.usersCanPostUpdates && <RecentActivity community={community} />}
            <UpcomingEvents communitySlug={community.slug} />
            <CompanyActivity communitySlug={community.slug} />
          </Hidden>
        </div>
        <div style={{ gridArea: 'updates-2' }}>
          <UpdateListPreloaded
            paginationOptions={{ page: 2, limit: 4 }}
            shouldHideLoading={true}
            resource={updatesResource}
            community={community}
            onChange={() => reloadAllUpdates(community.id)}
            shouldDisplayTopicSelector={false}
          />
        </div>
        <div style={{ gridArea: 'mix-2' }}>
          <MobileMargin>
            <FeaturedCommunityInvestors
              investors={getOrElse(investorResource, emptyPaginatedResult())}
              communitySlug={community.slug}
            />
          </MobileMargin>
        </div>
        <div style={{ gridArea: 'updates-3' }}>
          <PaginatedUpdates
            community={community}
            paginationOptions={{ page: 2, limit: communityOverviewPageSize }}
            filterBy={{ type: 'latest' }}
            shouldDisplayTopicSelector={false}
          />
        </div>
      </GridContainer>

      {canViewCompanyList && (
        <CommunityCompanyProfileRoute
          basePath="/communities/:communitySlug/overview"
          community={community}
          analyticsContext="community-overview"
        />
      )}
      <Route
        exact
        path={`${match.path}/:updateId`}
        render={routeProps => (
          <UpdateDialog
            updateId={routeProps.match.params.updateId}
            dialogHandler={{
              isOpen: true,
              open: () => {},
              close: () => {
                push(match.url);
              },
            }}
            onDelete={() => {
              push(match.url);
            }}
            community={community}
          />
        )}
      />
    </>
  );
}
