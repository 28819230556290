import uniq from 'ramda/src/uniq';

import { fetchUserProfile, fetchUserProfiles } from 'globalstate/reducers/userProfiles';

import { PatchUserPayload } from 'types/user';
import { TAction } from 'types';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import { invalidate } from 'hooks/useSWR';
import { selfDetailedKey } from 'apis/CompanyAPI/users/useSelfUserProfile';
import DashboardAPI from 'apis/DashboardAPI';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';

export const getUserProfile = (cwUserId: string): TAction => {
  return (dispatch, getState) => {
    const loaded = getState().userProfiles.items.some(u => u.cwUserId === cwUserId);
    if (loaded) {
      return;
    }
    usersApi.userProfiles.get(cwUserId).then(result => {
      dispatch(fetchUserProfile(result));
    });
  };
};

export const getUserProfiles = (cwUserIds: string[]): TAction => {
  return (dispatch, getState) => {
    const uniqueIds = uniq(cwUserIds);
    const notLoaded = uniqueIds.filter(cwUserId => !getState().userProfiles.items.find(u => u.cwUserId === cwUserId));

    if (notLoaded.length !== 0) {
      usersApi.userProfiles.list(notLoaded).then(result => {
        dispatch(fetchUserProfiles(result));
      });
    }
  };
};

export async function saveUser(user: PatchUserPayload) {
  const industryInvestmentPreferences = user.industryInvestmentPreferences?.filter(it => it != null);
  const response = await usersApi.userProfiles.patchProfile({
    ...user,
    industryInvestmentPreferences,
  });
  invalidate(selfDetailedKey, response);
  invalidate(companiesAPIUrls.getPublicProfilesSuggestedForOnboarding());
  if (industryInvestmentPreferences) {
    invalidate(DashboardAPI.pulse.suggestions());
  }
}
