import React from 'react';
import Timestamp from 'ui/elements/Timestamp';
import styles from './styles.scss';
import { Message } from 'apis/ContentAPI/conversationApi';
import cx from 'classnames';
import { SelfUserProfile, UserProfile } from 'types/user';

interface Props {
  message: Message;
  user: SelfUserProfile;
  author?: UserProfile;
}

export default function EventCard(props: Props) {
  const prefix = props.user.cwUserId === props.author?.cwUserId ? 'You' : (props.author?.name ?? 'Someone');
  return (
    <div className="u-flex u-content-spacing-y u-flex-center u-align-center">
      <div className={styles.byline}>
        <Timestamp timestamp={props.message.createdAt} />
        <div className={cx('u-quarter-spacing-bottom', styles.eventMessage)}>
          {prefix} {props.message.content}
        </div>
      </div>
    </div>
  );
}
