import React from 'react';
import { UserProfile } from 'types/user';
import UserConversationButton from 'domain/conversations/UserConversationButton';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import UserCard from 'ui/domain/Users/UserCard';

export default function ClickableUserCard({ user, href }: { user: UserProfile; href: string }) {
  return (
    <UserCard
      user={user}
      href={href}
      actions={
        <UserConversationButton className="text-medium" conversationWith={[user.cwUserId]}>
          <ChatBubblesIcon />
        </UserConversationButton>
      }
    ></UserCard>
  );
}
