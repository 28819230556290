import React from 'react';
import Button, { ButtonColors } from './Button';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';

interface Props {
  color: Extract<ButtonColors, 'white' | 'greyLight'>;
  onClick: () => void;
  text: string;
  className?: string;
}

export default function BackButton({ color, onClick, text, className }: Props) {
  return (
    <Button size="small" color={color} onClick={onClick} kind="primary" className={className}>
      <ChevronLeftIcon fontSize="small" />
      {text}
    </Button>
  );
}
