import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import IconButton from 'ui/elements/icons/IconButton';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import styles from './styles.scss';
import cx from 'classnames';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
}

// When opening a pdf in a dialog within a dialog it crashes when you zoom in.
// Therefore it was better du use a div with a large z-index
export function MobileFullscreenDialog(props: { children?: React.ReactNode; onClose: () => void }) {
  const theme = useTheme();

  return createPortal(
    <div
      style={{
        minHeight: '100vh',
        width: '100%',
        position: 'fixed',
        zIndex: 1300,
        overflow: 'scroll',
        touchAction: 'pan-x pan-y',
        top: 0,
        left: 0,
        backgroundColor: theme.bluePlanetPalette.grey.light,
      }}
    >
      <div className={styles.mobileCloseButtonWrapper}>
        <IconButton
          className={cx('u-half-spacing', styles.mobileCloseButton)}
          color="indigo"
          onClick={props.onClose}
          aria-label="Close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div
        style={{
          height: '100vh',
          position: 'relative',
          overflow: 'scroll',
        }}
      >
        {props.children}
      </div>
    </div>,
    document.body,
  );
}

export default function PDFDialog({ children, onClose }: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const closeDocument = () => {
    onClose();
  };

  return isMobile ? (
    <MobileFullscreenDialog onClose={closeDocument}>{children}</MobileFullscreenDialog>
  ) : (
    <CenteredModalDialog open onClose={closeDocument} disableEnforceFocus overflowY="scroll" width="wide">
      {children}
    </CenteredModalDialog>
  );
}
