import styled from '@emotion/styled';
import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';
import DOMPurify from 'dompurify';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import RedirectDialog from 'ui/modules/dialogs/RedirectInterceptDialog';
import { contentSpacing, halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';
import { isInternalUrl } from 'util/stringUtils';

interface Props {
  children: string;
  style?: object;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  embeddingIsAllowed?: boolean;
  externalLinkInterceptionIsDisabled?: boolean;
}

const Div = styled.div`
  white-space: pre-line;
  word-break: break-word;
  ul {
    list-style: disc;
    list-style-position: outside;
    padding-left: ${contentSpacing};
  }
  ol {
    list-style: decimal;
    list-style-position: outside;
    padding-left: ${contentSpacing};
    margin: 0;
  }
  h2 {
    margin: ${halfSpacing} ${quarterSpacing} 0 0;
  }
`;

const defaultAllowedTags = {
  ALLOWED_TAGS: ['a', 'img', 'ul', 'li', 'ol', 'em', 'strong', 'p', 'span', 'br', 'u', 'h1', 'h2', 'blockquote'],
  ALLOWED_ATTR: ['src', 'alt', 'rel', 'width', 'height', 'href', 'class', 'title', 'target'],
};

function HtmlContent(
  { children, style, onClick, className, embeddingIsAllowed, externalLinkInterceptionIsDisabled }: Props,
  ref: Ref<HTMLDivElement>,
) {
  const { host } = window.location;
  const [externalRedirectHref, setExternalRedirectHref] = useState<string | undefined>();
  const {
    isClosed: hasOptedOutOfExternalLinkInterception,
    closeMessage: setHasOptedOutOfExternalLinkInterception,
    isLoading,
  } = useCloseableMessage(`has-opted-out-of-external-link-interception`);

  const [checkboxState, setCheckboxState] = useState<{ isTouched: boolean; value?: boolean }>({
    isTouched: false,
    value: hasOptedOutOfExternalLinkInterception,
  });

  useEffect(() => {
    setCheckboxState({ isTouched: checkboxState.isTouched, value: hasOptedOutOfExternalLinkInterception });
  }, [isLoading]);

  const cleaned = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: [...defaultAllowedTags.ALLOWED_TAGS, ...(embeddingIsAllowed ? ['iframe', 'div'] : [])],
    ALLOWED_ATTR: defaultAllowedTags.ALLOWED_ATTR,
  });
  return (
    <>
      <Div
        ref={ref}
        onClick={e => {
          onClick && onClick(e);
          if (e.target instanceof HTMLAnchorElement) {
            const href = e.target.getAttribute('href');
            if (
              href &&
              !isInternalUrl(host, href) &&
              !hasOptedOutOfExternalLinkInterception &&
              !externalLinkInterceptionIsDisabled
            ) {
              e.preventDefault();
              setExternalRedirectHref(href);
            }
          }
        }}
        style={style}
        className={className}
        dangerouslySetInnerHTML={{
          __html: cleaned,
        }}
      ></Div>
      {externalRedirectHref && (
        <RedirectDialog
          externalRedirectHref={externalRedirectHref}
          setExternalRedirectHref={setExternalRedirectHref}
          checkboxState={checkboxState.value ?? false}
          setCheckboxState={value => setCheckboxState({ isTouched: true, value })}
          setHasOptedOutOfExternalLinkInterception={() => {
            if (checkboxState.isTouched && checkboxState.value === true) setHasOptedOutOfExternalLinkInterception();
          }}
        />
      )}
    </>
  );
}

export default forwardRef(HtmlContent);
