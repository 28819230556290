import { notify } from 'actions/snackbar';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { requestWrapper2 } from 'apis/requestWrapper';
import ProgressConfirmDialog from 'ui/modules/dialogs/ProgressConfirmDialog';
import Resource from 'util/resource/Resource';
import useDialogHandler from 'hooks/useDialogHandler';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { ProgressHandler } from 'hooks/useProgressHandler';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'ui/elements/buttons/Button';
import Select from 'ui/elements/form/Select/Select';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import RotateIcon from 'ui/elements/icons/RotateRightIcon';
import Card from 'ui/views/cards/Card';
import CardHeading from 'ui/views/cards/CardHeading';
import { TinyCommunity } from 'types/company/community';
import Label from 'ui/elements/form/Label';
import useCommunityRole from 'hooks/useCommunityRole';

interface Props {
  community: TinyCommunity;
  companyId: number;
  companyName: string;
  refreshLabels: () => void;
}

/*
 * Only used by innovasjon norge
 */
function TransferToCommunity(props: Props) {
  const [statusResource, , setStatusResource] = useResourceLegacy(
    () => communitiesApi.mentorNetwork.transfer.status(props.community.slug, props.companyId),
    [props.community.id, props.companyId],
  );

  const dialogHandler = useDialogHandler();
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      notify,
    },
    dispatch,
  );

  const [selectedCommunity, setSelectedCommunity] = useState<number | 'not_selected'>('not_selected');

  const transfer = async (progressHandler: ProgressHandler) => {
    if (selectedCommunity === 'not_selected') {
      return;
    }
    requestWrapper2(
      async () => {
        await communitiesApi.mentorNetwork.transfer.transfer(props.community.slug, props.companyId, selectedCommunity);
        props.refreshLabels();
      },
      error => actions.notify('error', error || 'Could not transfer company'),
      progressHandler,
      () => {
        if (statusResource.state === 'fetched' && statusResource.resource.status === 'transferable') {
          const community = statusResource.resource.linkedCommunities.find(c => c.communityId === selectedCommunity);
          community &&
            setStatusResource({
              state: 'fetched',
              resource: {
                transferredTo: {
                  communityId: community.communityId,
                  communityName: community.communityName,
                },
                status: 'transferred',
              },
              isValidating: false,
            });
        }
      },
    );
  };

  return (
    <Resource resource={statusResource} renderLoading={() => <span />} renderError={() => <span />}>
      {status => {
        switch (status.status) {
          case 'not_linked':
            return null;
          case 'transferable':
            const selected = status.linkedCommunities.find(c => c.communityId === selectedCommunity);
            return (
              <Card className="u-content-spacing-bottom">
                <CardHeading icon={<RotateIcon />} heading={'Transfer'} />
                <ProgressConfirmDialog
                  dialogHandler={dialogHandler}
                  title={'Transfer'}
                  confirmLabel={'Transfer'}
                  onConfirm={transfer}
                >
                  The company will be made available for mentor assignment in {selected?.communityName}
                </ProgressConfirmDialog>
                <div className="text-body u-content-spacing-bottom">
                  Would you like to transfer {props.companyName}?
                </div>
                <Label htmlFor="selectCommunity" className="u-half-spacing-bottom">
                  Select community
                </Label>
                <Select
                  name="selectCommunity"
                  id="selectCommunity"
                  value={{
                    value: selectedCommunity.toString(),
                    label:
                      status.linkedCommunities.find(c => c.communityId === selectedCommunity)?.communityName ??
                      'Select community',
                    isDisabled: selectedCommunity === 'not_selected',
                  }}
                  options={[
                    { value: 'not_selected', label: 'Select community', isDisabled: true },
                    ...status.linkedCommunities.map(c => ({ value: c.communityId.toString(), label: c.communityName })),
                  ]}
                  onChange={e => {
                    e ? setSelectedCommunity(parseInt(e.value, 10)) : null;
                  }}
                  getOptionLabel={value => value.label}
                />
                <div className="u-content-spacing-top">
                  <Button
                    disabled={selectedCommunity === 'not_selected'}
                    kind="secondary"
                    onClick={() => {
                      dialogHandler.open();
                    }}
                  >
                    {'Transfer'}
                  </Button>
                </div>
              </Card>
            );
          case 'transferred':
            return (
              <Card className="u-content-spacing-bottom">
                <CardHeading
                  className="u-content-spacing-bottom"
                  icon={
                    <IconAvatar color="success">
                      <CheckmarkIcon />
                    </IconAvatar>
                  }
                  heading={`Transferred to ${status.transferredTo.communityName}`}
                />
                <span className="text-body">
                  {props.companyName} has been transferred to {status.transferredTo.communityName}!
                </span>
              </Card>
            );
        }
      }}
    </Resource>
  );
}

export default function TransferToCommunityContainer(props: Props) {
  const { canEditCommunity } = useCommunityRole(props.community.slug);
  return canEditCommunity ? <TransferToCommunity {...props} /> : null;
}
