import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function OutlookIcon(props: IconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 94 90">
      <path d="M2 11.4404L52.8048 3V87.2988L2 78.9637V44.7151V11.4404Z" />
      <path d="M39.0102 46.5003C39.0102 55.1061 33.7778 62.0825 27.3234 62.0825C20.869 62.0825 15.6367 55.1061 15.6367 46.5003C15.6367 37.8944 20.869 30.918 27.3234 30.918C33.7778 30.918 39.0102 37.8944 39.0102 46.5003ZM21.6523 46.5003C21.6523 50.6764 24.1914 54.9407 27.3234 54.9407C30.4555 54.9407 32.5175 50.6764 32.5175 46.5003C32.5175 42.3242 30.4555 37.8975 27.3234 37.8975C24.1914 37.8975 21.6523 42.3242 21.6523 46.5003Z" />
      <path d="M53.3047 21.1787H88.3648H89.0166C89.1227 21.1787 89.2285 21.1917 89.3315 21.2175L89.5713 21.2774C89.7392 21.3194 89.897 21.3945 90.0355 21.4984L90.3265 21.7167C90.425 21.7905 90.5124 21.8779 90.5862 21.9764L90.8045 22.2675C90.9084 22.4059 90.9835 22.5637 91.0255 22.7316L91.0854 22.9714C91.1112 23.0744 91.1242 23.1802 91.1242 23.2864V24.7497L90.963 67.1347C90.9623 67.3346 90.9154 67.5317 90.826 67.7105L90.6373 68.0879L90.5039 68.2879C90.3786 68.4759 90.2063 68.628 90.0042 68.7291L89.7909 68.8357C89.7061 68.8781 89.617 68.9111 89.5251 68.934L89.1692 69.023C89.0662 69.0488 88.9604 69.0618 88.8542 69.0618H53.3047V21.1787Z" />
      <path d="M53.4628 39.5213L63.3649 47.3126L91.1199 25.4001" />
    </SvgIcon>
  );
}
