import { CommunityUpdate } from 'types/content/update';
import React, { useEffect } from 'react';
import Update from './Update';
import Dialog from 'ui/views/dialogs/Dialog';
import LoadableResource from 'util/resource/Resource';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import IconButton from '@mui/material/IconButton';
import { communityUrls } from 'urls';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import useSelector from 'hooks/useSelector';
import useCommunityRole from 'hooks/useCommunityRole';
import { TinyCommunity } from 'types/company/community';
import useSWR from 'hooks/useSWR';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserProfile } from 'actions/userProfiles';
import { getCompanyProfile } from 'actions/companyProfiles';
import { useSWRResource } from 'util/resource/useSWRResource';
import { getPostedBy } from './UpdateList';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { getOrUndefined } from 'util/resource';

interface Props {
  community: TinyCommunity;
  dialogHandler: DialogHandlerProps;
  onDelete: () => void;
  updateId: UUID;
}

function useUpdate(communityId: number, updateId: UUID) {
  const api = communityUpdateAPI(communityId);
  const swrResult = useSWR<CommunityUpdate>(api.get(updateId));

  const dispatch = useDispatch();
  const boundActions = bindActionCreators(
    {
      getUserProfile,
      getCompanyProfile,
    },
    dispatch,
  );

  useEffect(() => {
    if (swrResult.data !== undefined) {
      boundActions.getUserProfile(swrResult.data.update.creatorCwUserId);
      if (swrResult.data.postedBy.type === 'company') {
        boundActions.getCompanyProfile(swrResult.data.postedBy.companyId);
      }
    }
  }, [updateId, swrResult.data !== undefined]);

  return useSWRResource(swrResult);
}

export default function UpdateDialog(props: Props) {
  const { updateId } = props;

  const { resource: updateResource, mutate } = useUpdate(props.community.id, updateId);
  const { userProfiles, companyProfiles } = useSelector(state => ({
    userProfiles: state.userProfiles.items,
    companyProfiles: state.companyProfiles.items,
  }));

  const api = communityUpdateAPI(props.community.id);

  useEffect(() => {
    api.markAsRead(updateId);
  }, []);

  const { hasScope, myCompanyMemberships } = useCommunityRole(props.community.slug);
  const { resource: userProfileResource } = useSelfUserProfile();
  const me = getOrUndefined(userProfileResource);

  return (
    <Dialog open onClose={props.dialogHandler.close} maxWidth="md">
      <LoadableResource resource={updateResource}>
        {communityUpdate => {
          const creator = userProfiles.find(cu => cu.cwUserId === communityUpdate.update.creatorCwUserId);
          const canEditPost =
            communityUpdate.postedBy.type === 'community'
              ? hasScope('Write', 'Update', 'Admin')
              : communityUpdate.postedBy.type === 'company'
                ? myCompanyMemberships.some(
                    m =>
                      communityUpdate.postedBy.type === 'company' &&
                      m.company.id === communityUpdate.postedBy.companyId,
                  )
                : communityUpdate.postedBy.type === 'user'
                  ? communityUpdate.postedBy.cwUserId === me?.cwUserId
                  : false;

          return (
            <Update
              key={`update-preview-${communityUpdate.update.id}`}
              community={props.community}
              update={communityUpdate}
              creator={creator}
              postedBy={getPostedBy(communityUpdate, userProfiles, companyProfiles, companyId =>
                communityUrls.companies.viewFromOverview(props.community.id, companyId, 'community-feed'),
              )}
              onArchive={() => {}}
              onDelete={props.onDelete}
              onEdit={() => mutate()}
              shownIn="community"
              canEditPost={canEditPost}
              viewType="read more"
              headingActions={
                <IconButton className="u-half-spacing-left" onClick={props.dialogHandler.close}>
                  <CloseIcon />
                </IconButton>
              }
            />
          );
        }}
      </LoadableResource>
    </Dialog>
  );
}
