import React from 'react';

import KeyUserInfo from './KeyUserInfo';
import Avatar from 'ui/elements/avatars/Avatar';
import useResourceLegacy from 'util/resource/useResourceLegacy';

import LoadableResource from 'util/resource/Resource';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import MentorProfile from './MentorProfile';

interface Props {
  cwUserId: string;
}

export default function UserPreview(props: Props) {
  const { cwUserId } = props;
  const [user] = useResourceLegacy(() => usersApi.userProfiles.getDetailed(cwUserId), [cwUserId]);

  return (
    <LoadableResource resource={user}>
      {user => (
        <>
          <div className="u-flex-align-center u-flex--column">
            <Avatar
              size={80}
              resize={160}
              imageUrl={user.imageUrl}
              userName={user.name}
              style={{ display: 'block', position: 'relative', top: '75px', zIndex: 1 }}
            />
          </div>
          <KeyUserInfo user={user} />
          <MentorProfile user={user} />
        </>
      )}
    </LoadableResource>
  );
}
