import useSWR, { invalidate } from 'hooks/useSWR';
import { CommunityContentTopicListDTO, TopicPostCountDTO } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';
import { ContentAPIUrls } from 'apis/ContentAPI';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { deleteRequest, patch, post } from 'apis/ApiBase';
import { communityUpdatesKey } from 'pages/Community/Overview/CommunityUpdates/useCommunityUpdates';
import { communityOverviewPageSize } from 'pages/Community/Overview/sections/Overview';

export const communityTopicsKey = (communityId: string | number) => ContentAPIUrls.listTopics(communityId);

export const useCommunityTopics = (communityId: number) => {
  const swrResult = useSWR<CommunityContentTopicListDTO>(communityTopicsKey(communityId));
  return useSWRResource(swrResult);
};

export const usePostCountPerTopic = (communityId: string | number, topicId: string) => {
  const swrResult = useSWR<TopicPostCountDTO>(ContentAPIUrls.getPostCountPerTopic(communityId, topicId));
  return useSWRResource(swrResult);
};

export function useSaveCommunityTopic(communityId: number) {
  const notify = useNotify();
  return useLazyResource(
    ({ topicId, name, emoji }: { topicId?: string | undefined; name: string; emoji?: string }) =>
      topicId
        ? patch(ContentAPIUrls.updateTopic(communityId, topicId), { name: name, emoji: emoji })
        : post(ContentAPIUrls.createTopic(communityId), { name: name, emoji: emoji }),
    {
      onSuccess: (_, { topicId, name }) => {
        if (topicId !== undefined) {
          invalidate(communityTopicsKey(communityId));
        } else {
          invalidate(communityTopicsKey(communityId));
        }
        invalidate(communityUpdatesKey(communityId, { type: 'latest' }, { page: 1, limit: communityOverviewPageSize }));
        notify('success', topicId ? `${name} topic updated` : `${name} topic created`);
      },
      onFailure: (e: string) => {
        notify('error', e || 'Failed to save topic');
      },
    },
  );
}

export function useDeleteCommunityTopic(communityId: number) {
  const notify = useNotify();
  return useLazyResource((topicId: string) => deleteRequest(ContentAPIUrls.deleteTopic(communityId, topicId)), {
    onSuccess: () => {
      invalidate(communityTopicsKey(communityId));
      notify('success', 'Topic successfully deleted');
    },
    onFailure: (e: string) => {
      notify('error', e || 'Failed to delete topic');
    },
  });
}

export function useReassignCommunityTopic(communityId: number) {
  const notify = useNotify();
  return useLazyResource(
    ({ currentTopicId, newTopicId }: { currentTopicId: string; newTopicId: string }) =>
      post(ContentAPIUrls.reassignTopic(communityId, currentTopicId), { newTopicId }),
    {
      onSuccess: () => {
        invalidate(communityTopicsKey(communityId));
        invalidate(communityUpdatesKey(communityId, { type: 'latest' }, { page: 1, limit: communityOverviewPageSize }));
        notify('success', 'Topic successfully deleted');
      },
      onFailure: (e: string) => {
        notify('error', e || 'Failed to delete topic');
      },
    },
  );
}
