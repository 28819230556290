import useSWR from 'hooks/useSWR';
import { BusinessDomain } from 'types';
import { List } from 'types/api';
import { useSWRResource } from 'util/resource/useSWRResource';
import { companiesAPIUrls } from '../companies/companiesApi';

export const businessDomainsKey = companiesAPIUrls.businessDomains.listAll();

export const useBusinessDomains = () => {
  const swrResult = useSWR<List<BusinessDomain>>(businessDomainsKey);
  return useSWRResource(swrResult);
};
