import { useState } from 'react';

export type ProgressHandler = {
  onProgress: () => void;
  onCleanup: (result: 'success' | 'failure') => void;
};

export default function useProgressHandler() {
  const [loading, setLoading] = useState(false);

  function onProgress() {
    setLoading(true);
  }
  function onCleanup() {
    setLoading(false);
  }

  const progressHandler: ProgressHandler = {
    onProgress,
    onCleanup,
  };
  const returnState: [typeof loading, typeof progressHandler] = [loading, progressHandler];
  return returnState;
}
