import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { SelfUserProfile } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';

interface Props {
  userprofile: SelfUserProfile;
  saving: boolean;
}

interface DispatchProps {
  saveUser: (user: { id: number } & UserProfileFormValues) => void;
}

export interface UserProfileFormValues {
  description: string;
}

type AllProps = Props & DispatchProps & FormikProps<UserProfileFormValues>;

function ContactInfoForm(props: AllProps) {
  const { saving } = props;

  return (
    <>
      <Content>
        <Form>
          <FormRow>
            <FormikTextField
              formikProps={props}
              name="description"
              multiline
              minRows={5}
              maxRows={9}
              placeholder="Please provide a short description of your background and experience"
            />
          </FormRow>
        </Form>
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={() => props.handleSubmit()} isLoading={saving}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
}

export default withFormik<Props & DispatchProps, UserProfileFormValues>({
  mapPropsToValues: props => ({
    description: props.userprofile.description ?? '',
  }),
  validationSchema: Yup.object().shape({
    description: Yup.string().trim().required('Please provide a short description of yourself'),
  }),
  handleSubmit: (values, { props }) => {
    const data = {
      id: props.userprofile.id,
      description: values.description,
    };
    props.saveUser(data);
  },
})(ContactInfoForm);
