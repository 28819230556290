import { useCommunityTopics } from 'apis/CompanyAPI/communities/useCommunityTopics';
import React from 'react';
import { useHistory } from 'react-router';
import { TinyCommunity } from 'types/company/community';
import SelectCloud from 'ui/elements/form/SelectCloud/SelectCloud';
import Card from 'ui/views/cards/Card';
import { communityUrls } from 'urls';
import { getOrElse } from 'util/resource';
import Resource from 'util/resource/Resource';

interface Props {
  community: TinyCommunity;
  className?: string;
  sectionToOpen: 'overview' | 'updates';
}

export default function TopicSelector(props: Props) {
  const { resource: topicResource } = useCommunityTopics(props.community.id);
  const topicList = getOrElse(topicResource, { values: [] });
  const params = new URLSearchParams(window.location.search);
  const selectedTopic = params.get('topicId');
  const history = useHistory();

  const getSelectedTopic = () => {
    const topic = topicList.values.find(t => t.id === selectedTopic);
    return topic ? [{ ...topic, icon: topic.emoji }] : [];
  };

  return (
    <Resource resource={topicResource}>
      {topicList => {
        return (
          <Card className={props.className}>
            <h4 className="u-content-spacing-bottom u-quarter-spacing-left">Browse content based on topics</h4>
            <SelectCloud
              options={topicList.values.map(t => ({
                name: t.name,
                id: t.id,
                isDefault: t.isDefault,
                icon: t.emoji,
              }))}
              values={getSelectedTopic()}
              onSelect={t =>
                history.push(communityUrls.overview(props.community.slug, props.sectionToOpen, { topicId: t.id }))
              }
              onRemove={() => history.push(communityUrls.overview(props.community.slug, props.sectionToOpen))}
            />
          </Card>
        );
      }}
    </Resource>
  );
}
