import React, { useState } from 'react';
import { LabelDTO } from 'types/company';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import Button from 'ui/elements/buttons/Button';
import LabelsDialog from 'domain/shared/LabelsDialog';
import { useCommunityLabels } from 'apis/CompanyAPI/communities/useCommunityLabels';
import useLazyResource from 'util/resource/useLazyResource';

interface Props {
  labels: LabelDTO[];
  companyId: number;
  companyName: string;
  onClose: () => void;
  communitySlug: string;
  refresh: (labels: LabelDTO[]) => void;
}

export default function CommunityLabelsDialog(props: Props) {
  const [activeLabels, setActiveLabels] = useState(props.labels);

  const [toggleLabel, isSaving] = useLazyResource(
    (label: LabelDTO) =>
      activeLabels.find(l => l.id === label.id)
        ? communitiesApi.communityMemberLabels.remove(props.communitySlug, props.companyId, label.id)
        : communitiesApi.communityMemberLabels.attach(props.communitySlug, props.companyId, label.id),
    {
      onSuccess: (_, label) => {
        if (activeLabels.find(l => l.id === label.id)) {
          setActiveLabels(activeLabels.filter(l => l.id !== label.id));
        } else {
          setActiveLabels([...activeLabels, label]);
        }
      },
    },
  );

  const { resource: labelsResource, mutate: mutateLabels } = useCommunityLabels(props.communitySlug);

  return (
    <LabelsDialog
      labelsResource={labelsResource}
      mutateLabels={mutateLabels}
      title={`Labels for ${props.companyName}`}
      isChecked={label => activeLabels.find(l => l.id === label.id) !== undefined}
      toggleLabel={toggleLabel}
      isDisabled={isSaving}
      onClose={props.onClose}
      api={{
        create: (name: string, color: string) =>
          communitiesApi.communityLabels.add(props.communitySlug, { name, color }),
        patch: (label: LabelDTO, name: string, color: string) =>
          communitiesApi.communityLabels.update(props.communitySlug, label.id, { name, color }),
        delete: (label: LabelDTO) => communitiesApi.communityLabels.remove(props.communitySlug, label.id, true),
      }}
      entityName="companies"
      actions={
        <Button
          kind="secondary"
          onClick={() => {
            props.refresh(activeLabels.sort((a, b) => a.name.localeCompare(b.name)));
            props.onClose();
          }}
        >
          Close
        </Button>
      }
    />
  );
}
